<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :key="unqKey"
      :items="availableChoices"
      :items-per-page="5"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:item.sunday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Sunday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Sunday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
    
      <template v-slot:item.monday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Monday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Monday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
      <template v-slot:item.tuesday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Tuesday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Tuesday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
      <template v-slot:item.wednesday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Wednesday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Wednesday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
      <template v-slot:item.thursday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Thursday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Thursday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
      <template v-slot:item.friday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Friday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Friday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
      <template v-slot:item.saturday="{ item }">
        <ul>
          <span v-if="getMeals(item, 'Saturday').length == 0">--</span>
          <li v-for="mealItem in getMeals(item, 'Saturday')" :key="mealItem">
            {{ mealItem.nameEn }} : {{ mealItem.count }}
          </li>
        </ul>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    choices: {
      required: true
    }
  },
  watch: {
    choices: {
      handler(newVal) {
        this.availableChoices = newVal;
        this.unqKey = this.unqKey + 1;
      },
      deep: true
    }
  },
  created() {
    this.availableChoices = this.choices;
    this.setHeaders();
    this.unqKey = 1;
  },
  data() {
    return {
      snack: false,
      unqKey: 0,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [],
      availableChoices: []
    };
  },
  methods: {
    getMeals(item, title) {
      let daytem = item.dayChoices.find(x => x.day == title);
      let items = new Array();
      if (daytem) {
        daytem.mealCount.forEach(element => {
          if (element.count) {
            items.push({
              nameEn: element.category
                ? element.category.nameEn
                : element.nameEn,
              count: element.count
            });
          }
        });
      }
      return items;
    },
    setHeaders() {
      this.headers.push({
        text: "Variant",
        value: "variantNameEn",
        width: 200
      });
      this.headers.push(
        { text: "Sunday", value: "sunday", width: 300 },
      );
      this.headers.push(
        { text: "Monday", value: "monday", width: 300 },
      );
      this.headers.push(
        { text: "Tuesday", value: "tuesday", width: 300 },
      );
      this.headers.push(
        { text: "Wednesday", value: "wednesday", width: 300 },
      );
      this.headers.push(
        { text: "Thursday", value: "thursday", width: 300 },
      );
      this.headers.push(
        { text: "Friday", value: "friday", width: 300 },
      );
      this.headers.push(
        { text: "Saturday", value: "saturday", width: 300 },
      );
      this.headers.push(
        { text: "Price", value: "price" },
        { text: "Strikethrough Price", value: "strikeThroughPrice" }, 
        { text: "", value: "actions" }
      );

      // if (this.allCategories) {
      //   this.allCategories.forEach((element) => {
      //     this.headers.push({ text: element.nameEn, value: element._id });
      //   });
      //   this.headers.push(
      //     { text: "Price", value: "price" },
      //     { text: "", value: "actions" }
      //   );
      // }
    },
    deleteItem(choice) {
      // console.log(item);
      const index = this.choices.findIndex(item => item === choice);
      this.choices.splice(index, 1);
      // console.log(index);
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    }
  }
};
</script>
