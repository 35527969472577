<template>
  <div class="bg">
    <comment-view :commentList="commentList" :openComment="openComment" @closeComment="closeComment" />
    <v-layout>
      <AddCustomerDietitianAppoinment :customer_id="customer_id"
        @updateDietitianAppointment="updateDietitianAppointment" />
      <!-- v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_ADD)" -->
    </v-layout>
    <!-- item-key="name" -->
    <!-- {{ dietitianAppointmentList }} -->
    <v-data-table :headers="headers" :items="dietitianAppointmentList" :expanded.sync="expanded" item-key="_id"
      show-expand class="elevation-1" :options.sync="options">
      <!-- <template v-slot:[`item.user`]="{ item }">
        <router-link
          v-if="item.customer"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id }
          }"
          class="mx-auto"
        >
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template> -->
      <template v-slot:[`item.dietitian`]="{ item }">
        <router-link :to="{
          name: 'DietitianDetails',
          params: { dietitianId: item.dietitian._id },
          query: { dietitianId: item.dietitian._id }
        }" class="mx-auto">
          {{
              item.dietitian.user.firstnameEn[0].toUpperCase() +
              item.dietitian.user.firstnameEn.substring(1)
          }}
          {{
              item.dietitian.user.lastnameEn[0].toUpperCase() +
              item.dietitian.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
      <template v-slot:[`item.bookedDate`]="{ item }">
        {{ bookedDate(item) }}
      </template>
      <template v-slot:[`item.appointmentDate`]="{ item }">
        {{
            new Date(parseInt(item.firstBooking.appointmentDate))
            | moment("DD MMM YYYY")
        }}
        : {{ item.firstBooking.appointmentTime }}
        <!-- {{ item.firstBooking ? appointmentDate(item.firstBooking) : "--" }} -->
      </template>
      <template v-slot:[`item.mode`]="{ item }">
        {{ item.firstBooking ? mode(item.firstBooking) : "--" }}
      </template>
      <template v-slot:[`item.shift`]="{ item }">
        {{ item.firstBooking ? shift(item.firstBooking) : "--" }}
      </template>
      <template v-slot:[`item.followUpType`]="{ item }">
        {{ item.firstBooking ? followUpType(item.firstBooking) : "--" }}
      </template>
      <template v-slot:[`item.actionType`]="{ item }">
        {{ item.firstBooking ? item.firstBooking.actionType : "--" }}
      </template>
      <template v-slot:[`item.actionStatus`]="{ item }">
        {{ item.firstBooking ? item.firstBooking.actionStatus : "--" }}
      </template>
      <template v-slot:[`item.comment`]="{ item }">

        <v-btn elevation="0" color="transparent " @click="showComments(item.firstBooking.comment)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.bookingFee`]="{ item }">
        {{ bookingFee(item.firstBooking) }}
      </template>
      <template v-slot:[`item.additionalFee`]="{ item }">
        {{ additionalFee(item.firstBooking) }}
      </template>
      <template v-slot:[`item.totalFee`]="{ item }">
        {{ totalFee(item.firstBooking) }}
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">
        <div>

          <span :class="
            item.followUp[0].payment_status == 'SUCCESS' || item.followUp[0].additional_payment_status == 'FREE'
              ? 'green--text'
              : item.followUp[0].payment_status == 'PENDING'
                ? 'orange--text'
                : item.followUp[0].payment_status == 'CANCELLED'
                  ? 'blue--text'
                  : item.followUp[0].payment_status == 'FAILED'
                    ? 'red--text'
                    : ''
          ">
            {{ item.followUp[0].payment_status }}

          </span>

        </div>

        <div
          v-if="item.followUp[0].actionStatus == 'FINISHED' && item.followUp[0].modeOfAppointment == 'ON_VISIT' && item.followUp[0].additional_payment_status">
          ADDITIONAL FEE :
          <span :class="
            item.followUp[0].additional_payment_status == 'SUCCESS' || item.followUp[0].additional_payment_status == 'FREE'
              ? 'green--text'
              : item.followUp[0].additional_payment_status == 'PENDING'
                ? 'orange--text'
                : item.followUp[0].additional_payment_status == 'CANCELLED'
                  ? 'blue--text'
                  : item.followUp[0].additional_payment_status == 'FAILED'
                    ? 'red--text'
                    : ''
          ">
            {{ item.followUp[0].additional_payment_status }}

          </span>
        </div>



      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span v-if="isPaymentPending(item.firstBooking)">
          <RegenerateApptPayment :appointmentId="item._id" :appointment="item.firstBooking" />
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="ma-0 pa-0" :colspan="headers.length" v-if="item.remainingFollowUp.length">
          <v-data-table :headers="headers" :items="item.remainingFollowUp" hide-default-footer>
            <template v-slot:[`item.dietitian`]="{}">
              <router-link :to="{
                name: 'DietitianDetails',
                params: { dietitianId: item.dietitian._id },
                query: { dietitianId: item.dietitian._id }
              }" class="mx-auto">
                {{
                    item.dietitian.user.firstnameEn[0].toUpperCase() +
                    item.dietitian.user.firstnameEn.substring(1)
                }}
                {{
                    item.dietitian.user.lastnameEn[0].toUpperCase() +
                    item.dietitian.user.lastnameEn.substring(1)
                }}
              </router-link>
            </template>
            <template v-slot:[`item.bookedDate`]="{}">
              {{ bookedDate(item) }}
            </template>
            <template v-slot:[`item.appointmentDate`]="{ item }">
              {{
                  new Date(parseInt(item.appointmentDate)) | moment("DD MMM YYYY")
              }}
              : {{ item.appointmentTime }}
              <!-- {{ appointmentDate(item.followUp) }} -->
            </template>
            <template v-slot:[`item.mode`]="{ item }">
              {{ mode(item) }}
            </template>
            <template v-slot:[`item.shift`]="{ item }">
              {{ shift(item) }}
            </template>
            <template v-slot:[`item.followUpType`]="{ item }">
              {{ followUpType(item) }}
            </template>
            <template v-slot:[`item.actionType`]="{ item }">
              {{ item.actionType }}
            </template>
            <template v-slot:[`item.actionStatus`]="{ item }">
              {{ item.actionStatus }}
            </template>
            <template v-slot:[`item.comment`]="{ item }">

              <v-btn elevation="0" color="transparent " @click="showComments(item.comment)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>


            </template>
            <template v-slot:[`item.bookingFee`]="{ item }">
              {{ bookingFee(item) }}
            </template>
            <template v-slot:[`item.additionalFee`]="{ item }">
              {{ additionalFee(item) }}
            </template>
            <template v-slot:[`item.totalFee`]="{ item }">
              {{ totalFee(item) }}
            </template>
            <template v-slot:[`item.paymentStatus`]="{ item }">


              <span :class="
                item.payment_status == 'SUCCESS' || item.payment_status == 'FREE'
                  ? 'green--text'
                  : item.payment_status == 'PENDING'
                    ? 'orange--text'
                    : item.payment_status == 'CANCELLED'
                      ? 'blue--text'
                      : item.payment_status == 'FAILED'
                        ? 'red--text'
                        : ''
              ">
                {{ item.payment_status }}

              </span>


              <div
                v-if="item.actionStatus == 'FINISHED' && item.modeOfAppointment == 'ON_VISIT' && item.additional_payment_status">
                ADDITIONAL FEE :
                <span :class="
                  item.additional_payment_status == 'SUCCESS' || item.additional_payment_status == 'FREE'
                    ? 'green--text'
                    : item.additional_payment_status == 'PENDING'
                      ? 'orange--text'
                      : item.additional_payment_status == 'CANCELLED'
                        ? 'blue--text'
                        : item.additional_payment_status == 'FAILED'
                          ? 'red--text'
                          : ''
                ">
                  {{ item.additional_payment_status }}

                </span>
              </div>

            </template>
            <template v-slot:[`item.actions`]="subitem">

              <span v-if="isPaymentPending(subitem.item)">

                <RegenerateApptPayment :appointmentId="item._id" :appointment="subitem.item" />

              </span>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import AddCustomerDietitianAppoinment from "../customerDietitian/AddCustomerDietitianAppoinment.vue";
// import DeleteCustomerDietitianAppt from "../customerDietitian/DeleteCustomerDietitianAppt.vue";
import CommentView from "@/dietitian/dietitian_settings/components/CommentView.vue";
import RegenerateApptPayment from "../customerDietitian/RegenerateApptPayment.vue";
export default {
  props: {
    customer_id: {
      required: true
    }
  },
  mounted() {
    this.$root.$on("updateDietitianAppointments", () => {
      this.loading = true;
      this.getSlotAppointmentDetails();
    });
  },
  components: {
    AddCustomerDietitianAppoinment,
    RegenerateApptPayment,
    CommentView
    // UpdateCustomerDietitianAppoinment,
    // DeleteCustomerDietitianAppt
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      dietitianAppointmentList: [],
      search: "",
      commentList: [],
      openComment: false,
      headers: [
        { text: "", value: "data-table-expand", width: 20 },
        // {
        //   text: "Sl No",
        //   value: "index",
        //   sortable: false,
        //   width: 70
        // },
        // { text: "Name", value: "user", sortable: false },
        // {
        //   text: "Mobile Number",
        //   value: "customer.user.phone",
        //   sortable: false
        // },
        {
          text: "Appointment On",
          value: "appointmentDate",
          sortable: false,
          width: 200
        },
        {
          text: "Booked Date",
          value: "bookedDate",
          sortable: false,
          width: 120
        },
        { text: "Mode", value: "mode", sortable: false, width: 120 },
        { text: "Shift", value: "shift", sortable: false, width: 120 },
        { text: "Dietitian", value: "dietitian", sortable: false, width: 120 },
        { text: "Type", value: "followUpType", sortable: false, width: 120 },
        {
          text: "Action Type",
          value: "actionType",
          sortable: false,
          width: 180
        },
        {
          text: "Action Status",
          value: "actionStatus",
          sortable: false,
          width: 180
        },
        { text: "Comment", value: "comment", sortable: false, width: 250 },
        {
          text: "Booking / Call Fee",
          value: "bookingFee",
          sortable: false,
          width: 120
        },
        {
          text: "Additional Fee",
          value: "additionalFee",
          sortable: false,
          width: 120
        },
        { text: "Total", value: "totalFee", sortable: false, width: 120 },
        {
          text: "Payment Status",
          value: "paymentStatus",
          sortable: false,
          width: 240
        },
        // { text: "Action", value: "actions", sortable: false, width: 10 }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      },

      input: {
        limit: 10,
        skip: 1
      },
      loading: true,
      customerId: this.$route.query.customerId,
      //
      expanded: [],
      followUpdata: []
      // singleExpand: false,
    };
  },
  watch: {
    options: {
      handler(item) {
        this.input.limit = item.itemsPerPage;
        this.input.skip = item.page;
        this.getSlotAppointmentDetails();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  // apollo: {
  //   getAllAppoinments() {
  //     return {
  //       query: require("../../api/getAllAppoinments.graphql"),
  //       variables: this.variables,
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.appointmentList = data.getAllAppoinments.appointments;
  //         this.totalItemsCount = data.getAllAppoinments.totalCount;
  //       }
  //     };
  //   }
  // },

  methods: {
    showComments(comment) {
      this.commentList = comment;
      this.openComment = true;
    },
    closeComment() {
      this.openComment = false;
    },
    isPaymentPending(item) {

      if (item) {
        if (item.payment_status == "PENDING" || item.additional_payment_status == "PENDING") {
          return true;
        }
      }
      return false;
    },
    updateDietitianAppointment() {
      this.getSlotAppointmentDetails();
    },

    bookingFee(followUp) {
      let result = "";
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.bookingFee) {
          result = followUp.bookingFee;
        }
      } else {
        if (followUp.onCallFee) {
          result = followUp.onCallFee;
        }
      }
      return result;
    },
    additionalFee(followUp) {
      let result = "";
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.additionalFee) {
          result = followUp.additionalFee;
        }
      }

      return result;
    },
    paymentStatus(followUp) {
      let result = "--";
      if (followUp.actionStatus == "FINISHED") {
        if (followUp.additional_payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.additional_payment_status;
        }
      } else {
        if (followUp.payment_status) {
          // if (followUp.payment_status == "SUCCESS") {
          //   result = "Success";
          // }
          result = followUp.payment_status;
        }
      }

      return result;
    },
    totalFee(followUp) {
      let result = 0;
      if (followUp.modeOfAppointment == "ON_VISIT") {
        if (followUp.bookingFee) {
          result += parseFloat(followUp.bookingFee);
        }
        if (followUp.additionalFee) {
          result += parseFloat(followUp.additionalFee);
        }
      } else result = followUp.onCallFee;
      return result;
    },
    bookedDate(followUp) {
      if (followUp.createdDate) {
        return moment(parseInt(followUp.createdDate)).format("DD MMM YYYY");
      }
    },
    appointmentDate(followUp) {
      if (followUp.appointmentDate) {
        let res1 = moment(parseInt(followUp.appointmentDate)).format(
          "DD MMM YYYY"
        );
        let res2 = followUp.appointmentTime;
        let result = res1 + " : " + res2;
        return result;
      }
    },
    mode(followUp) {
      if (followUp.modeOfAppointment) {
        let result = "--";
        if (followUp.modeOfAppointment == "ON_CALL") {
          result = "On Call";
        }
        if (followUp.modeOfAppointment == "ON_VISIT") {
          result = "Visit";
        }
        return result;
      }
    },
    shift(followUp) {
      let result = "--";
      if (followUp.shift) {
        if (followUp.shift == "MORNING_SHIFT") {
          result = "Morning Shift";
        }
        if (followUp.shift == "EVENING_SHIFT") {
          result = "Evening Shift";
        }
      }
      return result;
    },
    followUpType(followUp) {
      let result = "--";
      if (followUp.followType > 0) {
        result = `Follow Up - ${followUp.followType}`;
      } else if (followUp.followType == 0) {
        result = "First";
      }
      return result;
    },
    getSlotAppointmentDetails() {
      this.loading = true;

      this.$apollo
        .watchQuery({
          query: require("@/dietitian/dietitian_settings/api/getCustomerSlotAppointment.graphql"),
          variables: {
            input: this.input,
            customerId: this.customerId
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;

          if (data.data) {
            // if(data.data.getAllSlotAppointments.slotAppointments.length){
            //   for(let x of )
            // }
            this.dietitianAppointmentList =
              data.data.getCustomerSlotAppointments.slotAppointments;
            for (let [
              i,
              appointment
            ] of this.dietitianAppointmentList.entries()) {
              appointment.index =
                i + (this.options.page - 1) * this.options.itemsPerPage + 1;
              // let shiftedArray = appointment.followUp.shift();
              // appointment.firstBooking = shiftedArray;
              appointment.firstBooking = appointment.followUp[0];
              appointment.remainingFollowUp = appointment.followUp.filter(
                (x, i) => i !== 0
              );
              console.log(appointment.remainingFollowUp.length);
            }
            console.log(data.data.getCustomerSlotAppointments);
            // this.oldValue = data.data.getCustomerSlotAppointments.slotAppointments;
            this.totalItemsCount =
              data.data.getCustomerSlotAppointments.totalCount;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    // isPaymentPending(appt) {
    //   if (appt.payment_status == "PENDING" && appt.paymentUrl) {
    //     return true;
    //   }
    //   return false;
    // },
    numberWithCommas(x) {
      if (!x) {
        return 0;
      }
      return parseFloat(x)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>

<style scoped>
</style>
