<template>
  <div>
    <v-dialog v-model="showDialog" max-width="1000">
      <template v-slot:activator="{ on }">
        <v-btn @click="runSearch()" medium class="" color="primary" v-on="on">
          <v-icon small class="me-2">mdi-magnify</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <span class="black--text text-h6"> Meals</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="show_dialog_box1 = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout justify-space-between class="mt-n4 mt-md-0" wrap>
            <v-flex xs12 sm5 md4>
              <v-text-field placeholder="Meals" v-model="search" solo dense @keydown.enter="runSearch()">
                <template v-slot:append>
                  <v-icon v-if="search.length > 0" color="black" class="me-n2" @click="clearSearch()">mdi-close</v-icon>
                  <v-icon v-if="search.length == 0" color="black" class="me-n2" @click="runSearch()">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm5 md2>
              <v-select :items="listOfDays" placeholder="Day" v-model="dayFilter" dense attach solo></v-select>
            </v-flex>
            <v-flex xs12 sm5 md2>
              <v-select :items="dietPlans" placeholder="All" label="ALL" v-model="typeFilter" item-text="nameEn" item-value="_id" dense attach solo></v-select>
            </v-flex>
            <v-flex xs12 sm5 md2>
              <v-select :items="['All', 'Active', 'InActive']" placeholder="Status" label="ALL" v-model="statusFilter" dense attach solo></v-select>
            </v-flex>
          </v-layout>
          <v-layout justify-end class="my-5">
            <app-menu-form class="mx-5" v-if="hasRole(AdminUserPermissions.MEAL_ADD)"></app-menu-form>
            <!-- <AuditTrialLog class="mt-md-n6 me-4" auditType="MEALS_LOG" /> -->
          </v-layout>
          <v-row class="ms-n12 ms-sm-n16 ms-md-n4">
            <v-tabs background-color="bg" slider-size="3" center-active class="ms-sm-n8" slider-color="black">
              <v-tab @click="menuItemsByCategory(null)" class="text-caption text-sm-body-2 font-weight-medium text-capitalize black--text ms-sm-12">All</v-tab>
              <v-tab
                @click="menuItemsByCategory(category._id)"
                v-for="category in allCategories"
                :key="category._id"
                :value="category"
                class="text-caption text-sm-body-2 font-weight-medium black--text text-capitalize"
                >{{ category.nameEn }}</v-tab
              >
            </v-tabs>
          </v-row>
          <v-divider class="me-1"></v-divider>

          <div>
            <v-data-table
              :mobile-breakpoint="0"
              :headers="headers"
              :items="menuList"
              class="elevation-1 mt-10"
              :options.sync="options"
              :loading="loading"
              :server-items-length="totalItemsCount"
              :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
            >
              <template #[`item.itemCode`]="{ item }">
                <span v-if="hasRole(AdminUserPermissions.MEAL_DETAILS_VIEW)">
                  <router-link
                    :to="{
                      name: 'MealDetails',
                      params: { mealId: item._id },
                      query: { mealId: item._id },
                    }"
                    class="mx-auto"
                  >
                    M {{ item.itemCode }}
                  </router-link>
                </span>
                <span v-else> M {{ item.itemCode }} </span>
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <v-checkbox :disabled="checkBoxDisable" color="primary" disabled-color="primary" @change="onStatusChange(item)" v-model="item.active"></v-checkbox>
                <!-- <v-icon v-show="item.active" color="primary"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon v-show="!item.active" color="grey"
            >mdi-checkbox-blank-outline</v-icon
          > -->
              </template>

              <template v-slot:[`item.availableWeekdays`]="{ item }">
                <span v-if="item.availableWeekdays">
                  {{ item.availableWeekdays.join(", ") }}
                </span>
              </template>
              <template v-slot:[`item.dietPlans`]="{ item }">
                <div v-if="item.dietPlans">
                  <div v-for="plan in item.dietPlans" :key="plan._id">
                    {{ plan.nameEn }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.rating`]="{ item }">
                <v-rating x-small length="5" :value="item.averageRating.rating" :readonly="true"></v-rating>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="primary" class="text-capitalize white--text me-3" height="40px" @click="onSelectMeal(item)">
                  Select
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  created() {
    if (this.hasRole(this.AdminUserPermissions.MEAL_STATUS)) {
      this.checkBoxDisable = false;
    }
    if (!this.hasRole(this.AdminUserPermissions.MEAL_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },
  props: {
    search: {
      required: true,
    },
  },

  watch: {
    options: {
      handler() {
        this.refetechMenuItems();
      },
      deep: true,
    },
    dayFilter: {
      handler() {
        this.refetechMenuItems();
      },
      deep: true,
    },
    typeFilter: {
      handler() {
        this.refetechMenuItems();
      },
      deep: true,
    },
    statusFilter: {
      handler() {
        this.refetechMenuItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      checkBoxDisable: true,
      statusChangeLoader: false,
      selectedMeal: "",
      statusDialogBox: false,
      customersUsingMeal: [],
      selectCatId: "",
      allCategories: [],
      dayFilter: "All",
      typeFilter: "",
      statusFilter: "All",
      variables: {
        limit: 25,
        skip: 0,
        category: "",
        sortDesc: false,
        sortBy: "",
        dayFilter: "All",
        typeFilter: "",
        statusFilter: "All",
      },
      loading: true,
      listOfDays: ["All", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dietPlans: [],
      menuList: [],
      totalItemsCount: 0,
      searchCounter: 0,
      showDialog: false,
      options: {
        itemsPerPage: 25,
        page: 1,
        sortDesc: [false],
        sortBy: [],
      },
      headers: [
        {
          text: "Menu Code",
          value: "itemCode",
          width: 100,
        },
        {
          text: "Meal(English)",
          value: "nameEn",
          width: 180,
        },
        {
          text: "Meal(Arabic)",
          value: "nameAr",
          width: 180,
        },
        {
          text: "Type",
          value: "dietPlans",
          width: 100,
          sortable: false,
        },
        {
          text: "Customer Rating",
          value: "rating",
          sortable: false,
          width: 100,
        },

        {
          text: "Actions",
          value: "actions",
          width: 200,
          sortable: false,
        },
      ],
    };
  },
  apollo: {
    getAllMenuItems() {
      return {
        query: require("@/menu-packages/api/getAllMenuItems.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.menuList = [];
          this.totalItemsCount = 0;
          if (data) {
            this.menuList = data.getAllMenuItems.menuItem;
            this.totalItemsCount = data.getAllMenuItems.totalCount;
          }
        },
      };
    },

    getAllDietPlans() {
      return {
        query: require("@/menu-packages/diet-plan/api/getAllDietPlans.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.dietPlans = data.getAllDietPlans;
          this.loading = loading;
        },
      };
    },
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data }) {
          // this.loading = loading;
          this.allCategories = data.getAllCategories.category;
        },
      };
    },
  },
  methods: {
    onSelectMeal(meal) {
      debugger;
      this.showDialog = false;
      this.$emit("onSelectMeal", meal);
    },
    updateStatusDialogBox(menu, status, disableStatus) {
      if (status == true) {
        this.selectedMeal.active = false;
        this.mealChangeStatus(menu, disableStatus);
      } else if (status == false) {
        this.selectedMeal.active = true;
      }
      // this.refetechMenuItems();
      this.statusDialogBox = false;
    },
    refetechMenuItems() {
      (this.variables.limit = this.options.itemsPerPage), (this.variables.skip = this.options.page * this.options.itemsPerPage - this.options.itemsPerPage);
      if (this.$apollo.queries.getAllMenuItems)
        this.$apollo.queries.getAllMenuItems.refetch({
          limit: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
          skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
          sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : "",
          sortBy: this.options.sortBy ? this.options.sortBy[0] : "",
          dayFilter: this.dayFilter,
          typeFilter: this.typeFilter,
          statusFilter: this.statusFilter,
        });
    },

    menuItemsByCategory(id) {
      this.selectCatId = id;
      this.$apollo.queries.getAllMenuItems.refetch({
        limit: 25,
        skip: 0,
        category: id,
      });
      // console.log(this.menuList);
    },

    runSearch() {
      this.searchCounter = this.searchCounter + 1;
      this.$apollo.queries.getAllMenuItems.refetch({
        limit: 25,
        skip: 0,
        category: this.selectCatId,
        searchKey: this.search,
      });
    },
    clearSearch() {
      this.search = "";
      this.$apollo.queries.getAllMenuItems.refetch({
        limit: 25,
        skip: 0,
        category: this.selectCatId,
        searchKey: "",
      });
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
