var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fromCustomer),expression:"!fromCustomer"}],attrs:{"justify":"end"}},[_c('div',{staticClass:"d-flex px-4 my-2 my-sm-0"},[_c('ExportOrders',{attrs:{"headers":_vm.filteredHeaders,"zone":_vm.zone,"deliveryStatus":_vm.deliveryStatus,"paymentStatus":_vm.paymentStatus,"fromDate":_vm.deliveryDate,"toDate":_vm.orderDate}})],1)]),_c('v-row',{staticClass:"justify-space-between"}),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fromCustomer),expression:"!fromCustomer"}],attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-spacer'),_c('v-spacer')],1),_c('v-spacer'),_c('v-layout',{staticClass:"mt-2",attrs:{"row":""}}),_c('v-row',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.allOrders,"search":_vm.search,"loading":_vm.loading,"mobile-breakpoint":"0","dense":"","options":_vm.options,"server-items-length":_vm.totalOrders,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":_vm.onOrderSelect},model:{value:(item.isChecked),callback:function ($$v) {_vm.$set(item, "isChecked", $$v)},expression:"item.isChecked"}})]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('ViewOrderDetails',{attrs:{"orderInfo":item}})]}},{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderNumber)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id },
          }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.user.lastnameEn))+" ")])]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [(item.customer)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.customer.user.phone)+" ")]):_vm._e()]}},{key:"item.zone",fn:function(ref){
          var item = ref.item;
return [(item.zone)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.zone ? item.zone.zoneName : "-")+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
          var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")])]}},{key:"item.discount",fn:function(ref){
          var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.discount))+" ")])]}},{key:"item.netPrice",fn:function(ref){
          var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.netPrice))+" ")])]}},{key:"item.paymentStatus",fn:function(ref){
          var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0",class:{
            'red--text': item.paymentStatus == 'PENDING',
          }},[_c('strong',[_vm._v(" "+_vm._s(item.paymentStatus))])])]}},{key:"item.deliveryStatus",fn:function(ref){
          var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0",class:{
            'red--text': item.paymentStatus == 'SUCCESS' && item.deliveryStatus == 'PENDING',
          }},[_c('strong',[_vm._v(" "+_vm._s(item.deliveryStatus))])])]}},{key:"item.createdBy",fn:function(ref){
          var item = ref.item;
return [(item.createdBy)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(_vm._s(item.createdBy.firstnameEn)+" "+_vm._s(item.createdBy.lastnameEn))]):_vm._e()]}},{key:"item.orderDate",fn:function(ref){
          var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0 gray2--text"},[_vm._v(" "+_vm._s(_vm.moment(new Date(parseInt(item.createdAt))).format("DD MMM YYYY"))+" ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(item.paymentStatus == 'PENDING')?_c('CheckPaymentStatus',{attrs:{"item":item}}):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }