<template>
  <v-card class="px-4" color="">
    <v-card-text class="pa-0 pt-2">
      <v-container class="mt-2">
        <v-form v-model="valid" ref="form">
          <v-form v-model="validProduct" ref="formProduct">
            <v-layout wrap>
              <v-flex xs12 sm8 md3>
                <v-autocomplete v-model="product" :items="products" item-text="nameEn" background-color="white"
                  item-value="_id" chips dense outlined small-chips label="Search products" clearable deletable-chips
                  :loading="productLoading" :rules="[requiredValidator('product')]">
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 sm8 md3 offset-sm-3 offset-md-0 class="ms-md-5">
                <v-text-field v-model="quantity" :rules="[requiredValidator('quantity')]" outlined
                  background-color="white" label="quantity" placeholder="Quantity" dense></v-text-field>
              </v-flex>
              <v-flex xs12 sm8 md3 offset-sm-3 offset-md-0 class="ms-md-5">
                <v-btn class="white--text text-capitalize mt-1" color="secondary" depressed :disabled="!validProduct"
                  @click="addProductToPurchase">
                  <v-icon class="me-1" small>mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>

          <v-layout wrap>
            <v-flex xs12 sm8 md12>
              <v-data-table :headers="headers" :items="itemsWithSno" hide-default-footer mobile-breakpoint="0"
                class="row-pointer">
                <template v-slot:[`item.productName`]="{ item }">
                  {{ item.product.nameEn }}
                </template>
                <template v-slot:[`item.quantity`]="{ item }">
                  {{ item.quantity }}
                </template>
                <template v-slot:[`item.strike`]="{ item }">
                  {{ item.product.price }}
                </template>
                <template v-slot:[`item.discount`]="{ item }">
                  {{ item.product.reductionPrice }}
                </template>
                <template v-slot:[`item.totalDiscount`]="{ item }">
                  {{ item.product.reductionPrice * item.quantity }}
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  {{ item.product.effectivePrice * item.quantity }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn @click="onRemove(item)" color="black" text>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table></v-flex>
          </v-layout>

          <v-divider></v-divider>
          <v-spacer></v-spacer>

          <v-row>
            <v-col col="3">
            </v-col>
            <v-col class="text-right">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">Total </h4>
            </v-col>
            <v-col>
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">{{ (totalActualPrice).toFixed(3) }}</h4>
            </v-col>
          </v-row>


          <v-row class="mt-n6">
            <v-col col="3">
            </v-col>
            <v-col class="text-right">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">Discount </h4>
            </v-col>
            <v-col>
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">{{ strikeDiscount ? strikeDiscount.toFixed(3) : '0' }}
              </h4>
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col col="3">
            </v-col>
            <v-col class="text-right">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">Sub Total </h4>
            </v-col>
            <v-col>
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">{{ getSubTotal() }}
              </h4>
            </v-col>
          </v-row>


          <v-row class="mt-n6">
            <v-col col="1">
            </v-col>
            <v-col class="">
              <v-divider :thickness="5" width="300" class="mb-5"></v-divider>
            </v-col>

          </v-row>

          <v-row class="mt-n6">
            <v-col col="3">
            </v-col>
            <v-col class="text-right">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">Delivery Charge </h4>
            </v-col>
            <v-col>
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2">{{ deliveryCharge ? deliveryCharge.toFixed(3) : 0 }}
              </h4>
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col col="3">
            </v-col>
            <v-col class="text-right">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2"><strong>Net Amount</strong> </h4>
            </v-col>
            <v-col class="">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2"><strong>{{ netPrice ? netPrice.toFixed(3) : '0' }}
                  KD</strong>
              </h4>
            </v-col>


          </v-row>

          <v-row class="">
            <v-col col="3">
            </v-col>
            <v-col class="text-right">
              <h4 class="mb-3 mb-sm-0 text-body-2 black--text mt-2"><strong>Payment Method</strong> </h4>
            </v-col>

            <v-col class="">
              <v-select solo dense :rules="[requiredValidatorSelect('payment method')]" attach
                @change="onPaymentMethodChange" item-text="text" v-if="!fromSales" item-value="value"
                v-model="paymentMethod" :items="avalilablePaymentMethod">
              </v-select>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>
          <strong class="mt-5"> Choose your delivery address</strong>
          <v-radio-group v-model="selectdAddress" @change="onAddressChange">
            <v-layout wrap>
              <v-flex md3 class="ma-4" v-for="addressItem in addressList" :key="addressItem._id">
                <v-row class="mt-3 " align="start">
                  <v-radio :value="addressItem._id">
                    {{ addressItem }},
                  </v-radio>
                  <div class="">
                    <p v-if="addressItem.area" class="mb-0">
                      Area: {{ addressItem.area.nameEn }},
                    </p>
                    <span v-if="addressItem.block">
                      Block: {{ addressItem.block.block }},
                    </span>
                    <p v-if="addressItem.Street" class="mb-0">
                      Street:{{ addressItem.Street }},
                    </p>
                    <span v-if="addressItem.house_number">
                      House number : {{ addressItem.house_number }},
                    </span>
                    <p v-if="addressItem.building_number">
                      Floor : {{ addressItem.building_number }}
                    </p>
                  </div>
                </v-row>
              </v-flex>
            </v-layout>
          </v-radio-group>
          <v-layout row justify-end class="mt-2 mb-4 me-1">
            <v-btn :width="$vuetify.breakpoint.smAndUp ? 120 : 90" color="secondary" v-if="!fromSales" :disabled="!valid"
              class="white--text text-capitalize" @click="onOrder()" :loading="btnloading">
              Save</v-btn>
          </v-layout>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  requiredValidator,
  requiredValidatorSelect,
} from "../../core/methods/validations";
export default {
  apollo: {
    products() {
      return {
        query: require("../api/products.graphql"),
        variables: { input: { skip: 0, limit: 0 } },
        result({ data, loading }) {
          this.products = data.products.products;
          this.productLoading = loading;
        },
      };
    },
    getAppConfigurations() {
      return {
        query: require("@/settings/api/getAppConfigurations.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.deliveryCharge = data.getAppConfigurations.deliveryCharge;
        }
      };
    }
  },


  mounted() {
    this.$root.$on("orderPlaced", () => {
      this.btnloading = true;
      this.purchaseProducts = [];
      this.$refs.form.reset();
    });
  },
  props: {
    fromSales: {
      required: false,
    },
    btnloading: {
      required: false,
    },
  },
  created() {
    this.getCustomerDetails();
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[2];
      this.avalilablePaymentMethod.push(data);
    }
  },

  data() {
    return {
      deliveryCharge: 0,
      strikeDiscount: 0,
      productLoading: true,
      avalilablePaymentMethod: [],
      quantity: "",
      netPrice: "",
      totalPrice: 0,
      totalActualPrice: 0,
      discount: "",
      product: "",
      requiredValidator,
      requiredValidatorSelect,
      valid: true,
      validProduct: true,
      errors: [],
      snackbar: false,
      text: "",
      snackColor: "",
      show_dialog_box: false,
      products: [],
      purchaseProducts: [],
      selectdAddress: null,
      customer: null,
      hasAddress: false,
      headers: [
        { text: "#", sortable: false, value: "slNo", width: 100 },
        { text: "Product Name", value: "productName", width: 200 },
        { text: "Quantity", value: "quantity", width: 100 },
        { text: "Price", value: "strike", width: 120, sortable: false },
        { text: "Discount (Per Item)", value: "discount", width: 120 },
        { text: "Total Discount", value: "totalDiscount", width: 120 },
        { text: "Payable Amount", value: "price", width: 120 },
        { text: "Action", value: "action", width: 120 },
      ],
      paymentMethod: "ONLINE",
      addressList: [],
      paymentMethods: [
        {
          value: "ONLINE",
          text: "Online",
        },
        {
          value: "OFFLINE",
          text: "Offline",
        },
        {
          value: "FREE",
          text: "Free",
        },
      ],
    };
  },

  methods: {
    getCustomerDetails() {
      this.$apollo
        .query({
          query: require("../../customer/api/getCustomerById.graphql"),
          variables: {
            id: this.$route.query.customerId,
          },
        })
        .then((data) => {
          this.loading = false;

          if (data.data.getCustomerById) {
            this.customer = data.data.getCustomerById;
            this.addressList = this.customer.addressDetails;
            if (this.addressList.length > 0) {
              this.hasAddress = true;
              this.selectdAddress = this.addressList[0]._id;
            }
          }
          this.onAddressChange();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getSubTotal() {
      let total = this.netPrice - this.deliveryCharge;
      if (total > 0)
        return total;
      return 0;
    },
    onAddressChange() {
      debugger
      let selectdAddress = this.selectdAddress;
      this.$emit("updateShoppingAddress", selectdAddress);
    },
    onPaymentMethodChange() {
      let input = {
        paymentMethod: this.paymentMethod,
        discount: this.discount,
        selectdAddress: this.selectdAddress,
      };
      this.$emit("updateShoppingInfo", input);

      if (this.paymentMethod == "FREE") {
        this.netPrice = 0;
        this.discount = 0;
      } else {
        this.reCalculateTotalPrice();
      }
    },
    close() {
      this.show_dialog_box = false;
    },
    reCalculateTotalPrice() {
      let totalPrice = 0;
      let totalActualPrice = 0;
      debugger
      this.purchaseProducts.forEach((element) => {
        totalPrice =
          totalPrice + parseFloat(element.product.effectivePrice) * element.quantity;

        totalActualPrice =
          totalActualPrice + parseFloat(element.product.price) * element.quantity;
      });
      this.totalPrice = totalPrice;
      this.totalActualPrice = totalActualPrice;
      this.netPrice =
        totalPrice - parseFloat(this.discount ? this.discount : 0) + this.deliveryCharge;
      if (this.netPrice < 0) {
        this.discount = 0;
        this.netPrice = totalPrice + this.deliveryCharge;
      }

      let input = {
        discount: this.discount,
        paymentMethod: this.paymentMethod,

      };

      this.$emit("updateShoppingInfo", input);
    },
    addProductToPurchase() {
      let isExist = this.purchaseProducts.find((x) => x.id == this.product);
      if (!isExist) {
        let product = this.products.find((x) => x._id == this.product);
        this.purchaseProducts.push({
          product: product,
          quantity: this.quantity,
          id: product._id,
        });

        let totalDiscount = 0;
        this.purchaseProducts.forEach(product => {
          debugger
          let diff = product.product.reductionPrice * product.quantity;
          totalDiscount = totalDiscount + diff;
        });
        this.strikeDiscount = totalDiscount;
        this.$refs.formProduct.reset();
        let input = {
          purchaseProducts: this.purchaseProducts,
          discount: this.discount,

          paymentMethod: this.paymentMethod,
        };
        this.$emit("updateShopping", input);
      } else {
        this.$emit("showSnackBar", {
          snackbar: true,
          snackColor: "error",
          text: "Product already added",
        });
      }
      this.reCalculateTotalPrice();
    },
    onRemove(item) {
      this.purchaseProducts = this.purchaseProducts.filter(
        (x) => x.id != item.id
      );
      this.reCalculateTotalPrice();
    },
    onOrder() {
      if (this.purchaseProducts.length == 0) {
        this.$emit("showSnackBar", {
          snackbar: true,
          snackColor: "error",
          text: "Please add products",
        });
      } else {
        this.$emit("onOrder", this.purchaseProducts);
      }
    },
  },
  computed: {
    itemsWithSno() {
      return this.purchaseProducts.map((d, index) => ({
        ...d,
        slNo: index + 1,
      }));
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
