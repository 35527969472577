<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text font-weight-bold text-capitalize"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Category</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createFaq()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Category English</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    solo
                    name="input-7-4"
                    label="Category (English)"
                    v-model="nameEn"
                    :rules="[requiredValidator('category')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Category Arabic</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    solo
                    name="input-7-4"
                    label="Category (Arabic)"
                    v-model="nameAr"
                    :rules="[requiredValidator('category')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Active</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-radio-group v-model="active" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                    @keypress="isNumber"
                    label="Show Order"
                    type="number"
                    v-model="showOrder"
                    min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <ProductCategoryDiscount
                :itemDiscount="itemDiscount"
                class="mt-4"
                type="category"
              />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-end">
          <v-btn
            color="secondary"
            class="black--text mb-2 mb-sm-4 text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="createProductCategory"
            :loading="btnloading"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import ProductCategoryDiscount from "@/discount/components/ProductCategoryDiscount";
export default {
  components: { ProductCategoryDiscount },
  data() {
    return {
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      nameEn: "",
      nameAr: "",
      showOrder: 0,
      active: true,
      show_dialog_box: false,
      requiredValidator: requiredValidator,
      itemDiscount: {
        enableDiscount: false,
        discount: 0,
        inPercentage: false,
        maxDiscountAmount: 0,
        startDate: "",
        endDate: ""
      }
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    createProductCategory() {
      this.btnloading = true;

      let discount = null;

      if (this.itemDiscount.enableDiscount) {
        discount = {
          startDate: this.itemDiscount.startDate,
          endDate: this.itemDiscount.endDate,
          discount: parseFloat(this.itemDiscount.discount),
          inPercentage: this.itemDiscount.inPercentage,
          maxDiscountAmount: parseFloat(this.itemDiscount.maxDiscountAmount)
        };
      }
      this.$apollo
        .mutate({
          mutation: require("../api/createProductCategory.graphql"),
          variables: {
            input: {
              nameEn: this.nameEn,
              nameAr: this.nameAr,
              status: this.active,
              order: parseInt(this.showOrder),
              discount: discount
            }
          },
          update: (store, { data: { createProductCategory } }) => {
            try {
              const query = {
                query: require("../api/getAllProductCategories.graphql")
              };

              let allCategory = store.readQuery(query);

              allCategory.getAllProductCategories.unshift(
                createProductCategory
              ); //push to top
              store.writeQuery({
                ...query,
                data: allCategory
              });

              this.text = "Product Category";
              this.snackbar = true;
              this.btnloading = false;
              this.show_dialog_box = false;
              this.$refs.form.reset();
            } catch (e) {
              console.log("Something bad happend- Category.vue");
              console.log(e);
              this.btnloading = false;
            }

            this.show_dialog_box = false;
            this.btnloading = false;
          }
        })

        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.show_dialog_box = false;
        });
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
