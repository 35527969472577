<template>
  <div>
    <div v-if="!loading">
      <v-row>
        <v-col>
          <v-expansion-panels flat>
            <v-expansion-panel flat class=" mb-5   lighten-2  white ">
              <v-expansion-panel-header>
                <div class="d-flex align-center">
                  <v-icon class="mr-2" small>mdi-shopping</v-icon>
                  <p class="mb-0">{{ order.products.length }} Items</p>
                </div>
              </v-expansion-panel-header>
              <hr />
              <v-expansion-panel-content style="padding:0px !important;">
                <v-card flat tile max-height="180px" style="overflow-y:auto">
                  <div v-for="item in order.products" :key="item._id">
                    <v-sheet class=" mt-4 d-flex justify-space-between">
                      <div class="d-flex ">
                        <div class="mr-5" v-if="item.product">
                          <img width="40px" height="40px" class="rounded-lg" :src="imageUrl + item.product.image" alt="" />
                        </div>
                        <div class="mr-5">{{ item.quantity }}x</div>
                        <v-card max-width="200px" flat tile transparent class="mr-5 font-weight-medium"> {{ item.productInfo.nameEn }}</v-card>
                      </div>
                      <strike>
                        <div v-if="item.reductionPrice">KD {{ item.productInfo.price + item.reductionPrice }}</div>
                      </strike>
                      <div>KD {{ item.productInfo.price * item.quantity }}</div>
                    </v-sheet>
                    <div class="mb-2 mt-4 gray " style="height:0.3px"></div>
                  </div>
                </v-card>
                <div class=" mt-4 pb-4">
                  <div class="d-flex justify-space-between mb-2">
                    <span> Subtotal </span>
                    <span>KD {{ order.total }}</span>
                  </div>
                  <div class="d-flex justify-space-between mb-2">
                    <span>Promo Discount</span>
                    <span>KD {{ order.discount ? order.discount : 0 }}</span>
                  </div>

                  <div class="d-flex justify-space-between mb-2">
                    <span>Delivery fee</span>
                    <span>KD {{ order.deliveryCharge ? order.deliveryCharge : 0 }} </span>
                  </div>

                  <div class="mb-2 mt-4 gray " style="height:0.3px"></div>
                  <div class="d-flex justify-space-between font-weight-bold">
                    <span>Total amount</span>
                    <span>KD {{ getTotalPrice() }}</span>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col>
          <v-card width="100%" color="white" flat tile class="mb-4  pb-4 ">
            <div class="d-flex justify-space-between pa-3">
              <div>
                <p class="font-weight-bold mb-1">
                  {{ order.customer.user.firstnameEn }}
                  {{ order.customer.user.lastnameEn }}
                </p>
                <p class="gray--text text-body-2">#{{ order.customer.customerId }}</p>
              </div>
              <CancelOrder v-if="tab == 0 && order.deliveryStatus == 'PENDING'" :item="order" />
            </div>
            <div class="px-3">
              <p class="primary--text"><v-icon color="primary" small>mdi-phone</v-icon> {{ order.customer.user.phone }}</p>
            </div>

            <div class="mb-2 mt-4 gray " style="height:0.3px"></div>
            <div class="px-4 d-flex align-start pt-4">
              <v-icon class="mr-2">mdi-map-marker</v-icon>
              <v-card max-width="300px" flat tile>
                Area, Block : {{ order.address.area.nameEn }}, {{ order.address.block.block }} {{ order.address.street }}, {{ order.address.jedha }}, {{ order.address.house_number }},
                {{ order.address.building_number }}
                {{ order.address.apartment_number }}
              </v-card>
            </div>
          </v-card>
          <v-expansion-panels flat class="">
            <v-expansion-panel flat class="mb-5   lighten-2  white ">
              <v-expansion-panel-header>
                <div class="d-flex align-center">
                  <Icon class="mr-2"> <v-icon class="" small>mdi-motorbike</v-icon> </Icon>

                  <p class="mb-0 font-weight-medium" v-if="orderInfo.delivery && orderInfo.delivery.driver && orderInfo.delivery.driver.user">
                    Delivery
                    {{ orderInfo.delivery.driver.user.firstnameEn }}
                    {{ orderInfo.delivery.driver.user.lastnameEn }}
                  </p>
                </div>
              </v-expansion-panel-header>
              <hr />
              <v-expansion-panel-content class="my-4">
                <div class="d-flex justify-space-between" v-if="orderInfo.delivery">
                  <p v-if="orderInfo.delivery.deliveryAssignedTime">
                    Assigned at

                    {{
                      moment(new Date(parseInt(orderInfo.delivery.deliveryAssignedTime)))
                        .tz(TIME_ZONE)
                        .format("hh:mm A, DD MMMM")
                    }}
                  </p>

                  <AssignDriver v-if="tab == 0 && order.deliveryStatus == 'DISPATCHED'" :item="orderInfo" :reassign="true" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel> </v-expansion-panels
        ></v-col>
      </v-row>
      <v-row no-gutters v-if="tab != 2">
        <v-col class="pr-2">
          <v-card width="100%" color="white" flat tile class="mb-4  pb-4">
            <div class="d-flex justify-space-between px-3 pt-3">
              <div>
                <p class="font-weight-bold mb-1">Payment methods</p>
              </div>
              <p class="font-weight-medium">KNET</p>
            </div>
            <div class="mb-2  gray " style="height:0.3px"></div>

            <p class="text-body-2 px-4 mt-3 py-0 mb-0">Print</p>
            <div class="px-4 d-flex align-start pt-0">
              <div class="d-flex pt-0">
                <!--
                                <v-radio-group v-model="language" row class="mr-12 text-body-2">
                                    <v-radio label="English" value="english"></v-radio>
                                    <v-radio label="عربي" value="arabic"></v-radio>
                                </v-radio-group>
                                -->
                <v-radio-group v-model="paperType" row>
                  <v-radio label="A4" value="A4"></v-radio>
                  <v-radio label="Thermal" value="Thermal"></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="px-4">
              <v-sheet outlined color="primary" rounded="lg">
                <v-card outlined @click="onPrintClick" class="primary--text text-left py-2 px-2 d-flex justify-space-between align-center " rounded="lg">
                  <p class="mb-0">Print Reciept</p>
                  <v-btn @click="onPrintClick" color="success" text><v-icon color="primary">mdi-printer</v-icon></v-btn>
                </v-card>
              </v-sheet>
            </div>
            <!-- <v-card class="px-4 " rounded="lg" flat tile>
                            <v-card class="gray lighten-4  pa-3 mt-4" rounded="lg" flat>
                                <p class="mb-0 font-weight-bold">Fees</p>
                                <div class="mt-1 d-flex justify-space-between">
                                    <p class="mb-0">Engage fees</p>
                                    <p class="mb-0">KWD 0.250</p>

                                </div>
                            </v-card>
                        </v-card> -->
          </v-card>
        </v-col>
        <v-col>
          <v-expansion-panels flat class="pl-1">
            <v-expansion-panel flat class=" mb-5   lighten-2  white ">
              <v-expansion-panel-header>
                <div class="d-flex align-center">
                  <v-icon class="mr-2" small>mdi-filter-variant</v-icon>
                  <p class="mb-0 font-weight-medium">Order timeline</p>
                </div>
              </v-expansion-panel-header>
              <hr />
              <v-expansion-panel-content style="padding:0px !important; " class="my-4">
                <v-card flat tile max-height="180px" style="overflow-y:auto">
                  <div v-for="item in order.changeHistory" :key="item">
                    <v-sheet class="  d-flex justify-space-between">
                      <div class="d-flex ">
                        <div class="mr-5">
                          <v-icon>mdi-check-circle-outline</v-icon>
                        </div>
                        <v-card flat tile transparent class="mr-5 font-weight-medium">
                          <p class="mb-0">{{ item.change }}</p>
                          <p class="text-body-2 gray--text mb-0" v-if="item.updatedBy">{{ item.updatedBy.firstnameEn }} {{ item.updatedBy.lastnameEn }}</p>
                        </v-card>
                      </div>
                      <div class="text-body-2 gray--text">
                        {{
                          moment(new Date(parseInt(item.updatedAt)))
                            .tz(TIME_ZONE)
                            .format("hh:mm A, DD MMM YYYY")
                        }}
                      </div>
                    </v-sheet>
                    <div class="gray mt-4 mb-4" style="height:0.3px" v-if="order.changeHistory.length < 4"></div>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>

    <v-expansion-panels v-else flat>
      <v-expansion-panel flat class=" mb-5   lighten-2  white ">
        <v-skeleton-loader class="mx-auto" elevation="12" max-width="1200" type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { printA4Orders } from "../methods/printA4Orders";
import { printPosOrders } from "../methods/printPosOrders";
import Icon from "../components/Icon.vue";
import AssignDriver from "../components/AssignDriver.vue";
import CancelOrder from "../components/CancelOrder.vue";
import { TIME_ZONE } from "../../utils/utils";
import moment from "moment";
export default {
  components: { AssignDriver, Icon, CancelOrder },
  props: {
    orderInfo: {
      required: true,
    },
    tab: Number,
  },

  data() {
    return {
      btnloading: false,
      paperType: "A4",
      loading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      order: null,
      products: [],
      headers: [
        { text: "#", sortable: false, value: "slNo", width: 100 },
        { text: "Product Name", value: "productName", width: 200 },
        { text: "Quantity", value: "quantity", width: 100 },
        { text: "Price", value: "price", width: 120 },
      ],
      TIME_ZONE,
      moment,
      imageUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {
    itemsWithSno() {
      return this.order.products.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  created() {
    this.orderDetails();
  },
  methods: {
    getTotalPrice() {
      let total = this.order.netPrice ? this.order.netPrice : 0;
      //  let delivery = this.order.deliveryCharge ? this.order.deliveryCharge : 0;
      let netPrice = total;
      return netPrice.toFixed(3);
    },
    onPrintClick() {
      debugger;
      if (this.paperType == "A4") {
        printA4Orders([this.order]);
      } else if (this.paperType == "Thermal") {
        printPosOrders([this.order]);
      }
    },

    orderDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/orderDetails.graphql"),
          variables: {
            id: this.orderInfo._id,
          },
        })
        .refetch({
          id: this.orderInfo._id,
        })
        .then(data => {
          this.loading = false;
          if (data.data.orderDetails) {
            this.order = data.data.orderDetails;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    close() {
      this.show_dialog_box = false;
    },
  },
};
</script>
