<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">

        <v-btn v-on="on" color="error" outlined text>Payment</v-btn>
      </template>
      <v-card class="pa-3 pop_bg">
        <v-card-title>
          <span class="text-h6 black--text"></span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="subtitle-1 mb-0 mt-6">
          <v-layout>
            <v-flex class="black--text" v-if="item.paymentUrl">
              Payment Url:
              <strong class="ml-3">
                <a :href="item.paymentUrl">
                  {{ item.paymentUrl }}
                </a></strong>
            </v-flex>
            <v-flex v-else class="black--text text-body-1">
              Generated through payment gateway.
            </v-flex>
          </v-layout>
          <v-layout justify-end class="mt-8">
            <v-btn color="secondary" class="me-5 text-capitalize white--text" @click="checkPaymentStatus()"
              :loading="btnPaymentStatusLoading">
              <v-icon>mdi-refresh</v-icon> Payment Status
            </v-btn>
          </v-layout>
        </v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center"> </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      valid: true,
      btnPaymentStatusLoading: false,
      btnRegenerateLoading: false,
      isPermanentlyDelete: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,

      snackbar: false,
      snackbarColor: "",
      text: ""
    };
  },
  methods: {
    checkPaymentStatus() {
      // debugger;
      this.btnPaymentStatusLoading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/checkOrderPaymentStatus.graphql"),
          variables: {
            orderId: this.item._id
          }
        })
        .then(data => {
          this.btnPaymentStatusLoading = false;
          if (data.data.checkOrderPaymentStatus) {
            this.$root.$emit("getAllOrders");
            this.button_loading = false;
            this.dialog = false;
            this.text = "Shopping payment received.";
            this.snackbarColor = "success";
            this.snackbar = true;
          } else {
            this.text = "No payment received";
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
            this.button_loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.btnPaymentStatusLoading = false;
          this.text = "Something went wrong";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.dialog = false;
          this.button_loading = false;
        });
    },

    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
