var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3 mt-sm-0"},[_c('h3',{staticClass:"black--text text-h5 font-weight-bold ms-2 mb-6"},[_vm._v("Drivers")])]),_c('v-row',{staticClass:"mt-n4 mt-md-0",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"placeholder":"Name..","solo":"","dense":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.runSearch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.search.length > 0)?_c('v-icon',{staticClass:"me-n2",attrs:{"color":"black"},on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v("mdi-close")]):_vm._e(),(_vm.search.length == 0)?_c('v-icon',{staticClass:"me-n2",attrs:{"color":"black"},on:{"click":function($event){return _vm.runSearch()}}},[_vm._v("mdi-magnify")]):_vm._e()]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-start  justify-md-end",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"me-4"},[(_vm.hasRole(_vm.AdminUserPermissions.DRIVER_ADD))?_c('DriverCreateForm'):_vm._e()],1),_c('span',{staticClass:"me-2"},[_c('AuditTrialLog',{attrs:{"auditType":"DRIVER_LOG"}})],1)])],1),_c('div',{},[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.driverList,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'DriverDeliveryReport',
            params: {
              driverId: item._id,
              driverName: _vm.getDriverFullName(item)
            }
          }}},[(item.user)?_c('v-row',[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()],1)]}},{key:"item.isActive",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isActive),expression:"item.isActive"}],attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.isActive),expression:"!item.isActive"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.morningShift",fn:function(ref){
          var item = ref.item;
return [(item.morningShift.zone.length > 0)?_c('div',_vm._l((item.morningShift.zone),function(zone){return _c('span',{key:zone._id},[_vm._v(" "+_vm._s(zone.zoneName)+" ")])}),0):_vm._e(),(item.eveningShift.zone.length > 0)?_c('div',_vm._l((item.eveningShift.zone),function(zone){return _c('span',{key:zone._id},[_vm._v(" "+_vm._s(zone.zoneName)+" ")])}),0):_vm._e()]}},{key:"item.eveningShift",fn:function(ref){
          var item = ref.item;
return [(item.morningShift.zone.length > 0)?_c('div',[_vm._v("Morning")]):_vm._e(),(item.eveningShift.zone.length > 0)?_c('div',[_vm._v("Evening")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.DRIVER_EDIT))?_c('DriverUpdateForm',{staticClass:"ms-2",attrs:{"driverInfo":item}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.DRIVER_DELETE))?_c('DriverDelete',{attrs:{"driver":item,"variables":_vm.variables}}):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }