<template>
  <div>
    <v-layout justify-end>
      <usage-report
        :fromDate="fromDate"
        :toDate="toDate"
        :usageList="usageList"
        :headers="headers"
      />
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-1 ms-2"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template #[`item.slNo`]="{ item }">{{
        itemsWithSno.indexOf(item) + (options.page - 1) * options.itemsPerPage + 1
      }}</template>
      <template #[`item.nameEn`]="{ item }">{{ item.nameEn }}</template>
      <template #[`item.nameAr`]="{ item }">{{ item.nameAr }}</template>
      <template #[`item.id`]="{ item }">{{ item.itemId }}</template>
      <template #[`item.brandName`]="{ item }">{{ item.brand }}</template>
      <template #[`item.unit`]="{ item }">{{ getUnitName(item.quantityUnit) }} </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import UsageReport from "./export/UsageReport.vue";
export default {
  components: {
    "usage-report": UsageReport,
  },
  props: {
    month: {
      require: true,
    },
    search: {
      require: true,
    },
  },
  created() {
    this.getAllUnits();
  },

  watch: {
    options: {
      handler() {
        let month = moment().set({
          year: this.month.split("-")[0],
          month: this.month.split("-")[1],
          day: 2,
        });
        let fromDate = month.startOf("month").format("YYYY-MM-DD");
        let toDate = month.endOf("month").format("YYYY-MM-DD");
        // let searchKey = this.search;
        this.$apollo.queries.monthlyUtilization.refetch({
          limit: this.options.itemsPerPage,
          skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
          fromDate: fromDate,
          toDate: toDate,
          searchKey: this.search,
        });
      },
      deep: true,
    },
    month: {
      handler() {
        let month = moment().set({
          year: this.month.split("-")[0],
          month: this.month.split("-")[1],
          day: 2,
        });
        let fromDate = month.startOf("month").format("YYYY-MM-DD");
        let toDate = month.endOf("month").format("YYYY-MM-DD");
        // let searchKey = this.search;
        this.$apollo.queries.monthlyUtilization.refetch({
          limit: this.variables.limit,
          skip: 0,
          fromDate: fromDate,
          toDate: toDate,
          searchKey: this.search,
        });
      },
      deep: true,
    },
    search: {
      handler() {
        let month = moment().set({
          year: this.month.split("-")[0],
          month: this.month.split("-")[1],
          day: 2,
        });
        let fromDate = month.startOf("month").format("YYYY-MM-DD");
        let toDate = month.endOf("month").format("YYYY-MM-DD");

        this.$apollo.queries.monthlyUtilization.refetch({
          limit: this.variables.limit,
          skip: 0,
          searchKey: this.search,
          fromDate: fromDate,
          toDate: toDate,
        });
      },
    },
  },
  data() {
    return {
      loading: false,
      totalCount: 0,
      fromDate: "",
      toDate: "",
      variables: {
        limit: 25,
        skip: 0,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      headers: [
        { text: "#", value: "slNo", width: 100, sortable: false },
        { text: "Item ID", value: "id", width: 100, sortable: false },
        { text: "Item Name (English)", value: "nameEn", sortable: false },
        { text: "Item Name (Arabic)", value: "nameAr", sortable: false },
        { text: "Brand Name", value: "brandName", sortable: false },
        { text: "Unit", value: "unit", sortable: false },
        { text: "Monthly Outward", value: "outwardTotal", sortable: false },
      ],
      usageList: [],
      unitList: [],
    };
  },
  apollo: {
    monthlyUtilization() {
      // debugger
      let month = moment().set({
        year: this.month.split("-")[0],
        month: this.month.split("-")[1],
        day: 2,
      });
      let fromDate = month.startOf("month").format("YYYY-MM-DD");
      let toDate = month.endOf("month").format("YYYY-MM-DD");
      // let searchKey = this.search;
      // console.log("searchKey",searchKey)
      console.log("searchKey", this.search);
      return {
        query: require("../api/monthlyUtilization.graphql"),
        variables: {
          limit: this.variables.limit,
          skip: this.variables.skip,
          fromDate: fromDate,
          toDate: toDate,
          searchKey: this.search,
        },
        result({ data, loading }) {
          console.log("data");
          console.log(data);
          this.loading = loading;
          this.usageList = data.monthlyUtilization.ingredients;
          this.totalCount = data.monthlyUtilization.totalCount;
        },
      };
    },
  },
  methods: {
    getAllUnits() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllUnits.graphql"),
          variables: this.variables,
        })
        .refetch({})
        .then((data) => {
          if (data.data) {
            this.unitList = data.data.getAllUnits.units;
            console.log("UnitList ", this.unitList);
          }
        });
    },
    getUnitName(id) {
      if (id) {
        let data = this.unitList.find((x) => x._id == id);
        if (data) {
          return data.name;
        }
      }
      return "--";
    },
  },
  computed: {
    itemsWithSno() {
      if (this.usageList)
        return this.usageList.map((d, index) => ({ ...d, slNo: index + 1 }));
      else return new Array();
    },
  },
};
</script>
