<template>
  <div :key="categoryInfo._id">

    <v-card class="mt-4">

      <v-card-title class="justify-center">
        <h5 class=" ">Custom Nutrients</h5>
      </v-card-title>
      <v-layout class="ma-2" wrap v-for="(nutrients, index) in customNutrients.filter(
        x => x.menuCategory == categoryInfo._id
      )" :key="index">
        <v-flex class="d-flex" xs12 sm6>
          <v-flex xs6 sm5 class="mx-3">
            <span class="pb-5 pl-3 ">Protein</span>
            <v-text-field label="Protein" solo type="number" min="0" dense v-model="nutrients.protein"
              :rules="[requiredValidator('protein'), positive('protein')]"></v-text-field>
          </v-flex>
          <v-flex xs3 sm3 class="mt-1 ">
            <v-btn class="white--text
                     text-capitalize 
                      ms-sm-5
                      mt-5" @click="applyToAllNutrients(nutrients.protein, 'protein')" color="primary">Apply to All
            </v-btn>
          </v-flex>
        </v-flex>
        <v-flex class="d-flex" xs12 sm6>
          <v-flex xs6 sm5 class="mx-3">
            <span class="pb-5 pl-3 ">Fat</span>
            <v-text-field label="Fat" solo type="number" min="0" dense v-model="nutrients.fat"
              :rules="[requiredValidator('fat'), positive('fat')]"></v-text-field>
          </v-flex>
          <v-flex xs3 sm3 class="mt-1">
            <v-btn class="white--text
                     text-capitalize 
                      ms-sm-5
                      mt-5" @click="applyToAllNutrients(nutrients.fat, 'fat')" color="primary">Apply to All</v-btn>
          </v-flex>
        </v-flex>
        <v-flex class="d-flex" xs12 sm6>
          <v-flex xs6 sm5 class="mx-3">
            <span class="pb-5 pl-3 ">Carbs</span>
            <v-text-field label="Carbs" solo type="number" min="0" dense v-model="nutrients.carbs"
              :rules="[requiredValidator('carbs'), positive('carbs')]"></v-text-field>
          </v-flex>
          <v-flex xs3 sm3 class="mt-1">
            <v-btn class="white--text
                     text-capitalize 
                      ms-sm-5
                      mt-5" @click="applyToAllNutrients(nutrients.carbs, 'carbs')" color="primary">Apply to All</v-btn>
          </v-flex>
        </v-flex>
        <v-flex class="d-flex" xs12 sm6>
          <v-flex xs6 sm5 class="mx-3">
            <span class="pb-5 pl-3 ">Calories</span>
            <v-text-field label="Calories" solo type="number" min="0" dense v-model="nutrients.calories"
              :rules="[requiredValidator('calories'), positive('calories')]"></v-text-field>
          </v-flex>
          <v-flex xs3 sm3 class="mt-1">
            <v-btn class="white--text
                     text-capitalize 
                      ms-sm-5
                      mt-5" @click="applyToAllNutrients(nutrients.calories, 'calories')" color="primary">Apply to All
            </v-btn>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-card>
    <h4 class="mt-8 mb-4">Meals</h4>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <template v-for="(n, index) in categoryInfo.packageMenuWeek">
          <v-stepper-step :key="`${index}-step`" :complete="e1 > index" :step="index" editable>
            Week {{ index }}
          </v-stepper-step>

          <v-divider v-if="index !== categoryInfo.packageMenuWeek.length" :key="index"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="(n, index) in categoryInfo.packageMenuWeek" :key="`${n}-content`" :step="index">

          <v-layout v-for="item in n.categoryInfo.daysItem" :key="item.weekDay" wrap>


            <v-flex xs12 md2>
              <h5 class="font-weight-medium">{{ item.weekDay }}</h5>
            </v-flex>
            <v-flex xs12 md4 v-if="menuItemSearchList.length">
              <v-autocomplete :items="getWeekData(item.weekDay)" item-text="nameEn" item-value="_id" dense solo multiple
                v-model="item.menuItem" chips attach small-chips :rules="[requiredValidatorSelect('menu')]"
                label="Select Menu" deletable-chips :loading="menuItemsLoading" return-object>
                <template slot="no-data">
                  <v-flex xs12 md12 class="mt-2 mb-2">
                    <h5 class="font-weight-medium ml-4">No meals found.</h5>
                  </v-flex>
                </template>
                <template slot="item" slot-scope="data">{{ data.item.nameEn }}, {{ data.item.nameAr }}</template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 md4 v-else>
              <v-autocomplete dense solo multiple chips small-chips attach :rules="[requiredValidatorSelect('menu')]"
                label="Select Menu" deletable-chips :loading="menuItemsLoading">
                <template slot="no-data">
                  <v-flex xs12 md12 class="mt-2 mb-2">
                    <h5 class="font-weight-medium ml-4">No meals found.</h5>
                  </v-flex>
                </template>
                <template slot="item" slot-scope="data">{{ data.item.nameEn }}, {{ data.item.nameAr }}</template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs12 md4 class="ms-md-5" v-if="item.menuItem">
              <div v-if="item.menuItem.length">
                <v-autocomplete :items="item.menuItem" item-text="nameEn" item-value="_id" dense solo multiple
                  v-model="item.defaultMenu" chips attach small-chips :rules="[requiredValidatorSelect('default menu')]"
                  label="Default Menu" deletable-chips>
                  <template slot="no-data">
                    <v-flex xs12 md12 class="mt-2 mb-2">
                      <h5 class="font-weight-medium ml-4">No meals found.</h5>
                    </v-flex>
                  </template>
                  <template slot="item" slot-scope="data">{{ data.item.nameEn }}, {{ data.item.nameAr }}</template>
                </v-autocomplete>
              </div>
            </v-flex>
          </v-layout>
          <v-layout justify-space-between v-if="noOfPackageWeek > 1">
            <v-btn color="primary" @click="beforeStep(index)" :disabled="index == 0 ? true : false">
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="nextStep(index)" :disabled="index == (noOfPackageWeek - 1) ? true : false">
              Continue
            </v-btn>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackColor">{{
    snackText
    }}</v-snackbar>
  </div>
</template>

<script>
import {
  requiredValidatorSelect,
  requiredValidator,
  positive
} from "../../core/methods/validations";
export default {
  props: {
    category: {
      require: true,
      type: Object
    },
    defaultMenu: {
      require: false
    },
    packageMenu: {
      require: false
    },
    dietPlan: {
      require: false
    },
    customNutrient: {
      require: false
    },
    noOfPackageWeek: {
      require: true
    },
    // packageMenuWeek: {
    //   require: true
    // }
  },
  data() {
    return {
      e1: 0,
      snackbar: false,

      snackColor: "",
      snackText: "",
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      tempMenus: [],
      tempItems: [],
      daysItem: [],
      categoryInfo: {},
      menuItemsLoading: false,
      menuItemSearchList: [],
      btnloading: false,
      requiredValidatorSelect,
      requiredValidator,
      positive,
      result: "",
      customNutrients: []
    };
  },

  watch: {
    categoryInfo: {
      handler() {

        this.$emit("updateCategory", this.categoryInfo);
        // this.categoryInfo.daysItem.forEach(item => {
        //   let allDefaultMenu = new Array();
        //   if (item.defaultMenu) {
        //     let isContainInMenu = false;
        //     item.defaultMenu.forEach(defaultMenu => {
        //       let menuItem = item.menuItem.find(x => x._id == defaultMenu);
        //       if (menuItem) {
        //         isContainInMenu = true;
        //       }
        //       if (isContainInMenu) {
        //         allDefaultMenu.push(defaultMenu);
        //       }
        //     });
        //     item.defaultMenu = allDefaultMenu;
        //   }
        // });
        console.log("id");
      },
      deep: true
    },
    // packageMenuWeek: {
    //   handler() {
    //     console.log(this.packageMenuWeek)
    //   //  this.$emit("updateCategoryPackageMenuWeek", this.packageMenuWeek);
    //     // this.categoryInfo.daysItem.forEach(item => {
    //     //   let allDefaultMenu = new Array();
    //     //   if (item.defaultMenu) {
    //     //     let isContainInMenu = false;
    //     //     item.defaultMenu.forEach(defaultMenu => {
    //     //       let menuItem = item.menuItem.find(x => x._id == defaultMenu);
    //     //       if (menuItem) {
    //     //         isContainInMenu = true;
    //     //       }
    //     //       if (isContainInMenu) {
    //     //         allDefaultMenu.push(defaultMenu);
    //     //       }
    //     //     });
    //     //     item.defaultMenu = allDefaultMenu;
    //     //   }
    //     // });
    //     console.log("id");
    //   },
    //   deep: true
    // },
    dietPlan: {
      handler() {
        this.loadMenuItems(true);
      },
      deep: true
    }
  },
  created() {
    if (this.customNutrient) {
      this.customNutrients = this.customNutrient;
    }

    console.log("created");

    this.loadMenuItems(false);
    this.categoryInfo = JSON.parse(JSON.stringify(this.category));

  },
  methods: {
    nextStep(n) {
      this.e1 = n + 1;
    },
    beforeStep(n) {
      this.e1 = n - 1;
    },
    loadMenuItems(isChangeType) {
      this.$apollo
        .query({
          query: require("@/menu-packages/api/getAllMenuItemsBasic.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey: "",
            category: this.category._id
          }
        })
        .then(data => {
          // debugger;

          let menuItems = JSON.parse(
            JSON.stringify(data.data.getAllMenuItems.menuItem)
          );
          if (this.dietPlan) {
            this.menuItemSearchList = menuItems.filter(x =>
              x.dietPlans.some(p => p._id == this.dietPlan)
            );
          }
          debugger
          console.log(this.categoryInfo)
          console.log(this.packageMenu)

          if (this.packageMenu) {
            if (this.categoryInfo.packageMenuWeek) {




              this.categoryInfo.packageMenuWeek.forEach(packageMenuWeek => {

                let thisWeekMenu = this.packageMenu.find(x => x.weekNo == (packageMenuWeek.count + 1));
                console.log(thisWeekMenu)

                packageMenuWeek.categoryInfo.daysItem.forEach(element => {
                  try {
                    let packageMenuItem = thisWeekMenu.daysItem.find(
                      x => x.weekDay == element.weekDay
                    );
                    element.menuItem = packageMenuItem.menuItem;
                    console.log(packageMenuItem)
                  } catch (error) {
                    console.log(error);

                  }

                })
              })
            }
          }

          if (this.defaultMenu) {
            if (this.categoryInfo.packageMenuWeek) {
              this.categoryInfo.packageMenuWeek.forEach(packageMenuWeek => {

                let thisWeekMenu = this.defaultMenu.find(x => x.weekNo == (packageMenuWeek.count + 1));

                packageMenuWeek.categoryInfo.daysItem.forEach(element => {
                  let packageMenuItem = thisWeekMenu.daysItem.find(
                    x => x.weekDay == element.weekDay
                  );
                  element.defaultMenu = packageMenuItem.menuItem;
                  console.log(packageMenuItem)

                })
              })
            }
          }
          // if (this.packageMenu) {

          //   

          //   this.categoryInfo.daysItem.forEach(element => {
          //     let packageMenuItem = this.packageMenu.daysItem.find(
          //       x => x.weekDay == element.weekDay
          //     );

          //     let defaultItem = this.defaultMenu.daysItem.find(
          //       x => x.weekDay == element.weekDay
          //     );

          //     let packageMenuItems = new Array();
          //     packageMenuItem.menuItem.forEach(item => {
          //       let menuItem = this.menuItemSearchList.find(
          //         x => x._id == item._id
          //       );

          //       if (menuItem) {
          //         if (menuItem.active) {
          //           if (menuItem.availableWeekdays.includes(element.weekDay)) {
          //             packageMenuItems.push(menuItem);
          //           }
          //         }
          //       }
          //     });

          //     let menuItems = new Array();

          //     defaultItem.menuItem.forEach(item => {
          //       let menuItem = this.menuItemSearchList.find(
          //         x => x._id == item._id
          //       );
          //       if (menuItem) {
          //         if (menuItem.active) {
          //           if (menuItem.availableWeekdays.includes(element.weekDay)) {
          //             menuItems.push(item._id);
          //           }
          //         }
          //       }
          //     });

          //     element.defaultMenu = menuItems;
          //     element.menuItem = packageMenuItems;
          //   });
          // }




          if (isChangeType) {
            this.categoryInfo.daysItem.forEach(element => {
              element.menuItem = [];
            });
          }

          this.menuItemsLoading = false;
        });
    },
    getWeekData(week) {
      let weekResult = new Array(0);
      this.menuItemSearchList.forEach(element => {
        let weekData = element.availableWeekdays.filter(x => x === week);
        if (weekData) {
          if (weekData.length) weekResult.push(element);
        }
      });
      return weekResult;
    },
    applyToAllNutrients(data, nutrients) {
      if (nutrients == "protein")
        this.customNutrients.forEach(x => (x.protein = data));
      if (nutrients == "fat") this.customNutrients.forEach(x => (x.fat = data));
      if (nutrients == "carbs")
        this.customNutrients.forEach(x => (x.carbs = data));
      if (nutrients == "calories")
        this.customNutrients.forEach(x => (x.calories = data));

      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = `${nutrients} of value ${data} applied to all categories.`;
    }
  }
};
</script>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
