<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <div v-if="totalItemsCount == 0">
      <v-alert dense class="text-center secondary--text" color="primary" dark>
        No Active plans is <strong> expires</strong> in coming
        <strong>3 </strong> days
      </v-alert>
    </div>

    <div v-if="salesOrderList.length !== 0">
      <v-layout wrap>
        <h2 class="primary--text text-h5 font-weight-bold">
          End Date Reminder
        </h2>
      </v-layout>

      <v-spacer></v-spacer>
      <v-layout class="text-end">
        <ExportEndDate :headers="headers" />
      </v-layout>
      
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="salesOrderList"
        class="elevation-1 mt-4"
        :loading="loading"
      >
        <template v-slot:[`item.date`]="{ item }">{{
          new Date(parseInt(item.created_At)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.startDate`]="{ item }">{{
          new Date(parseInt(item.start_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.endDate`]="{ item }">{{
          new Date(parseInt(item.end_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.plan`]="{ item }">{{
          item.package.titleEn
        }}</template>
        <template v-slot:[`item.package_price`]="{ item }">{{
          numberWithCommas(item.package_price)
        }}</template>
        <template v-slot:[`item.discount`]="{ item }">{{
          numberWithCommas(item.discount)
        }}</template>
        <template v-slot:[`item.net_price`]="{ item }"
          >{{ numberWithCommas(item.net_price) }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <PriceSplitup :subscribedPackage="item" />
        </template>

        <template v-slot:[`item.customer`]="{ item }">
          <router-link
            v-if="!item.is_deleted"
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item.customer },
              query: { customerId: item.customer }
            }"
            class="mx-auto"
          >
            {{ toTitleCase(item.user.firstnameEn) }}
            {{ toTitleCase(item.user.lastnameEn) }}
          </router-link>

          <span v-else>
            {{ toTitleCase(item.customer.firstnameEn) }}
            {{ toTitleCase(item.customer.lastnameEn) }}
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import ExportEndDate from "../components/ExportEndDate.vue";
export default {
  components: { ExportEndDate },

  data: () => ({
    loading: true,
    headers: [
      {
        text: "Order ID",
        value: "order_number",
        width: 120
      },
      { text: "Date", value: "date", width: 120, sortable: false },
      { text: "Customer Name", value: "customer", width: 180, sortable: false },

      { text: "Start Date", value: "startDate", width: 120, sortable: false },
      { text: "End Date", value: "endDate", width: 120, sortable: false },
      { text: "Plan Name", value: "plan", width: 150, sortable: false },
      // {
      //   text: "Days",
      //   value: "availableNumOfDays",
      //   width: 75,
      //   sortable: false
      // },
      // {
      //   text: "Plan Status",
      //   value: "paymentStatus",
      //   width: 110,
      //   sortable: false
      // },
      {
        text: "Package Price",
        value: "package_price",
        width: 115,
        sortable: false
      },
      { text: "Discount", value: "discount", width: 100, sortable: false },
      // { text: "Refund", value: "refund", width: 100, sortable: false },
      // { text: "Payment Status", value: "", width: 120, sortable: false },
      { text: "Total", value: "net_price", width: 100, sortable: false }
    ],
    salesOrderList: [],
    menuList: [],
    totalItemsCount: 1
  }),
  apollo: {
    subscribePackagesEndDateReminder() {
      this.loading = true;
      return {
        query: require("../api/subscribedPackageEndDateReminder.graphql"),

        result({ data, loading }) {
          this.loading = loading;
          console.log(data.subscribePackagesEndDateReminder);
          if (data.subscribePackagesEndDateReminder) {
            this.totalItemsCount =
              data.subscribePackagesEndDateReminder.total_count;
            this.salesOrderList =
              data.subscribePackagesEndDateReminder.sales_orders;
          }
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.END_DATE_REMINDER_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  methods: {
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    }
  }
};
</script>
