<template>
  <div>
    <v-row justify="end" v-show="!fromCustomer">
      <div class="d-flex px-4 my-2 my-sm-0">
        <ExportOrders :headers="filteredHeaders" :zone="zone" :deliveryStatus="deliveryStatus" :paymentStatus="paymentStatus" :fromDate="deliveryDate" :toDate="orderDate" />
      </div>
    </v-row>
    <v-row class="justify-space-between"> </v-row>

    <v-col cols="6" sm="6" md="3" v-show="!fromCustomer">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-col>

    <v-spacer></v-spacer>
    <v-layout row class="mt-2"> </v-layout>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="allOrders"
        :search="search"
        :loading="loading"
        mobile-breakpoint="0"
        dense
        class="row-pointer"
        :options.sync="options"
        :server-items-length="totalOrders"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
      >
        <template v-slot:[`item.checkbox`]="{ item }">
          <v-checkbox v-model="item.isChecked" @change="onOrderSelect"></v-checkbox>
        </template>
        <template v-slot:[`item.orderId`]="{ item }">
          <ViewOrderDetails :orderInfo="item"></ViewOrderDetails>
        </template>

        <template v-slot:[`item.orderNumber`]="{ item }">
          {{ item.orderNumber }}
        </template>

        <template v-slot:[`item.customer`]="{ item }">
          <router-link
            v-if="!item.is_deleted"
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item.customer._id },
              query: { customerId: item.customer._id },
            }"
            class="mx-auto"
          >
            {{ toTitleCase(item.customer.user.firstnameEn) }}
            {{ toTitleCase(item.customer.user.lastnameEn) }}
            <!-- <div class="mx-1" /> -->
          </router-link>

          <span v-else>
            <!-- <div class="mx-1" /> -->
            {{ toTitleCase(item.customer.user.firstnameEn) }}
            {{ toTitleCase(item.customer.user.lastnameEn) }}
          </span>
        </template>

        <template v-slot:[`item.phone`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0" v-if="item.customer">
            {{ item.customer.user.phone }}
          </h4>
        </template>

        <template v-slot:[`item.zone`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0" v-if="item.zone">
            {{ item.zone ? item.zone.zoneName : "-" }}
          </h4>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0">
            {{ numberWithCommas(item.total) }}
          </h4>
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0">
            {{ numberWithCommas(item.discount) }}
          </h4>
        </template>
        <template v-slot:[`item.netPrice`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0">
            {{ numberWithCommas(item.netPrice) }}
          </h4>
        </template>

        <template v-slot:[`item.paymentStatus`]="{ item }">
          <h4
            class="font-weight-medium my-0 py-0"
            v-bind:class="{
              'red--text': item.paymentStatus == 'PENDING',
            }"
          >
            <strong> {{ item.paymentStatus }}</strong>
          </h4>
        </template>

        <template v-slot:[`item.deliveryStatus`]="{ item }">
          <h4
            class="font-weight-medium my-0 py-0"
            v-bind:class="{
              'red--text': item.paymentStatus == 'SUCCESS' && item.deliveryStatus == 'PENDING',
            }"
          >
            <strong> {{ item.deliveryStatus }}</strong>
          </h4>
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0" v-if="item.createdBy">{{ item.createdBy.firstnameEn }} {{ item.createdBy.lastnameEn }}</h4>
        </template>
        <template v-slot:[`item.orderDate`]="{ item }">
          <h4 class="font-weight-medium my-0 py-0 gray2--text">
            {{ moment(new Date(parseInt(item.createdAt))).format("DD MMM YYYY") }}
          </h4>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <CheckPaymentStatus v-if="item.paymentStatus == 'PENDING'" :item="item" />
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { printA4Orders } from "../methods/printA4Orders";
import { printPosOrders } from "../methods/printPosOrders";
import ExportOrders from "../components/ExportOrders.vue";
import ViewOrderDetails from "../components/ViewOrderDetails.vue";

import CheckPaymentStatus from "../components/CheckPaymentStatus.vue";
// import Shopping from "../components/Shopping.vue";

import moment from "moment";
import { format, parseISO } from "date-fns";

export default {
  components: {
    ExportOrders,
    ViewOrderDetails,
    CheckPaymentStatus,
  },
  props: {
    fromCustomer: {
      required: false,
    },
    customer: {
      required: false,
    },
    filter: {
      required: false,
    },
  },

  mounted() {
    this.$root.$on("getAllOrders", () => {
      this.getAllOrders();
    });
  },
  watch: {
    filter: {
      handler() {
        this.getAllOrders();
      },
      deep: true,
    },
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : this.totalOrders),
          (this.variables.skip = this.options.page * this.options.itemsPerPage - this.options.itemsPerPage);
        this.getAllOrders();
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      moment: moment,
      regions: [],
      zones: [],
      zoneLoading: true,
      region: "",
      zone: "",
      deliveryStatus: "",
      paymentStatus: "SUCCESS",
      //
      menuDeliveryDate: false,
      menuOrderDate: false,
      search: "",
      searchKey: "",
      tabs: "",
      headers: [
        { text: "", value: "checkbox", sortable: false, width: 50 },

        {
          text: "Order Id",
          align: "left",
          value: "orderId",
          sortable: false,
          width: 70,
        },
        {
          text: "Delivery Status",
          align: "center",
          value: "deliveryStatus",
          sortable: false,
          width: 60,
        },
        {
          text: "Payment Status",
          align: "center",
          value: "paymentStatus",
          sortable: false,
          width: 60,
        },
        {
          text: "Customer",
          align: "center",
          width: 180,
          value: "customer",
          sortable: false,
        },
        {
          text: "Phone",
          align: "center",
          width: 100,
          value: "phone",
          sortable: false,
        },
        {
          text: "Total",
          align: "center",
          width: 120,
          value: "total",
          sortable: false,
        },
        {
          text: "Discount",
          align: "center",
          width: 100,
          value: "discount",
          sortable: false,
        },
        {
          text: "Net Price",
          align: "center",
          width: 120,
          value: "netPrice",
          sortable: false,
        },
        {
          text: "Zone",
          align: "center",
          value: "zone",
          sortable: false,
          width: 120,
        },
        {
          text: "Order Date",
          align: "center",
          width: 120,
          value: "orderDate",
          sortable: false,
        },
        {
          text: "Created By",
          align: "center",
          width: 150,
          value: "createdBy",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          width: 80,
          value: "action",
          sortable: false,
        },
      ],
      statusTypes: ["PENDING", "DISPATCHED", "DELIVERED", "CANCELLED"],
      variables: {
        limit: 10,
        skip: 0,
        regionFilter: "",
        zoneFilter: "",
        status: null,
        searchKey: "",
        deliveryDate: null,
        orderDate: null,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      currentOrder: {},
      allOrders: [],
      totalOrders: 10,
      currentStatus: null,
      currentOrderID: 0,
      prevStatus: [],
      deliveryDate: format(
        parseISO(
          moment()
            .subtract(7, "days")
            .toDate()
            .toISOString()
        ),
        "yyyy-MM-dd"
      ),
      orderDate: format(
        parseISO(
          moment()
            .toDate()
            .toISOString()
        ),
        "yyyy-MM-dd"
      ),
      fromDate: "",
      toDate: "",

      showMarkAsDespatch: false,
      showMarkAsDeliverd: false,
      showEditOrder: false,
      showProductReturn: false,
      orderEditCustomerId: "",
      productReturnCustomerId: "",
      isEdit: false,
      orderToEdit: null,
      selectedIds: [],
      isAnyOrderSelected: false,
      status: "",
      deliveryTimeDialog: null,
      deliveryText: null,
      deliveryTimeTo: null,
      deliveryTimeRequired: "",
      zoneList: [],
    };
  },

  computed: {
    deliveryDateFormatted() {
      return this.deliveryDate ? moment(this.deliveryDate).format("DD/MM/YYYY") : "";
    },
    orderDateFormatted() {
      return this.orderDate ? moment(this.orderDate).format("DD/MM/YYYY") : "";
    },

    toDateFormatted() {
      return this.toDate ? moment(this.toDate).format("DD/MM/YYYY") : "";
    },
    fromDateFormatted() {
      return this.fromDate ? moment(this.fromDate).format("DD/MM/YYYY") : "";
    },
    filteredHeaders() {
      return this.headers.filter(header => {
        return header.value !== "checkbox" && header.value !== "action";
      });
    },
  },
  created() {
    this.getAllOrders();
    this.getAllZones();
  },
  methods: {
    onShopping() {
      this.$router.push({
        name: "CustomerShopping",
        query: { customerId: this.$route.query.customerId },
      });
    },
    getAllZones() {
      this.zoneLoading = true;
      this.$apollo
        .query({
          query: require("@/customer/api/getAllZones.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey: "",
          },
        })
        .then(data => {
          this.zoneLoading = false;
          if (data.data.getAllZones) {
            this.zoneList = data.data.getAllZones.zones;
          }
        })
        .catch(error => {
          this.zoneLoading = false;
          console.log(error);
        });
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },

    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    closeDeliveryTime() {
      this.deliveryTimeDialog = false;
    },
    setDeliveryTime() {
      if (this.deliveryText) {
        this.updateConfirmed("DISPATCHED");
        this.deliveryTimeDialog = false;
      } else {
        this.deliveryTimeRequired = "Please enter the text";
      }
    },

    padLeadingZeros(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    printA4Orders() {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      printA4Orders(selectedOrders);
    },
    printPosOrders() {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      printPosOrders(selectedOrders);
    },

    addProduct(data) {
      this.orderEditCustomerId = data.customerId;
      this.isEdit = false;
      this.orderToEdit = {
        shippingDetails: data.address,
      };

      this.showSideDrawer("EditUserProduct");
    },
    getAllOrders() {
      this.loading = true;
      let pageInput = {
        skip: 0,
        limit: 25,
      };

      let filter = {
        paymentStatus: this.filter.paymentStatus,
        deliveryStatus: this.filter.deliveryStatus,
        zone: this.filter.zone,
        fromDate: this.filter.deliveryDate,
        toDate: this.filter.orderDate,
        customer: this.fromCustomer ? this.customer._id : "",
        tab: "PAST",
      };
      this.$apollo
        .watchQuery({
          query: require("../api/listOrders.graphql"),
          variables: {
            input: pageInput,
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .refetch({
          variables: {
            input: {
              limit: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
              skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
            },
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .then(data => {
          this.isAnyOrderSelected = false; // posOrder button disabling
          this.loading = false;
          this.allOrders = [];
          this.totalOrders = 0;
          if (data.data.listOrders) {
            let allOrders = data.data.listOrders.orders;
            allOrders.forEach(element => {
              element.isChecked = false;
            });
            this.allOrders = allOrders;
            this.totalOrders = data.data.listOrders.totalCount;
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },

    updateConfirmed(val) {
      if (val) this.updateOrderStatuses(this.status);
    },
    onDelivered() {
      this.status = "DELIVERED";
      this.$refs.status_modal.show();
    },
    onDispatched() {
      this.status = "DISPATCHED";
      this.deliveryTimeDialog = true;
    },
    updateOrderStatuses(status) {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      let selectedIds = [];
      selectedOrders.forEach(order => {
        selectedIds.push(order._id);
      });
      this.$apollo
        .mutate({
          mutation: require("../api/updateOrderStatuses.graphql"),
          variables: {
            ids: selectedIds,
            status: status,
            deliveryText: this.deliveryText,
          },
        })
        .then(data => {
          if (data.data.updateOrderStatuses === true) {
            debugger;
            this.getAllOrders();
          }
        });
    },
    onOrderSelect() {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      if (selectedOrders.length) {
        this.isAnyOrderSelected = true;
      } else {
        this.isAnyOrderSelected = false;
      }

      let dispatchedOrders = selectedOrders.filter(x => x.deliveryStatus == "DISPATCHED");

      let deliveredOrders = selectedOrders.filter(x => x.deliveryStatus == "DELIVERED");

      let cancelledOrders = selectedOrders.filter(x => x.deliveryStatus == "CANCELLED");

      let pendingOrders = selectedOrders.filter(x => x.deliveryStatus == "PENDING");

      this.showMarkAsDespatch = true;
      this.showMarkAsDeliverd = true;
      this.showEditOrder = false;
      this.showProductReturn = false;
      if (selectedOrders == 0) {
        this.showMarkAsDespatch = false;
        this.showMarkAsDeliverd = false;
      }

      if (dispatchedOrders.length > 0) {
        this.showMarkAsDespatch = false;
      }

      if (deliveredOrders.length > 0) {
        this.showMarkAsDespatch = false;
        this.showMarkAsDeliverd = false;
      }

      if (cancelledOrders.length > 0) {
        this.showMarkAsDespatch = false;
        this.showMarkAsDeliverd = false;
      }
      if (selectedOrders.length == 1 && pendingOrders.length == 1) {
        this.showEditOrder = true;
      } else if (selectedOrders.length == 1 && dispatchedOrders.length == 1) {
        this.showEditOrder = true;
      }
      if (selectedOrders.length == 1 && deliveredOrders.length == 1) {
        this.showProductReturn = true;
      }
    },
    editOrder() {
      let selectedOrder = this.allOrders.filter(x => x.isChecked)[0];
      this.orderEditCustomerId = selectedOrder.customer._id;
      this.isEdit = true;
      this.orderToEdit = selectedOrder;
      this.showSideDrawer("EditUserProduct");
    },
    productReturn() {
      let selectedOrder = this.allOrders.filter(x => x.isChecked)[0];
      this.productReturnCustomerId = selectedOrder.customer._id;
      this.orderToEdit = selectedOrder;
      this.showSideDrawer("ProductReturnForm");
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
