<template>
  <div>

    <v-card class="pa-3" color="pop_bg">
      <v-card-title>
     
        <v-checkbox class="text-h6 black--text" :value="true" label="Enable Discount"
          v-model="itemDiscount.enableDiscount"></v-checkbox>

        <v-spacer></v-spacer>

      </v-card-title>
      <v-divider></v-divider>

      <v-card-text v-if="itemDiscount.enableDiscount">
        <v-container>

          <v-layout wrap class="mt-4">
            <v-flex xs12 sm4 md2 class="mt-n2">
              <h4 class="text-body-2 black--text">Discount</h4>

            </v-flex>
            <v-flex xs12 sm8 md3 class="mt-sm-n4">
              <v-text-field solo dense v-model="itemDiscount.discount"
                :append-icon="itemDiscount.inPercentage == true ? 'mdi-percent' : ''" type="number" min="0"
                :rules="[requiredValidator('discount')]"></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md2 class="mt-2 mt-md-n2 ms-md-16">
              <h4 class="text-body-2 black--text">Discount Type</h4>
            </v-flex>
            <v-flex xs12 sm5 md3 class="mt-md-n6">
              <v-radio-group v-model="itemDiscount.inPercentage" :mandatory="true" column>
                <v-radio label="Fixed Amount" :value="false"></v-radio>

                <v-radio label="Percentage" :value="true"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout> </v-layout>

          <v-layout wrap>
            <v-flex xs12 sm4 md2 class="mt-1 " v-if="itemDiscount.inPercentage">
              <h4 class="text-body-2 black--text">
                Maximum Discount
              </h4>
            </v-flex>
            <v-flex xs12 sm8 md3 class="mt-1" v-if="itemDiscount.inPercentage">
              <v-text-field solo dense v-model="itemDiscount.maxDiscountAmount" type="number" min="0"
                :rules="[requiredValidator('discount')]"></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout wrap>
            <v-flex xs12 sm4 md2 class="mt-2">
              <h4 class="text-body-2 black--text">Start Date</h4>
            </v-flex>
            <v-flex xs12 sm8 md3>
              <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field solo dense :value="formatStartDate" append-icon="fa-calendar-alt black--text" readonly
                    :rules="[requiredValidator('start date')]" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="secondary" v-model="itemDiscount.startDate" @input="menuStartDate = false"
                  :min="today"></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm4 md2 class="ms-md-16 mt-2">
              <h4 class="text-body-2 black--text">End Date</h4>
            </v-flex>
            <v-flex xs12 sm8 md3>
              <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field solo dense :value="formatEndDate" append-icon="fa-calendar-alt black--text" readonly
                    :rules="[requiredValidator('end date')]" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="secondary" v-model="itemDiscount.endDate" @input="menuEndDate = false"
                  :min="new Date(itemDiscount.startDate) | moment('YYYY-MM-DD')"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <!-- <v-layout wrap v-show="false">
            <v-flex xs12 sm4 md2 class="mt-2">
              <h4 class="text-body-2 black--text">Applicable for</h4>
            </v-flex>


            <v-flex xs12 sm8 md3 class="mt-n3">
              <v-checkbox v-model="applicableFor" @change="changeApplicable()" value="PRODUCT"
                label="Product"></v-checkbox>
            </v-flex>

            <v-flex xs12 sm8 md3 class="mt-n3">
              <v-checkbox v-model="applicableFor" @change="changeApplicable()" value="CATEGORY"
                label="Category"></v-checkbox>
            </v-flex>
          </v-layout>


          <v-layout row wrap v-if="applicableFor == 'PRODUCT'">
            <v-flex xs12 sm8 md6 class="mt-3">
              <v-autocomplete solo dense label="Product" :items="products" item-text="nameEn" return-object
                v-model="chooseProduct" item-value="_id"></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm8 md6 class="mt-3">
              <v-btn text class="error--text text-capitalize font-weight-bold" color="secondary"
                @click="addProduct"><v-icon small class="me-1">mdi-plus</v-icon> </v-btn>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-if="applicableFor == 'CATEGORY'">
            <v-flex xs12 sm8 md6 class="mt-3">
              <v-autocomplete solo dense label="Category" :items="categories" item-text="nameEn" clearable deletable-chips
                small-chips v-model="categoriesSelected" multiple item-value="_id"></v-autocomplete>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-if="applicableFor == 'PRODUCT'">
            <v-flex xs12 sm8 md6 v-for="(item, index) in selectedProducts" :key="item._id">
              {{ index + 1 }} - {{ item.nameEn }}, Price: {{ item.price }}
              <v-btn text class="error--text text-capitalize font-weight-bold" color="secondary"
                @click="removeProduct(item)"><v-icon small class="me-1">mdi-delete</v-icon> </v-btn>
            </v-flex>
          </v-layout> -->

        </v-container>
      </v-card-text>


    </v-card>

  </div>
</template>

<script>
import { requiredValidator, percentageValidator } from "../../core/methods/validations";
import { DATE_FORMAT } from "../../utils/utils";
import moment from "moment";
// import AssignPackages from "./AssignPackages.vue";
export default {
  components: {},
  props: {

    itemDiscount: {
      required: true,
    },
    type: {
      required: true,
    }
  },
  data() {
    return {
      categoriesSelected: [],
      requiredValidator: requiredValidator,
      today: new Date().toISOString().slice(0, 10),
      percentageValidator,
      moment: moment,
      chooseProduct: "",
      applicableFor: "ALL",
      selectedProducts: [],
      dialog: false,
      inPercentage: false,
      discount: "",
      maxDiscountAmount: 0,
      btnloading: false,
      nowDate: new Date(),
      isActive: true,
      titleAr: "",
      titleEn: "",
      snackbar: false,
      text: "",
      valid: true,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      enableDiscount: false,
      menuEndDate: false,
      variables: {
        limit: 25,
        skip: 0,
      },
      snack: false,
      snackColor: "",
      snackText: "",
      enableAddBtn: true,
      products: [],
      categories: [],
    };
  },
  apollo: {

  },
  created() {

  },
  methods: {

  },

  computed: {
    formatStartDate() {
      return this.itemDiscount.startDate ? moment(this.itemDiscount.startDate).format(DATE_FORMAT) : "";
    },
    formatEndDate() {
      return this.itemDiscount.endDate ? moment(this.itemDiscount.endDate).format(DATE_FORMAT) : "";
    },

  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
