<template>
  <v-container>
    <v-img
      :src="require('../../assets/signin-logo.svg')"
      width="140px"
      class="mx-auto mt-10"
      contain
    />
    <v-card
      rounded="lg"
      outlined
      max-height="400px"
      max-width="500px"
      class="mx-auto my-10 pa-10 pa-sm-14"
      color="white"
      elevation="4"
    >
      <v-layout justify-center>
        <h1>SIGN IN</h1>
      </v-layout>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <v-layout class="mt-10">
            <v-flex>
              <ValidationProvider
                v-slot="{ errors }"
                name="Username"
                rules="required"
              >
                <v-text-field
                  v-model="phone"
                  :error-messages="errors"
                  label="Username"
                  required
                  solo
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                rules="required"
              >
                <v-text-field
                  v-model="password"
                  :error-messages="errors"
                  label="Password"
                  solo
                  required
                  @click:append="show1 = !show1"
                  dense
                  :append-icon="
                    show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                  "
                  :type="show1 ? 'text' : 'password'"
                ></v-text-field>
              </ValidationProvider>
            </v-flex>
          </v-layout>
          <v-layout justify-center class="ms-n3">
            <v-btn
              depressed
              color="primary"
              class="white--text ml-3 font-weight-light"
              :min-width="$vuetify.breakpoint.xs ? 150 : 385"
              type="submit"
              :loading="btn_loading"
              >Sign In</v-btn
            >
          </v-layout>
        </form>
      </ValidationObserver>
      <p v-if="errorFromApi" class="red--text text-center mt-6">
        {{ errorFromApi }}
      </p>
    </v-card>
  </v-container>
</template>

<script>
import { required, length } from "vee-validate/dist/rules";
import { userSignIn } from "../methods/signin.js";
import { isDietitian } from "../../user/methods/helper.js";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} cannot be empty"
});

extend("length", {
  ...length,
  message: "{_field_} number should have {length} digits"
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      phone: "",
      password: "",
      btn_loading: false,
      show1: false,
      errorFromApi: null,
      style: {
        borderStyle: { border: "0px solid black !important" }
      }
    };
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate();
      // this.$store.state.signin = true;

      this.btn_loading = true;
      userSignIn(this.phone, this.password).then(data => {
        if (data) {
          // console.log(data);
          if (data === true) {
            this.btn_loading = false;
            if (isDietitian()) {
              this.$router.replace({ path: "appointments" });
            } else {
              this.$router.replace({ path: "dashboard" });
            }
          } else if (data.includes("NOT_FOUND")) {
            this.errorFromApi = this.$t("user.signIn.invalidLogin");
            this.btn_loading = false;
          }
        }
      });
    }
  }
};
</script>

<style>
.inputPhone input[type="number"] {
  -moz-appearance: textfield;
}
.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
