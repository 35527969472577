<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="black--text text-h5 font-weight-bold ms-3">Products Category</h2>
    <!-- Hello {{ products }} -->
    <v-row class="mt-3 mt-sm-0 justify-end">
      <AddCategory v-if="hasRole(AdminUserPermissions.SHOP_PRODUCT_ADD)" />
    </v-row>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-5"
      :items-per-page="25"
      :loading="loading"
    >
      <template v-slot:item.status="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.status" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.status" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col md1 class="mr-n mt-n2 ">
            <UpdateCategory
              v-if="hasRole(AdminUserPermissions.SHOP_PRODUCT_EDIT)"
              :category="item"
            />
          </v-col>
          <v-col md1 class="ml-n4">
            <DeleteCategory
              v-if="hasRole(AdminUserPermissions.SHOP_PRODUCT_DELETE)"
              :category="item"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import DeleteCategory from "../components/DeleteCategory";
import AddCategory from "../components/AddCategory";
import UpdateCategory from "../components/UpdateCategory";
export default {
  components: { DeleteCategory, UpdateCategory, AddCategory },
  props: {
    loading: {
      default: true
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SHOP_PRODUCT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  apollo: {
    getAllProductCategories() {
      return {
        query: require("../api/getAllProductCategories.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.categories = data.getAllProductCategories;
        }
      };
    }
  },
  computed: {
    itemsWithSno() {
      return this.categories.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  },
  data() {
    return {
      variables: {
        limit: 25,
        skip: 0
      },
      options: {
        itemsPerPage: 25,
        page: 1
      },
      categories: [],
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
          width: 80
        },
        {
          text: "Name (English)",
          value: "nameEn",
          width: 250,
          sortable: false
        },
        { text: "Name (Arabic)", value: "nameAr", width: 250, sortable: false },
        // { text: "Answer", value: "answer" },
        { text: "Status", value: "status", sortable: false, width: 80 },
        { text: "Action", value: "actions", sortable: false, width: 80 }
      ],
      snack: false,
      snackColor: "",
      snackText: ""
    };
  },
  methods: {
    deleteItem(choice) {
      // console.log(item);
      const index = this.faqList.findIndex(
        item => item.choiceNameEn === choice.choiceNameEn
      );
      this.faqList.splice(index, 1);
      // console.log(index);
    }
  }
};
</script>
