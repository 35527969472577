<template>
  <v-container class="bg px-0" fluid>
    <v-layout class="mt-6" wrap>
      <v-flex xs10 sm5>
        <v-text-field
          placeholder="Name"
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="mt-n1 me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="mt-n1 me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-flex>

      <v-flex xs12 sm7 class="mt-5 mt-sm-3">
        <v-row class="text-end">
          <Current :headers="headers" :stockList="ingredientList" />
        </v-row>
      </v-flex>
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="ingredientList"
      class="elevation-1 mt-6"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.quantity="{ item }">
        <span v-if="item.stock">
          <span v-if="item.quantityUnit == 'PIECE'">
            {{ parseFloat(item.stock.currentStock).toFixed(0) }}&nbsp;{{
              itemQuantityUnit(item)
            }}
          </span>
          <span v-else>
            {{ parseFloat(item.stock.currentStock / 1000).toFixed(3) }}&nbsp;{{
              itemQuantityUnit(item)
            }}
          </span>
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import Current from "../components/export/Current";
export default {
  components: { Current },
  data() {
    return {
      loading: true,
      ingredientList: [],
      totalItemsCount: 25,
      search: "",
      variables: {
        limit: 25,
        skip: 0,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      headers: [
        { text: "Item ID", value: "itemId", sortable: false, width: 200 },
        { text: "Item Name", value: "nameEn", sortable: false, width: 250 },
        // { text: "Brand Name", value: "brand" },
        { text: "Quantity", value: "quantity", sortable: false, width: 200 },
        // { text: "Total Price", value: "totalPrice" },
      ],
    };
  },
  apollo: {
    getAllCurrentStocks() {
      return {
        query: require("@/stock/api/getAllCurrentStocks.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.ingredientList = data.getAllCurrentStocks.ingredients;
          this.totalItemsCount = data.getAllCurrentStocks.totalCount;
        },
      };
    },
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        this.$apollo.queries.getAllCurrentStocks.refetch({
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage,
        });
      },
      deep: true,
    },
  },
  methods: {
    runSearch() {
      this.options.page = 1;
      this.$apollo.queries.getAllCurrentStocks.refetch({
        limit:
          this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
        skip:this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage,
        searchKey: this.search,
      });
    },
    itemQuantityUnit(item) {
      let unit = "";
      if (item.quantityUnit === "GRAMS") unit = "KG";
      else if (item.quantityUnit === "ML") unit = "L";
      else if (item.quantityUnit === "PIECE") unit = "PCS";
      return unit;
    },
  },
};
</script>