<template>
  <div class="secondary black--text rounded-xl " style="height: 270px">
    <div v-if="!personalInfo">
      <v-flex class="mt-4 ms-2 pa-4">
        <h4 class="black--text text-h6 font-weight-bold">
          Activity and HealthIssues
        </h4>
      </v-flex>
      <div class="px-4">
        <v-row class="mt-n4 ">
          <v-col md="5" class="ms-2">Activity</v-col>
          <v-col md="6" class="font-weight-medium" v-if="customer.activity">
            {{ activity(customer.activity) }}
          </v-col>
        </v-row>
        <v-row class="mt-n4 ">
          <v-col md="5" class="ms-2">Health Issues</v-col>
          <v-col md="6" class="font-weight-medium" v-if="customer.activity">
            {{ activity(customer.healthIssues) }}
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-flex class="mt-4 ms-2 pa-4">
        <h4 class="black--text text-h6 font-weight-bold">Personal Info</h4>
      </v-flex>
      <div class="px-4">
        <v-row class="mt-n4 ">
          <v-col md="5" class="ms-2">Gender</v-col>
          <v-col md="6" class="font-weight-medium" v-if="customer.user.gender">
            {{ toPascalCase(customer.user.gender) }}
          </v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">DOB</v-col>
          <v-col md="6" class="font-weight-medium" v-if="customer.user.dob">
            {{ new Date(parseInt(customer.user.dob)) | moment("MMM DD YYYY") }}
          </v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">Age</v-col>
          <v-col md="6" class="font-weight-medium">{{ age }}</v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">Mobile</v-col>
          <v-col md="6" class="font-weight-medium">{{
            customer.user.phone
          }}</v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">Email</v-col>
          <v-col md="6" class="font-weight-medium">{{
            customer.user.email
          }}</v-col>
        </v-row>
        <v-row class="mt-n6" v-if="customer.height">
          <v-col md="5" class="ms-2">Height</v-col>
          <v-col md="6" class="font-weight-medium">{{ customer.height }}</v-col>
        </v-row>

        <v-row class="mt-n6" v-if="customer.weight">
          <v-col md="5" class="ms-2">Weight</v-col>
          <v-col md="6" class="font-weight-medium">{{ customer.weight }}</v-col>
        </v-row>
        <v-row class="mt-n6" v-if="customer.user.userId">
          <v-col md="5" class="ms-2">Referral Code</v-col>
          <v-col md="6" class="font-weight-medium">
            {{
              parseInt(customer.user.userId)
                .toString(16)
                .toUpperCase()
            }}</v-col
          >
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      required: true
    },
    personalInfo: {
      required: true
    }
  },
  methods: {
    toPascalCase(string) {
      return `${string}`
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(
          new RegExp(/\s+(.)(\w+)/, "g"),
          ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, "g"), "")
        .replace(new RegExp(/\w/), s => s.toUpperCase());
    },
    capitalize(data) {
      return data
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
    },
    activity(items) {
      let activity = items.map(x => this.capitalize(x.name));
      if (activity.length) {
        let result = "";
        for (let [i, x] of activity.entries()) {
          if (i == 0) {
            result = `${x}`;
          } else {
            result = `${result}, ${x}`;
          }
        }
        return result;
      }
    }
  },
  computed: {
    age: {
      get: function() {
        if (this.customer.user.dob) {
          let dateofbirth = new Date(parseInt(this.customer.user.dob));
          var ageDifMs = Date.now() - dateofbirth.getTime();
          var ageDate = new Date(ageDifMs);
          return Math.abs(ageDate.getUTCFullYear() - 1970);
        } else return "";
      },
      set: () => null
    }
  },
};
</script>
