<template>
  <v-container class="mt-n6 pa-0">
    <v-btn
      @click="showDialogBox"
      class="black--text text-capitalize font-weight-bold"
      color="secondary"
      ><v-icon class="me-1" small>mdi-plus</v-icon> Add</v-btn
    >
    <v-dialog v-model="show_dialog_box" max-width="1100px">
      <!-- <template v-slot:activator="{ on }">
    
      </template> -->
      <v-card class="ma-0 py-10  pa-sm-6" color="pop_bg">
        <v-card-text class="">
          <v-row class="pt-sm-6 ">
            <span class="black--text text-h6 text-sm-h5 ms-2 mb-4 "
              >Enter Meal Details</span
            >
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider class="mt-2"></v-divider>
          <v-form
            class="pa-sm-8"
            @submit="createMenuItem()"
            v-model="valid"
            ref="form"
          >
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mb-2 mb-sm-0">Meal Name</h5>
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field
                  label="Meal Name"
                  v-model="nameEn"
                  solo
                  :rules="[requiredValidator('meal name')]"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                <v-text-field
                  v-model="nameAr"
                  label="Meal Name Arabic"
                  :rules="[requiredValidator('meal name arabic')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h4 class="text-body-2 black--text  mb-2 mb-sm-0">
                  Description
                </h4>
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-textarea
                  solo
                  name="input-7-4"
                  label="Description"
                  value=""
                  v-model="descriptionEn"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                <v-textarea
                  solo
                  name="input-7-4"
                  value=""
                  label="Description Arabic"
                  v-model="descriptionAr"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text">Image</h5>
              </v-flex>

              <v-flex xs12 sm7 class="mt-3 mt-sm-0">
                <croppa
                  class="ml-2 mt-2"
                  v-model="myCroppa"
                  :width="170"
                  :height="120"
                  canvas-color="black"
                  :placeholder="'Choose an image'"
                  :placeholder-font-size="16"
                  placeholder-color="yellow"
                  :accept="'image/*'"
                  :file-size-limit="0"
                  :quality="5"
                  :zoom-speed="3"
                  :disabled="false"
                  :disable-drag-and-drop="false"
                  :disable-click-to-choose="false"
                  :disable-drag-to-move="false"
                  :disable-scroll-to-zoom="false"
                  :disable-rotation="false"
                  :prevent-white-space="false"
                  :reverse-scroll-to-zoom="false"
                  :show-remove-button="true"
                  :remove-button-color="'red'"
                  :remove-button-size="10"
                ></croppa>

                <!-- <input
                  type="file"
                  accept="image/*"
                  @change="onFileSelected($event)"
                /> -->
                <v-layout>
                  <span style="color: red">
                    ****Use mouse scroll wheel to crop image****
                  </span>
                </v-layout>
              </v-flex>

              <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
                snackText
              }}</v-snackbar>
            </v-layout>

            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-md-5">Category</h5>
              </v-flex>
              <v-flex xs12 sm9 md10>
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm6
                    md3
                    v-for="(cat, index) in allCategories"
                    :key="index"
                    class="mt-n2 mt-sm-0 text-start"
                  >
                    <v-checkbox
                      :label="cat.nameEn"
                      v-model="category"
                      :value="cat._id"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex sm2 v-for="cat in allCategories" :key="cat._id">
          <v-checkbox
            :label="cat.nameEn"
            v-model="category"
            v-validate="'required'"
            :value="cat._id"
          ></v-checkbox>
        </v-flex>-->
            </v-layout>
            <v-layout wrap class="mt-sm-0">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-2">Meals Tag</h5>
              </v-flex>
              <v-flex sm6 md4>
                <v-select
                  v-model="mealsTag"
                  :items="allmealsTags"
                  item-value="_id"
                  item-text="name"
                  dense
                  solo
                  attach
                  v-on:input="limiter"
                  chips
                  multiple
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-6">Currently Active</h5>
              </v-flex>
              <v-flex xs12 sm6>
                <v-radio-group v-model="active" :mandatory="false" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-6">Diet Plan</h5>
              </v-flex>
              <v-flex xs12 sm9 md10>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="item in allDietPlans"
                    :key="item._id"
                  >
                    <v-checkbox
                      :label="item.nameEn"
                      v-model="dietPlans"
                      :value="item._id"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-5">
              <v-flex xs12 sm3 md2 class="pe-sm-4 pe-md-2">
                <h5 class="text-body-2 black--text">Meal Info(100-100)</h5>
              </v-flex>
              <v-flex xs12 sm9 md10 class="mt-4 mt-sm-0">
                <v-layout wrap>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text">
                    Protien(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3>
                    <v-text-field
                      type="number"
                      @keypress="isNumber"
                      min="0"
                      label="Protein(gm)"
                      v-model="meanInfo.protein"
                      :rules="[requiredValidator('protein')]"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text ms-md-8">
                    Carbs(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3 class="ms-md-10">
                    <v-text-field
                      type="number"
                      min="0"
                      @keypress="isNumber"
                      label="Carbs(gm)"
                      v-model="meanInfo.carbs"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text">
                    Calories(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3>
                    <v-text-field
                      label="Calories(gm)"
                      v-model="meanInfo.calories"
                      min="0"
                      solo
                      dense
                      :rules="[requiredValidator('calories')]"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text ms-md-8">
                    Fat(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3 class="ms-md-10">
                    <v-text-field
                      label="Fat(gm)"
                      v-model="meanInfo.fat"
                      :rules="[requiredValidator('fat')]"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout> </v-flex
            ></v-layout>
            <!-- <v-layout wrap>
        <v-flex xs12 md2>
          <h5 class="font-weight-medium">Kitchen Type</h5>
        </v-flex>
        <v-flex xs8 md4 class="mt-5 mt-md-0">
          <v-select
            :items="kitchenTypesItems"
            v-model="kitchenType"
            :rules="[requiredValidator('kitchen type')]"
            label="Select Kitchen Type"
            dense
            outlined
          ></v-select>
        </v-flex>
      </v-layout>-->
            <v-layout wrap class="mt-8 mt-sm-0">
              <v-flex xs4 sm3 md2>
                <h5 class="text-body-2 black--text">Meal Code</h5>
              </v-flex>
              <v-flex xs8 sm4 md3>
                <v-text-field
                  v-model="mealCode"
                  label="Meal Code"
                  :disabled="true"
                  solo
                  :rules="[requiredValidator('meal code')]"
                  dense
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs8 md3 class="ms-md-10">
          <v-text-field
            label="Price"
            v-model="price"
            outlined
            dense
          ></v-text-field>
        </v-flex>-->
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm3 md2 class="pe-sm-2">
                <h4 class="text-body-2 black--text">Available Week Days</h4>
              </v-flex>
              <v-flex xs12 sm8 md10 class="ps-2 mt-4 mt-sm-0">
                <v-layout wrap>
                  <v-flex xs6 sm6 md3 lg2>
                    <v-checkbox
                      label="All"
                      value="All"
                      @click="toggleDays"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    xs6
                    sm6
                    md3
                    lg2
                    v-for="(day, index) in listOfDays"
                    :key="index"
                  >
                    <v-checkbox
                      :label="day"
                      v-model="availableWeekdays"
                      :value="day"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs6 md1 class="ms-md-8">
            <v-checkbox
              label="Monday"
              v-model="availableWeekdays"
              value="Monday"
            ></v-checkbox>
          </v-flex>
          <v-flex xs6 md1 class="ms-md-8">
            <v-checkbox
              label="Tuesday"
              v-model="availableWeekdays"
              value="Tuesday"
            ></v-checkbox>
          </v-flex>
          <v-flex xs6 md1 class="ms-md-8">
            <v-checkbox
              label="Wednesday"
              v-model="availableWeekdays"
              value="Wednesday"
            ></v-checkbox>
          </v-flex>
          <v-flex xs6 md1 class="ms-md-14">
            <v-checkbox
              label="Thursday"
              v-model="availableWeekdays"
              value="Thursday"
            ></v-checkbox>
          </v-flex>
          <v-flex xs6 md1 class="ms-md-10">
            <v-checkbox
              label="Friday"
              v-model="availableWeekdays"
              value="Friday"
            ></v-checkbox>
          </v-flex>
          <v-flex xs6 md1 class="ms-md-6">
            <v-checkbox
              label="Saturday"
              v-model="availableWeekdays"
              value="Saturday"
            ></v-checkbox>
          </v-flex>-->
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-6">
                  Meal Portioning
                </h5>
              </v-flex>
              <v-flex xs12 sm3>
                <v-switch v-model="mealPortioning" class="mt-5"></v-switch>
              </v-flex>
            </v-layout>
            <v-layout v-if="mealPortioning" wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-4">
                  Meal Portions
                </h5>
              </v-flex>

              <v-flex xs12 sm3>
                <v-select
                  class=" mt-2"
                  placeholder="select meal portions"
                  :rules="[requiredValidator('meal portions')]"
                  solo
                  dense
                  :items="mealPortionList"
                  item-text="name"
                  item-value="_id"
                  multiple
                  v-model="mealPortions"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-4">
                  Show Ingredients In App
                </h5>
              </v-flex>
              <v-flex xs12 sm3>
                <v-switch
                  v-model="showIngredientsInApp"
                  class="mt-3"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-system-bar height="1"></v-system-bar>
            <h3 class="text-body-1 font-weight-bold black--text mt-5">
              Ingredients Required
            </h3>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
              snackText
            }}</v-snackbar>
            <v-form
              v-model="isValidIngredient"
              @submit="AddIngredient()"
              ref="ingredient"
            >
              <v-layout class="mt-5 mt-md-10" wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="mt-1 mb-2 mb-sm-0 text-body-2 black--text">
                    Ingredient Category
                  </h5>
                </v-flex>
                <v-flex xs12 sm8 md6 lg4>
                  <v-autocomplete
                    label="Ingredient Category"
                    v-model="newIngredientCategory"
                    solo
                    dense
                    attach
                    :items="ingredientCategoryList"
                    item-text="nameEn"
                    item-value="_id"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsCategorySearchKey"
                    :loading="ingredientsCategoryLoading"
                    return-object
                    @change="onChangeIngredientCategory"
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient category by name...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data">{{
                      data.item.nameEn
                    }}</template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text  mb-2 mb-sm-0">
                    Ingredient
                  </h5>
                </v-flex>
                <v-flex xs12 sm8 md6 lg4>
                  <v-autocomplete
                    v-model="newIngredient"
                    :items="ingredientSearcList"
                    item-text="nameEn"
                    :rules="[requiredValidator('ingredient')]"
                    dense
                    attach
                    :error-messages="errors"
                    solo
                    chips
                    small-chips
                    label="Ingredient Name"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsSearchKey"
                    :loading="ingredientsLoading"
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient by name...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data"
                      >{{ data.item.nameEn }}, {{ data.item.nameAr }}</template
                    >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text  mb-2 mb-sm-0">
                    Ingredient ID
                  </h5>
                </v-flex>
                <v-flex xs12 sm8 md6 lg4>
                  <v-autocomplete
                    label="Ingredient ID"
                    v-model="newIngredient"
                    solo
                    dense
                    attach
                    :rules="[requiredValidator('ingredient id')]"
                    :items="ingredientSearcList"
                    item-text="itemId"
                    item-value="_id"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsIdSearchKey"
                    :loading="ingredientsLoading"
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient by id...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data">{{
                      data.item.itemId
                    }}</template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Dislikable</h5>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group v-model="isDislikable" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">
                    Quantity
                  </h5>
                </v-flex>
                <v-flex xs10 sm4 md4 class="mt-5 mt-md-0">
                  <v-text-field
                    v-model="newQuantity"
                    solo
                    dense
                    label="Quantity"
                    type="number"
                    min="0"
                    @keypress="isFloatNumber"
                    :rules="[requiredValidator('quantity')]"
                  >
                    <template v-if="newIngredient" v-slot:append>
                      <h4 class="mt-1 grey--text">
                        {{ getUnitName(newIngredient.quantityUnit) }}
                      </h4>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">
                    Quantity After Preparation
                  </h5>
                </v-flex>
                <v-flex xs10 sm4 md4 class="mt-5 mt-md-0">
                  <v-text-field
                    v-model="quantityAfter"
                    solo
                    dense
                    label="Quantity"
                    type="number"
                    min="0"
                    @keypress="isFloatNumber"
                    :rules="[requiredValidator('quantity')]"
                  >
                    <template v-if="newIngredient" v-slot:append>
                      <h4 class="mt-1 grey--text">
                        {{ getUnitName(newIngredient.quantityUnit) }}
                      </h4>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 sm4 md3 class="mt-sm-6 mt-md-0">
                  <v-btn
                    :width="
                      $vuetify.breakpoint.mdAndUp
                        ? 200
                        : $vuetify.breakpoint.sm
                        ? 140
                        : 100
                    "
                    color="primary"
                    class="secondary--text text-capitalize"
                    @click="AddIngredient()"
                    :loading="btnloading"
                    :disabled="!isValidIngredient"
                    >Add</v-btn
                  >
                </v-flex>
              </v-layout>
              <v-layout justify-sm-end wrap class="mt-3 mt-sm-0">
                <v-flex xs4 sm2 md1>
                  <h5 class="font-weight-medium error--text">Total Price:</h5>
                </v-flex>
                <v-flex xs6 sm2 md2>
                  <h4 class="font-weight-bolder error--text">
                    {{ ingredientPrice() }} KD
                  </h4>
                </v-flex>
              </v-layout>
            </v-form>
            <v-layout wrap class="mt-5">
              <v-flex xs12>
                <h5 class="text-body-2 black--text">Ingredients Added</h5>
              </v-flex>
              <v-flex xs12 class="mt-5 ">
                <app-ingredient-table
                  :ingredientList="ingredients"
                  :unitList="unitList"
                  @newFat="fatChange"
                  @newProtein="proteinChange"
                  @newCarbs="carbsChange"
                  @newCalories="caloriesChange"
                ></app-ingredient-table>
              </v-flex>
            </v-layout>
            <v-layout row justify-center class="mt-8">
              <v-btn
                width="120"
                color="secondary"
                class="black--text text-capitalize"
                @click="createMenuItem()"
                :loading="btnloading"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
// import AddImage from "../../core/components/AddImage";
import IngredientTable from "../components/IngredientTable.vue";
import { requiredValidator } from "../../core/methods/validations";
// import { ValidationProvider, extend } from "vee-validate";
// import { required } from "vee-validate/dist/rules";
import Croppa from "vue-croppa";
import Vue from "vue";
Vue.use(Croppa);
// import { required } from "vee-validate/dist/rules";
// extend("required", {
//   ...required,
//   message: "This field is required",
// });

export default Vue.extend({
  props: {
    unitList: {
      type: Array,
      required: true
    }
  },
  components: {
    // "app-add-image": Add0Image,
    // ValidationProvider,
    "app-ingredient-table": IngredientTable
  },
  computed: {
    rules() {
      return [
        this.dietPlans.length > 0 || "At least one item should be selected"
      ];
    }
  },
  data() {
    return {
      show_dialog_box: false,
      snack: false,
      snackColor: "",
      snackText: "",
      descriptionEn: "",
      descriptionAr: "",
      showIngredientsInApp: true,
      nameEn: "",
      myCroppa: null,
      nameAr: "",
      image: null,
      isValidIngredient: true,
      requiredValidator: requiredValidator,
      itemCode: "",
      mealCode: "",
      meanInfo: {
        protein: "",
        fat: "",
        carbs: "",
        calories: ""
      },
      active: true,
      dietPlans: [],
      // price: null,
      availableWeekdays: [],
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      isDaysToggled: false,
      category: [],
      mealsTag: [],
      ingredients: [],
      allCategories: [],
      allmealsTags: [],
      loading: false,
      kitchenTypesItems: ["Type1", "Type2", "Type3", "Type4"],
      kitchenType: [],
      variables: {
        limit: 25,
        skip: 0
      },
      newIngredient: "",
      newIngredientCategory: "",
      newQuantity: 0,
      quantityAfter: 0,
      isDislikable: false,
      ingredientsLoading: false,
      ingredientsCategoryLoading: false,
      ingredientsSearchKey: "",
      ingredientsIdSearchKey: "",
      ingredientsCategorySearchKey: "",
      ingredientSearcList: [],
      ingredientCategoryList: [],
      awaitingSearch: false,
      awaitingSearchId: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      allDietPlans: [],
      mealPortioning: false,
      mealPortionList: [],
      mealPortions: []
    };
  },
  watch: {
    newIngredient: {
      handler(value) {
        if (value) {
          console.log(value.ingredientCategory);
          if (value.ingredientCategory) {
            this.newIngredientCategory = value.ingredientCategory;
          }
        }
      },
      immediate: true
    },
    ingredientsCategorySearchKey: {
      handler(value) {
        if (this.ingredientsCategoryLoading == true) return;
        this.ingredientsCategoryLoading = true;
        this.$apollo
          .watchQuery({
            query: require("@/stock/api/getAllStockCategory.graphql"),
            variables: { searchKey: value }
          })
          .refetch({})
          .then(data => {
            if (data.data) {
              this.ingredientsCategoryLoading = false;
              this.ingredientCategoryList =
                data.data.getAllStockCategory.stockCategory;
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      immediate: true
    },
    ingredientsIdSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length < 1) return;
      this.getAllIngredients(val);
    },
    ingredientsSearchKey: function(val) {
      if (val == null || val == undefined) return;
      if (val.length <= 0) {
        return;
      }
      this.getAllIngredients(val);
    }
  },
  apollo: {
    getAllCategories() {
      return {
        query: require("../api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.allCategories = data.getAllCategories.category.filter(
            x => x.active
          );
        }
      };
    },
    getAllDietPlans() {
      return {
        query: require("../diet-plan/api/getAllDietPlans.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.allDietPlans = data.getAllDietPlans.filter(x => x.active);
        }
      };
    },
    getAllMealsTags() {
      return {
        query: require("../api/getAllMealsTags.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.allmealsTags = data.getAllMealsTags;
          // console.log(data.getAllIngredients);
        }
      };
    }
  },
  created() {
    this.getAllMealPortions();
  },
  methods: {
    getUnitName(id) {
      if (id) {
        let data = this.unitList.find(x => x._id == id);
        if (data) {
          return data.name;
        }
      }
    },

    getAllMealPortions() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          // .query({
          query: require("@/menu-packages/api/getAllMealPortions.graphql")
        })
        .refetch({})
        .then(data => {
          if (data.data) {
            this.mealPortionList = data.data.getAllMealPortions.mealPortions;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    onChangeIngredientCategory() {
      this.getAllIngredients("");
    },
    getAllIngredients(val) {
      this.ingredientsLoading = true;
      let ingredientCategoryId = "";
      if (this.newIngredientCategory) {
        ingredientCategoryId = this.newIngredientCategory._id;
      }
      this.$apollo
        .query({
          query: require("@/stock/api/getAllMealIngredients.graphql"),
          variables: {
            limit: 25,
            skip: 0,
            searchKey: val,
            ingredientCategoryId
          }
        })
        .then(data => {
          console.log(data);
          this.ingredientSearcList =
            data.data.getAllMealIngredients.ingredients;
          this.ingredientsLoading = false;
        });
    },
    fatChange(data) {
      this.meanInfo.fat = Math.round(data).toString();
    },
    proteinChange(data) {
      this.meanInfo.protein = Math.round(data).toString();
    },
    carbsChange(data) {
      this.meanInfo.carbs = Math.round(data).toString();
    },
    caloriesChange(data) {
      this.meanInfo.calories = Math.round(data).toString();
    },
    ingredientPrice() {
      let price = 0;
      // if (this.ingredients) {
      //   this.ingredients.map(function(elt) {
      //     price = price + elt.quantity * elt.ingredient.quantityPrice;
      //   });
      // }
      // return parseFloat(price).toFixed(3);
      if (this.ingredients) {
        for (let x of this.ingredients) {
          let unitConversionValue = this.unitList.find(
            y => y._id == x.ingredient.quantityUnit
          );
          let conversionValue = 1;
          if (unitConversionValue) {
            conversionValue = unitConversionValue.conversionValue;
          }
          price =
            price + x.quantity * x.ingredient.quantityPrice * conversionValue;
        }
      }
      return parseFloat(price).toFixed(3);
    },
    limiter(e) {
      if (e.length > 4) {
        e.pop();
      }
    },
    showDialogBox() {
      this.show_dialog_box = true;
      this.getNewMealCode();
    },
    getNewMealCode() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getNewMealCode.graphql")
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          if (data.data.getNewMealCode) {
            this.mealCode = `M${data.data.getNewMealCode}`;
            this.itemCode = data.data.getNewMealCode;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },

    toggleDays() {
      if (!this.isDaysToggled) {
        this.isDaysToggled = true;
        this.availableWeekdays = this.listOfDays;
      } else {
        this.availableWeekdays = [];
        this.isDaysToggled = false;
      }
    },
    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      // console.log(selectedFile);
      this.image = selectedFile;
    },
    close() {
      this.show_dialog_box = false;
    },
    AddIngredient() {
      const tempIngredient = {
        ingredient: this.newIngredient,
        quantity: this.newQuantity,
        quantityAfterPreperation: this.quantityAfter,
        isDislikable: this.isDislikable
      };

      let exists = this.ingredients.filter(
        x => x.ingredient._id == this.newIngredient._id
      );

      if (exists.length > 0) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Ingredient already exist";
        this.$refs.ingredient.reset();
      } else {
        this.ingredients.push(tempIngredient);
        this.$refs.ingredient.reset();
      }
    },
    createMenuItem() {
      if (this.availableWeekdays[0] == null) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add available days";
        return;
      }

      // if (!this.myCroppa.hasImage()) {
      //   this.snack = true;
      //   this.snackColor = "error";
      //   this.snackText = "Please select an image.";
      //   return;
      // }

      this.myCroppa.generateBlob(blob => {
        this.image = blob;
        if (!this.ingredients.length) {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Please add an ingredient";
          return;
        }
        if (this.category.length === 0) {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Please input category";
          return;
        }
        if (this.dietPlans.length === 0) {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "At least one diet plan should be selected";
          return;
        }
        let mealPortion = [];
        if (this.mealPortioning) {
          if (this.mealPortions.length == 0) {
            this.snack = true;
            this.snackColor = "error";
            this.snackText =
              "At least add one meal portion or turn off meal portioning";
            return;
          }
          mealPortion = this.mealPortions;
        }
        console.log(mealPortion);
        let createdIngredient = [];
        if (this.ingredients != null) {
          this.ingredients.map(function(elt) {
            createdIngredient.push({
              ingredient: elt.ingredient._id,
              quantity: String(elt.quantity),
              quantityAfterPreperation: String(elt.quantityAfterPreperation),
              isDislikable: elt.isDislikable
            });
          });
        }
        this.btnloading = true;
        this.$apollo
          .mutate({
            mutation: require("@/menu-packages/api/createMenuItem.graphql"),
            variables: {
              nameEn: this.nameEn,
              nameAr: this.nameAr,
              descriptionEn: this.descriptionEn,
              descriptionAr: this.descriptionAr,
              showIngredientsInApp: this.showIngredientsInApp,
              image: this.image,
              dietPlans: this.dietPlans,
              itemCode: this.itemCode.toString(),
              meanInfo: this.meanInfo,
              active: this.active,
              //   kitchenType :this.kitchenType,
              // price: this.price,
              category: this.category,
              mealsTag: this.mealsTag,
              ingredients: createdIngredient,
              availableWeekdays: this.availableWeekdays,
              mealPortion
            },
            update: (store, { data: { createMenuItem } }) => {
              try {
                const query = {
                  query: require("@/menu-packages/api/getAllMenuItems.graphql"),
                  variables: {
                    limit: 25,
                    skip: 0
                  }
                };

                let allMenuItems = store.readQuery(query);
                // console.log(allMenuItems);
                allMenuItems.getAllMenuItems.menuItem.unshift(createMenuItem); //push to top
                store.writeQuery({
                  ...query,
                  data: allMenuItems
                });
                this.text = "Menu Item Added";
                this.snackbar = true;
              } catch (e) {
                console.log("Something bad happend- MenuForm.vue");
              }
              this.$refs.form.reset();
              this.ingredients = [];
              this.btnloading = false;
              this.showIngredientsInApp = true;
              this.availableWeekdays = [];
              this.isDaysToggled = false;
              this.show_dialog_box = false;
              this.$root.$emit("updateMenuitems");
            }
          })
          .then(() => {
            this.active = true;
          })
          .catch(error => {
            this.btnloading = false;
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
              this.snack = true;
              this.snackColor = "error";
              this.snackText = error.graphQLErrors[0].message;
            }
          });
      });
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
});
</script>
