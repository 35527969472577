<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn
          class="white--text text-capitalize"
          v-on="on"
          height="40px"
          color="primary"
        >
          <v-icon class="white--text me-1" small>mdi-plus</v-icon> Add Customer
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title class="justify-center">
          <!-- <span class="primary--text" v-show="isDisable">Customer Details</span> -->
          <span class="black--text">Add Customer</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider class="mb-6"></v-divider>
        <!-- {{ addressDetails }} -->
        <v-card-text>
          <v-container class="mt-8">
            <v-form @submit="createCustomer()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n4 mt-sm-2">
                  <h4 class="text-body-2 black--text">First Name(English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    :rules="[requiredValidator('first name')]"
                    solo
                    dense
                    v-model="firstnameEn"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Last Name (English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field
                    :rules="[requiredValidator('last name')]"
                    solo
                    dense
                    v-model="lastnameEn"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">First Name(Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    :rules="[requiredValidator('first name(arabic)')]"
                    solo
                    dense
                    v-model="firstnameAr"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Last Name (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field
                    solo
                    :rules="[requiredValidator('last name(arabic)')]"
                    dense
                    v-model="lastnameAr"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Contact Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    class="inputPhone"
                    solo
                    dense
                    :counter="8"
                    type="number"
                    v-model="phone"
                    @keypress="isNumber"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 8),
                      maxLengthValidator('phone', 8),
                      this.doesPhoneExist != true || 'phone already exists.'
                    ]"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Email</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field
                    solo
                    dense
                    v-model="email"
                    @click="doesEmailExist = false"
                    :rules="[
                      requiredValidator('email'),
                      emailValidator(),
                      this.doesEmailExist != true || 'email already exists.'
                    ]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    solo
                    :rules="[
                      requiredValidator('password'),
                      minLengthValidator('password', 8)
                    ]"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    dense
                    v-model="password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Gender</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="genderList"
                    :rules="[requiredValidator('gender')]"
                    v-model="gender"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Martial Status</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    :items="martialStatusList"
                    :rules="[requiredValidator('martial status')]"
                    v-model="martialStatus"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
                <v-flex
                  v-if="gender == 'FEMALE'"
                  xs12
                  sm4
                  md3
                  lg2
                  ms-lg-6
                  class="mt-n2 mt-sm-2"
                >
                  <h4 class="text-body-2 black--text">Pregnant</h4>
                </v-flex>
                <v-flex v-if="gender == 'FEMALE'" xs12 sm8 md9 lg4>
                  <v-select
                    :items="pregnantList"
                    :rules="[requiredValidator('Pregnant')]"
                    v-model="pregnant"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Activities</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    :items="activityList"
                    :rules="[requiredValidator('activities')]"
                    v-model="activity"
                    dense
                    item-text="name"
                    item-value="_id"
                    attach
                    solo
                    multiple
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Health Issues</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="healthIssuesList"
                    :rules="[requiredValidator('health issues')]"
                    v-model="healthIssues"
                    dense
                    attach
                    solo
                    item-text="name"
                    item-value="_id"
                    multiple
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Date of birth</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        :value="formatDate"
                        dense
                        :rules="[requiredValidator('date of birth')]"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :max="new Date() | moment('YYYY-MM-DD')"
                      v-model="dateofbirth"
                      @input="menu2 = false"
                      color="secondary"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">How Found About App</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="UserSourceList"
                    :rules="[requiredValidator('user source')]"
                    v-model="UserSource"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs0 sm4 md3 lg7> </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-if="UserSource === 'OTHER'"
                    solo
                    dense
                    v-model="text"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Contact Method</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    :items="methodList"
                    :rules="[requiredValidator('contact method')]"
                    solo
                    dense
                    attach
                    item-text="method"
                    item-value="_id"
                    v-model="method"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Customer Goal</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="customerGoalList"
                    :rules="[requiredValidator('customer goal')]"
                    v-model="customerGoal"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Referred By</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field solo dense v-model="referredBy"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text"></h4>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text"></h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-checkbox v-model="isIncludeCustomMeal" label="Include Custom Meal?">
                  </v-checkbox>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-checkbox v-model="isCutleryRequired" label="Cutleries"> </v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Comment</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg10 class="pe-lg-12">
                  <v-textarea
                    rows="1"
                    v-model="customerComment"
                    solo
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <!-- {{ new Date() | moment("YYYY-MM-DD") }} -->
              <v-layout wrap>
                <v-flex xs12 class="mt-n2 mt-sm-2 mb-2 mb-sm-8">
                  <h4 class="text-h6 black--text font-weight-bold">Address</h4>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Area</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-autocomplete
                    v-model="address.area"
                    :items="allAreas"
                    item-text="nameEn"
                    item-value="_id"
                    solo
                    chips
                    dense
                    attach
                    @change="getBlocksByArea(address.area)"
                    small-chips
                    :rules="[requiredValidator('area')]"
                    clearable
                    deletable-chips
                    :loading="areasLoading"
                  >
                    <template slot="no-data">
                      <v-flex xs12 md6 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for area...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data"
                      >{{ data.item.nameEn }}, {{ data.item.nameAr }}</template
                    >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Block</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <!-- {{ blocksByArea }} -->
                  <v-select
                    v-model="address.block"
                    :items="blocksByArea"
                    item-text="block"
                    item-value="_id"
                    solo
                    @change="getShiftsByArea()"
                    :rules="[requiredValidator('block')]"
                    dense
                    attach
                    :disabled="!blockEnable"
                  >
                    <template slot="no-data">
                      <v-flex xs12 md6 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">Select a block</h5>
                      </v-flex>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Shift</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    v-model="address.shift"
                    :items="allShifts"
                    solo
                    dense
                    attach
                    :rules="[requiredValidator('shift')]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Street</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-model="address.street"
                    solo
                    :rules="[requiredValidator('street')]"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Jedha</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    v-model="address.jedha"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">House Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-model="address.house_number"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Floor Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    v-model="address.building_number"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Apartment Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-model="address.apartment_number"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Address Type</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    :items="addressTypeList"
                    :rules="[requiredValidator('address type')]"
                    v-model="address.addressType"
                    dense
                    attach
                    solo
                  >
                  </v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2>
                  <h4 class="black--text text-body-2">Comments</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg10 class="pe-lg-12">
                  <v-textarea
                    rows="1"
                    v-model="address.comments"
                    solo
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <!-- <v-text-field
                    label="Map Location"
                    v-model="address.map_location"
                    outlined
                    dense
                  ></v-text-field> -->

              <!-- {{ address }} -->
              <v-layout justify-end class="mt-5">
                <v-btn
                  color="secondary"
                  class="black--text text-capitalize"
                  :width="$vuetify.breakpoint.xs ? 90 : 120"
                  @click="createCustomer()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import {
  requiredValidator,
  emailValidator,
  minLengthValidator,
  maxLengthValidator
} from "../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
//import { ValidationProvider } from "vee-validate";
export default {
  data() {
    return {
      show1: false,
      UserSource: "",
      // isDisable: true,
      genderList: [
        { text: "Male", value: "MALE" },
        { text: "Female", value: "FEMALE" }
      ],
      UserSourceList: [
        { text: "Internet Search Engine", value: "INTERNET" },
        { text: "Social Media", value: "SOCIAL_MEDIA" },
        { text: "Through a friend or referal", value: "REFERRAL" },
        { text: "Other", value: "OTHER" }
      ],
      methodList: [],
      method: "",
      shift: "",
      allShifts: [],
      customerGoalList: [
        { text: "Lose Weight", value: "LOSE_WEIGHT" },
        { text: "Maintain Weight", value: "MAINTAIN_WEIGHT" },
        { text: "Gain Weight", value: "GAIN_WEIGHT" }
      ],
      customerComment: "",
      customerGoal: "",
      referredBy: "",
      menu2: false,
      firstnameEn: "",
      lastnameEn: "",
      firstnameAr: "",
      lastnameAr: "",
      phone: "",
      email: "",
      dateofbirth: "",
      gender: "",
      howFoundAboutApp: "",
      password: "",
      addressTypeList: ["Office", "Home", "Others"],
      address: {
        area: "",
        shift: "",
        block: "",
        street: "",
        jedha: "",
        house_number: "",
        building_number: "",
        apartment_number: "",
        comments: "",
        map_location: "",
        addressType: ""
      },
      blocksByArea: [],
      allAreas: [],
      areasLoading: false,
      areaSearchKey: "",
      awaitingAreasSearch: false,
      valid: true,
      minLengthValidator: minLengthValidator,
      emailValidator: emailValidator,
      maxLengthValidator: maxLengthValidator,
      requiredValidator: requiredValidator,
      errors: [],
      show_dialog_box: false,
      snackbar: false,
      snackbarColor: "",
      doesPhoneExist: false,
      doesEmailExist: false,
      text: "",
      btnloading: false,
      blockEnable: false,

      martialStatus: "",
      pregnant: "",
      activity: "",
      healthIssues: "",
      martialStatusList: [
        { text: "Married", value: "MARRIED" },
        { text: "Unmarried", value: "UNMARRIED" },
        { text: "Divorced", value: "DIVORCED" }
      ],
      pregnantList: [
        "No",
        "First Trimester",
        "Second Trimester",
        "Third Trimester"
      ],
      activityList: [],
      healthIssuesList: [],
      isCutleryRequired: true,
      isIncludeCustomMeal:false,
    };
  },
  created() {
    this.getAllContactMethods();
    this.getAllAreas();
    this.getAllActivities();
    this.getAllHealthIssue();
  },

  computed: {
    formatDate() {
      return this.dateofbirth
        ? moment(this.dateofbirth).format(DATE_FORMAT)
        : "";
    }
  },
  methods: {
    getAllActivities() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/e-clinic/api/getAllActivity.graphql")
        })
        .then(data => {
          if (data.data) {
            this.activityList = data.data.getAllActivities.activities;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAllHealthIssue() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/e-clinic/api/getAllHealthIssue.graphql")
        })
        .then(data => {
          if (data.data)
            this.healthIssuesList = data.data.getAllHealthIssue.healthIssues;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    close() {
      // this.isDisable = true;
      this.isNewAddress = false;
      this.show_dialog_box = false;
    },
    getAllAreas() {
      this.$apollo
        .query({
          query: require("@/customer/api/getAllAreas.graphql"),
          variables: {
            searchKey: ""
          }
        })
        .then(data => {
          this.allAreas = data.data.getAllAreas.areas;
          this.areasLoading = false;
        });
    },
    getAllContactMethods() {
      this.$apollo
        .query({
          query: require("@/contact-method/api/getAllContactMethods.graphql"),
          variables: {
            limit: 25,
            skip: 0
          }
        })
        .then(data => {
          this.methodList = data.data.getAllContactMethods.methods;
        });
    },
    getBlocksByArea(areaId) {
      if (areaId) {
        this.$apollo
          .query({
            query: require("@/customer/api/getBlocksByArea.graphql"),
            variables: {
              id: areaId
            }
          })
          .then(data => {
            this.blocksByArea = data.data.getBlocksByArea.blocks;
            this.blockEnable = true;
          });
      }
    },
    getShiftsByArea() {
      if (this.address.area && this.address.block) {
        this.$apollo
          .query({
            query: require("@/customer/api/getShiftsByArea.graphql"),
            variables: {
              area: this.address.area,
              block: this.address.block
            }
          })
          .then(data => {
            this.allShifts = data.data.getShiftsByArea.shifts;
          });
      } else {
        this.allShifts = [];
      }
    },
    createCustomer() {
      this.btnloading = true;
      let userInput = {
        firstnameEn: this.firstnameEn,
        lastnameEn: this.lastnameEn,
        firstnameAr: this.firstnameAr,
        lastnameAr: this.lastnameAr,
        email: this.email,
        phone: this.phone,
        gender: this.gender,
        dob: this.dateofbirth,
        password: this.password
      };

      let deliveryInput = [];
      let howFoundAboutApp = {
        userSource: this.UserSource,
        text: this.text
      };
      let martialStatus = this.martialStatus;
      let pregnantStatus = null;
      if (this.gender == "FEMALE") {
        pregnantStatus = this.pregnant;
      }
      deliveryInput.push(this.address);
      this.$apollo
        .mutate({
          mutation: require("../api/adminCustomerSignUp.graphql"),
          variables: {
            input: userInput,
            deliveryInput: deliveryInput,
            howFoundAboutApp: howFoundAboutApp,
            contactMethod: this.method,
            referredBy: this.referredBy,
            comment: this.customerComment,
            customerGoal: this.customerGoal,
            martialStatus,
            pregnantStatus,
            activity: this.activity,
            healthIssues: this.healthIssues,
            isCutleryRequired: this.isCutleryRequired,
            isIncludeCustomMeal:this.isIncludeCustomMeal
          }
        })
        .then(() => {
          this.$root.$emit("updateCustomers");
          this.close();
          this.$refs.form.reset();
          this.text = "Customer Added";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.btnloading = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: EMAIL_ALREADY_EXIST") {
              this.doesEmailExist = true;
            } else if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            } else {
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
              this.btnloading = false;
            }
            window.scrollTo(0, 0);
            this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });

            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.isNewAddress = false;
        });
    }
  }
};
</script>
number

<style>
.inputPhone input[type=""] {
  -moz-appearance: textfield;
}
.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
