<template>
  <v-container class="pa-8" fluid>
    <v-row v-show="fromCustomer">
      <!-- <Shopping /> -->
      <v-btn class="white--text text-capitalize mt-1 mb-5" color="primary" @click="onShopping"> <v-icon class="me-1"
          small>mdi-plus</v-icon>Add </v-btn>
    </v-row>
    <h2 class="primary--text">Shopping</h2>
    <v-layout class="mt-10" wrap>
      <v-flex xs12 md2>
        <v-autocomplete v-model="filter.zone" :loading="zoneLoading" clearable @change="zoneChange()" :items="zoneList"
          solo item-text="zoneName" item-value="_id" label="Zone" dense></v-autocomplete>
      </v-flex>
      <v-flex xs12 md2 class="ms-md-5">
        <v-autocomplete v-model="filter.deliveryStatus" dense clearable @change="statusChange()" :items="statusTypes"
          label="Order Status" solo></v-autocomplete>
      </v-flex>
      <v-flex xs12 md3 class="mt-3 mt-md-0 ms-md-5">
        <v-menu v-model="menu1" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDate" label="Order From Date" solo append-icon="mdi-calendar-month-outline"
              v-bind="attrs" v-on="on" dense></v-text-field>
          </template>
          <v-date-picker v-model="filter.orderDateFrom" @change="selectFromDate"></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 md3 class=" mt-3 mt-md-0 ms-md-5">
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDate" label="Order To Date" solo append-icon="mdi-calendar-month-outline"
              v-bind="attrs" v-on="on" dense></v-text-field>
          </template>
          <v-date-picker v-model="filter.orderDateTo" @change="selectFromDate"></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-flex md4>

      <v-text-field placeholder="Search" v-model="searchKey" @click.enter="searchChange()" solo dense>
        <template v-slot:prepend-inner>
          <v-icon class=" ">mdi-magnify</v-icon>
        </template>
      </v-text-field>
    </v-flex>
    <v-tabs class="mt-10" center-active v-model="tab">
      <v-tab class="font-weight-black">Current Orders</v-tab>
      <v-tab class="font-weight-black">Past Orders</v-tab>
      <v-tab class="font-weight-black">Pending Orders</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pt-10">
      <v-tab-item :key="1">
        <ShoppingItem :tab="tab" :type="'CURRENT'" :searchKey="searchKey" :fromCustomer="fromCustomer" :filter="filter"
          :customer="customer" />
      </v-tab-item>
      <v-tab-item :key="2">
        <ShoppingItem :tab="tab" :type="'PAST'" :searchKey="searchKey" :fromCustomer="fromCustomer" :filter="filter"
          :customer="customer" />
      </v-tab-item>
      <v-tab-item :key="3">
        <ShoppingItem :tab="tab" :type="'PENDING'" :searchKey="searchKey" :fromCustomer="fromCustomer" :filter="filter"
          :customer="customer" />
      </v-tab-item>
    </v-tabs-items>
    <v-system-bar height="1"></v-system-bar>
  </v-container>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import ShoppingItem from "../components/ShoppingItem.vue";
export default {
  props: {
    fromCustomer: {
      required: false,
    },
    customer: {
      required: false,
    },
  },


  data() {
    return {
      zones: ['Zone 1', 'Zone 2'],
      statusTypes: ["PENDING", "DISPATCHED"],
      fDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      menu1: false,
      menu2: false,
      tab: 0,
      searchKey: "",
      zoneList: [],
      filter: {
        deliveryStatus: "",
        paymentStatus: "SUCCESS",
        orderDateFrom: format(
          parseISO(
            moment()
              .subtract(7, "days")
              .toDate()
              .toISOString()
          ),
          "yyyy-MM-dd"
        ),

        orderDateTo: format(
          parseISO(
            moment()
              .toDate()
              .toISOString()
          ),
          "yyyy-MM-dd"
        ),
        zone: "",
      },
    };
  },
  watch: {
    tab: {
      handler() {
        if (this.tab == 0) {
          this.statusTypes = ["PENDING", "DISPATCHED"];
        }
        if (this.tab == 1) {
          this.statusTypes = ["DELIVERED", "CANCELLED"];
        }
        if (this.tab == 2) {
          this.statusTypes = ["PENDING"];
        }
        this.filter.deliveryStatus = "";
      },
    },
  },
  created() {
    //this.getAllOrders();
    if (!this.hasRole(this.AdminUserPermissions.SHOP_ORDER_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getAllZones();
  },
  methods: {
    onShopping() {
      this.$router.push({
        name: "CustomerShopping",
        query: { customerId: this.$route.query.customerId },
      });
    },
    selectFromDate() {
      this.menu1 = false;
      this.menu2 = false;
    },

    getAllZones() {
      this.zoneLoading = true;
      this.$apollo
        .query({
          query: require("@/customer/api/getAllZones.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey: "",
          },
        })
        .then(data => {
          this.zoneLoading = false;
          if (data.data.getAllZones) {
            this.zoneList = data.data.getAllZones.zones;
          }
        })
        .catch(error => {
          this.zoneLoading = false;
          console.log(error);
        });
    },
  },
  computed: {
    fromDate() {
      return this.filter.orderDateFrom ? moment(this.filter.orderDateFrom).format("DD/MM/YYYY") : "";
    },
    toDate() {
      return this.filter.orderDateTo ? moment(this.filter.orderDateTo).format("DD/MM/YYYY") : "";
    },
  },
  components: { ShoppingItem }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.row-pointer>>>tbody tr :hover {
  cursor: pointer;
}
</style>

<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
