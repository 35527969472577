<template>
  <div class="secondary black--text rounded-xl " style="height: 270px">
    <!-- {{ address }} -->

    <v-row class="mx-2 mt-4 pa-4" justify="space-between" align="end">
      <h4 class="black--text text-h6 font-weight-bold">Address</h4>

      <v-pagination
        class="mx-n2"
        v-if="address.length > 1"
        v-model="page"
        :length="address.length"
      ></v-pagination>
    </v-row>

    <div class="px-4" v-if="address.length > 0">
      <div v-if="address[page - 1]">
        <!-- <v-layout class="mt-3 mt-md-3">
          <v-flex>Area</v-flex>
          <v-flex
            v-if="address[page - 1].area"
            xs8
            lg1
            class="font-weight-medium"
          >:{{ address[page - 1].area.nameEn }}</v-flex>
          <v-flex v-else xs8 lg1 class="font-weight-medium ms-md-16">:</v-flex>
        </v-layout>-->

        <v-row class="mb-n6 mt-n5">
          <v-col class="ms-2" md="5">Area</v-col>
          <v-col md="6" v-if="address[page - 1].area" class="">
            {{ address[page - 1].area.nameEn }}
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">Block</v-col>
          <v-col md="6" class="" v-if="address[page - 1].block">{{
            address[page - 1].block.block
          }}</v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">Street</v-col>
          <v-col md="6" class="font-weight-medium">{{
            address[page - 1].street
          }}</v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col md="5" class="ms-2">Jedha</v-col>
          <v-col md="6" class="font-weight-medium">{{
            address[page - 1].jedha
          }}</v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col class="ms-2" md="5">House number</v-col>
          <v-col md="6" class="font-weight-medium">{{
            address[page - 1].house_number
          }}</v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col class="ms-2" md="5">Floor number</v-col>
          <v-col md="6" class="font-weight-medium">
            {{ address[page - 1].building_number }}
          </v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col class="ms-2" md="5">Shift</v-col>
          <v-col md="6" class="font-weight-medium">
            <span v-if="address[page - 1].shift">
              {{ address[page - 1].shift }}
            </span>
            <span v-else> - </span>
          </v-col>
        </v-row>
        <!-- <v-layout class="mt-3 mt-md-3">
        <v-flex> Map location</v-flex>
        <v-flex xs8 lg1 class="font-weight-medium">
          :{{ address[page-1].map_location }}
        </v-flex>
        </v-layout>-->
        <v-row class="mt-n6">
          <v-col class="ms-2" md="5">Comments</v-col>
          <v-col md="6" class="font-weight-medium">{{
            address[page - 1].comments
          }}</v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-layout justify-center class="mt-16">No Address Found</v-layout>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1
    };
  },
  props: {
    address: {
      required: true
    }
  }
};
</script>
