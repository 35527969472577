<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="850px">
      <template v-slot:activator="{ on }">
        <a v-on="on" href="javascript:void(0)" @click="showOrderDetails">{{
          orderInfo.orderId
        }}</a>
      </template>
      <v-card class="px-4 py-6 py-sm-8" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2"
            >Order Details</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-card-text class="pa-0 pt-2" v-if="order">
          <v-row>
            <v-col md4 cols="5">
              <v-card v-if="order.address">
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> Area</v-col>
                  <v-col md4 cols="6"> {{ order.address.area.nameEn }}</v-col>
                </v-row>
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> Block</v-col>
                  <v-col md4 cols="6"> {{ order.address.block.block }}</v-col>
                </v-row>
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> Address Type</v-col>
                  <v-col md4 cols="6"> {{ order.address.addressType }}</v-col>
                </v-row>
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> Street</v-col>
                  <v-col md4 cols="6"> {{ order.address.street }}</v-col>
                </v-row>
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> House Number</v-col>
                  <v-col md4 cols="6"> {{ order.address.house_number }}</v-col>
                </v-row>
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> Building Number</v-col>
                  <v-col md4 cols="6">
                    {{ order.address.building_number }}</v-col
                  >
                </v-row>
                <v-row class="mx-2 my-n1">
                  <v-col md4 cols="6"> Apartment Number</v-col>
                  <v-col md4 cols="6">
                    {{ order.address.apartment_number }}</v-col
                  >
                </v-row>
              </v-card>
            </v-col>
            <v-col>
              <v-btn small class="me-2 primary" @click="printA4Orders()">
                <v-icon small class="me-2">mdi-download</v-icon>
                A4 Order</v-btn
              >
            </v-col>
            <v-col>
              <v-btn small class="me-2 primary" @click="printPosOrders()">
                <v-icon small class="me-2">mdi-download</v-icon>
                POS Order</v-btn
              >
            </v-col>
          </v-row>
          <v-container class="mt-2">
            <v-data-table
              :headers="headers"
              :items="itemsWithSno"
              mobile-breakpoint="0"
              class="row-pointer"
            >
              <template v-slot:[`item.productName`]="{ item }">
                {{ item.productInfo.nameEn }}
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                {{ item.quantity }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ item.productInfo.price }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <v-sheet :color="`grey lighten-4`" class="pa-3">
            <v-skeleton-loader
              class="mx-auto"
              max-width="850"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { printA4Orders } from "../methods/printA4Orders";
import { printPosOrders } from "../methods/printPosOrders";
export default {
  props: {
    orderInfo: {
      required: true,
    },
  },

  data() {
    return {
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      order: null,
      products: [],
      headers: [
        { text: "#", sortable: false, value: "slNo", width: 100 },
        { text: "Product Name", value: "productName", width: 200 },
        { text: "Quantity", value: "quantity", width: 100 },
        { text: "Price", value: "price", width: 120 },
      ],
    };
  },
  computed: {
    itemsWithSno() {
      return this.order.products.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  methods: {
    printA4Orders() {
      printA4Orders([this.order]);
    },
    printPosOrders() {
      printPosOrders([this.order]);
    },

    showOrderDetails() {
      this.orderDetails();
    },
    orderDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/orderDetails.graphql"),
          variables: {
            id: this.orderInfo._id,
          },
        })
        .refetch({
          id: this.orderInfo._id,
        })
        .then((data) => {
          this.loading = false;
          if (data.data.orderDetails) {
            this.order = data.data.orderDetails;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    close() {
      this.show_dialog_box = false;
    },
  },
};
</script>