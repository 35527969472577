<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="white--text text-capitalize font-weight-bold" color="secondary"> <v-icon small
            class="me-1">mdi-plus</v-icon> Add </v-btn>
      </template>
      <v-card class="px-4 py-6 py-sm-8" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2">Add Product</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <v-divider></v-divider>

        <v-card-text class="pa-0 pt-2">
          <v-container class="mt-2">
            <v-form @submit="createProduct()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Search Meal
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="mealSearchKey" solo placeholder="Map Meal(Enter Meal Code)" dense></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 md1 class="">
                  <SearchMeal :search="mealSearchKey" @onSelectMeal="onSelectMeal" />
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-text-field type="number" v-model="order" :rules="[requiredValidator('order')]" solo
                    placeholder="order" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Product Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field v-model="nameEn" :rules="[requiredValidator('product')]" solo
                    placeholder="Product Name English" dense></v-text-field>
                </v-flex>

                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-text-field v-model="nameAr" :rules="[requiredValidator('product')]" solo
                    placeholder="Product Name Arabic" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Product Description
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-textarea v-model="descriptionEn" :rules="[requiredValidator('description')]" solo
                    placeholder="Description English" dense></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-textarea v-model="descriptionAr" solo placeholder="Description Arabic" dense></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Minimum order qty</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="minimumOrderQty" :rules="[requiredValidator('minimum order qty')]" solo
                    placeholder="Minimum order qty" dense></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 md2 offset-md-0 class="ms-md-5">
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Maximum order qty</h4>
                </v-flex>
                <v-flex xs12 sm8 md3 offset-sm-3 offset-md-0 class="ms-md-5">
                  <v-text-field v-model="maximumOrderQty" :rules="[requiredValidator('maximum order qty')]" solo
                    placeholder="Maximum order qty" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Categories
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-select v-model="productCategories" :items="categories" solo dense item-value="_id" item-text="nameEn"
                    attach chips small-chips label="Select Category" multiple deletable-chips></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Price</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field v-model="price" :rules="[requiredValidator('price')]" solo placeholder="Product Price"
                    type="number" dense></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-checkbox v-model="status" label="Active"> </v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Stock</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-select :items="['IN_STOCK', 'OUT_OF_STOCK']" label="" v-model="stockType" dense solo></v-select>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-checkbox v-model="isMeal" label="Is Meal"> </v-checkbox>
                </v-flex>
              </v-layout>

              <!-- <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Initial Quanity</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    v-model="initialQuantity"
                    :rules="[requiredValidator('Initial Quantity')]"
                    solo
                    placeholder="Initial Quantity"
                    type="number"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout> -->

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Image</h5>
                </v-flex>

                <v-flex xs12 sm7 class="mt-3 mt-sm-0 ml-n2">
                  <croppa class="ml-2 mt-2" v-model="myCroppa" :width="170" :height="120" canvas-color="black"
                    :placeholder="'Choose an image'" :placeholder-font-size="16" placeholder-color="yellow"
                    :accept="'image/*'" :file-size-limit="0" :quality="5" :zoom-speed="3" :disabled="false"
                    :disable-drag-and-drop="false" :disable-click-to-choose="false" :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false" :disable-rotation="false" :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false" :show-remove-button="true" :remove-button-color="'red'"
                    :remove-button-size="10"></croppa>

                  <!-- <input
                  type="file"
                  accept="image/*"
                  @change="onFileSelected($event)"
                /> -->
                  <v-layout>
                    <span style="color: red">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-4  " v-if="isMeal">
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2 mt-3">Fat</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="meanInfo.fat" min="0" @keypress="formatDecimal" solo
                    :rules="[requiredValidator('fat')]" type="number" suffix="gm"></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2 mt-3">Protein</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="meanInfo.protein" solo min="0" :rules="[requiredValidator('protein')]"
                    @keypress="formatDecimal" type="number" suffix="gm"></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2 mt-3">Carb</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="meanInfo.carbs" solo min="0" :rules="[requiredValidator('carb')]"
                    @keypress="formatDecimal" type="number" suffix="gm"></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2 mt-3">Calories</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="meanInfo.calories" solo min="0" :rules="[requiredValidator('calories')]"
                    @keypress="formatDecimal" type="number" suffix="gm"></v-text-field>
                </v-flex>
              </v-layout>

              <ProductCategoryDiscount :itemDiscount="itemDiscount" class="mt-4" type="product" />
              <v-layout row justify-end class="mt-2 me-1">
                <v-btn :width="$vuetify.breakpoint.smAndUp ? 120 : 90" color="secondary"
                  class="white--text text-capitalize" @click="createProduct()" :loading="btnloading" :disabled="!valid">
                  Save</v-btn>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import SearchMeal from "../components/SearchMeal.vue";
import ProductCategoryDiscount from "@/discount/components/ProductCategoryDiscount";

export default {
  components: { SearchMeal, ProductCategoryDiscount },
  data() {
    return {
      requiredValidator: requiredValidator,
      minimumOrderQty: "",
      maximumOrderQty: "",
      method: "",
      methodAr: "",
      myCroppa: null,
      image: null,
      snackColor: "",
      order: "",
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      isActive: true,
      nameEn: "",
      nameAr: "",
      code: "",
      descriptionEn: "",
      descriptionAr: "",
      price: "",
      stockType: "IN_STOCK",
      status: false,
      show_dialog_box: false,
      initialQuantity: "",
      mealCode: "",
      mealSearchKey: "",
      isMeal: false,
      meanInfo: { fat: "", protein: "", carbs: "", calories: "" },
      loading: false,
      categories: [],
      productCategories: [],
      itemDiscount: {
        enableDiscount: false,
        discount: 0,
        inPercentage: false,
        maxDiscountAmount: 0,
        startDate: "",
        endDate: ""
      }
    };
  },
  watch: {},
  apollo: {
    getAllProductCategories() {
      return {
        query: require("../api/getAllProductCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          console.log(data);
          this.categories = data.getAllProductCategories;
          this.loading = loading;
        },
      };
    },
  },
  methods: {
    formatDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)) {
        // 46 is dot
        $event.preventDefault();
      }
      if (this.alertQuantity != null && this.alertQuantity.indexOf(".") > -1 && this.alertQuantity.split(".")[1].length > 2) {
        $event.preventDefault();
      }
    },
    close() {
      this.show_dialog_box = false;
    },
    selectMeal() {
      this.getMenuItemByCode();
    },
    onSelectMeal(mealInfo) {
      debugger;
      if (mealInfo) {
        this.mealInfo = mealInfo;
        this.nameEn = this.mealInfo.nameEn;
        this.nameAr = this.mealInfo.nameAr;
        this.descriptionEn = this.mealInfo.descriptionEn;
        this.descriptionAr = this.mealInfo.descriptionAr;
        this.meanInfo = this.mealInfo.meanInfo;
        this.isMeal = true;
      }
    },

    getMenuItemByCode() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getMenuItemByCode.graphql"),
          variables: {
            mealCode: this.mealCode,
          },
        })
        .refetch({
          variables: {
            mealCode: this.mealCode,
          },
        })
        .then(data => {
          this.loading = false;
          if (data.data.getMenuItemByCode) {
            this.mealInfo = data.data.getMenuItemByCode;
            this.nameEn = this.mealInfo.nameEn;
            this.nameAr = this.mealInfo.nameAr;
            this.descriptionEn = this.mealInfo.descriptionEn;
            this.descriptionAr = this.mealInfo.descriptionAr;
            this.meanInfo = this.mealInfo.meanInfo;
            this.isMeal = true;
          } else {
            this.snackbar = true;
            this.mealCode = "";
            this.text = "No meal found";
            this.snackColor = "error";
            this.meanInfo = null;
            this.descriptionEn = "";
            this.descriptionAr = "";
          }
        })
        .catch(error => {
          this.snackbar = true;
          this.mealCode = "";
          this.text = "No meal found";
          this.snackColor = "error";
          this.loading = false;
          this.meanInfo = null;
          this.titleEn = "";
          this.titleEn = "";
          this.descriptionEn = "";
          this.descriptionAr = "";
          console.log(error);
        });
    },
    createProduct() {
      if (!this.myCroppa) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please choose an image.";
      }
      this.myCroppa.generateBlob(blob => {
        this.image = blob;
        this.createProductAPI();
      });
    },
    createProductAPI() {
      this.btnloading = true;
      let discount = null;

      if (this.itemDiscount.enableDiscount) {
        if (!this.itemDiscount.inPercentage) {
          if (parseFloat(this.itemDiscount.discount) >= parseFloat(this.price)) {
            this.snackbar = true;
            this.snackColor = "error";
            this.text = "Discount amount should be less than the product amount.";
            return false;
          }
        }
        discount = {
          startDate: this.itemDiscount.startDate,
          endDate: this.itemDiscount.endDate,
          discount: parseFloat(this.itemDiscount.discount),
          inPercentage: this.itemDiscount.inPercentage,
          maxDiscountAmount: parseFloat(this.itemDiscount.maxDiscountAmount),
        }
      }

      this.$apollo
        .mutate({
          mutation: require("../api/createProduct.graphql"),
          variables: {
            input: {
              nameEn: this.nameEn,
              nameAr: this.nameAr,
              code: this.code,
              descriptionEn: this.descriptionEn,
              descriptionAr: this.descriptionAr,
              price: parseFloat(this.price),
              status: this.status,
              stockType: this.stockType,
              initialQuantity: parseInt(this.initialQuantity),
              image: this.image,
              order: parseInt(this.order),
              minimumOrderQty: parseInt(this.minimumOrderQty),
              maximumOrderQty: parseInt(this.maximumOrderQty),
              meanInfo: {
                fat: String(this.meanInfo.fat),
                protein: String(this.meanInfo.protein),
                carbs: String(this.meanInfo.carbs),
                calories: String(this.meanInfo.calories),
              },
              isMeal: this.isMeal,
              categories: this.productCategories,
              discount: discount
            },
          },
        })
        .then(() => {
          this.$root.$emit("updateProduct");
          this.$refs.form.reset();
          this.isActive = true;
          this.btnloading = false;
          this.show_dialog_box = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
