<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="primary--text font-weight-bold">{{
          status ? "Update order Status to " + status : ""
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="font-weight-bold"
            text
            @click="cancel()"
            >No</v-btn
          >
          <v-btn
            color="secondary"
            class="font-weight-bold"
            text
            @click="deleteItem()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  <script>
export default {
  props: {
    status: String,
  },
  data() {
    return {
      dialog: false,
      clickedYes: false,
    };
  },
  watch: {
    dialog() {
      if (!this.dialog && !this.clickedYes) {
        this.cancel();
      }
    },
  },
  methods: {
    show() {
      this.clickedYes = false;
      this.dialog = true;
    },
    deleteItem() {
      this.clickedYes = true;
      this.dialog = false;
      this.$emit("deleteThisItem", true);
    },
    cancel() {
      this.clickedYes = false;
      this.dialog = false;
      this.$emit("deleteThisItem", false);
    },
  },
};
</script>