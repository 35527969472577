var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fromCustomer),expression:"!fromCustomer"}],attrs:{"justify":"end"}},[_c('div',{staticClass:"d-flex px-4 my-2 my-sm-0"},[_c('ExportOrders',{attrs:{"headers":_vm.filteredHeaders,"zone":_vm.zone,"deliveryStatus":_vm.deliveryStatus,"paymentStatus":_vm.paymentStatus,"fromDate":_vm.deliveryDate,"toDate":_vm.orderDate}})],1)]),_c('v-row',{staticClass:"justify-space-between"}),_c('v-spacer'),_c('v-layout',{staticClass:"mt-2",attrs:{"row":""}}),_c('v-row',{staticClass:"flex-wrap my-4 ml-5",attrs:{"justify":"start"}},[_c('span',[_c('AddButton',{attrs:{"action":_vm.onDispatched,"disabled":_vm.showMarkAsDespatch,"icon":"mdi-truck","text":"Mark as dispatched"},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1),_c('span',[_c('AddButton',{attrs:{"action":_vm.onDelivered,"disabled":_vm.showMarkAsDeliverd,"icon":"mdi-account-check","text":"Mark as Delivered"},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1),_c('span')]),_c('v-row',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.allOrders,"search":_vm.search,"loading":_vm.loading,"mobile-breakpoint":"0","dense":"","options":_vm.options,"server-items-length":_vm.totalOrders,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":_vm.onOrderSelect},model:{value:(item.isChecked),callback:function ($$v) {_vm.$set(item, "isChecked", $$v)},expression:"item.isChecked"}})]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('ViewOrderDetails',{attrs:{"orderInfo":item}})]}},{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderNumber)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item.customer._id },
          query: { customerId: item.customer._id },
        }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.user.lastnameEn))+" ")])]}},{key:"item.phone",fn:function(ref){
        var item = ref.item;
return [(item.customer)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.customer.user.phone)+" ")]):_vm._e()]}},{key:"item.zone",fn:function(ref){
        var item = ref.item;
return [(item.zone)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.zone ? item.zone.zoneName : "-")+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")])]}},{key:"item.discount",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.discount))+" ")])]}},{key:"item.netPrice",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.netPrice))+" ")])]}},{key:"item.paymentStatus",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0",class:{
          'red--text': item.paymentStatus == 'PENDING',
        }},[_c('strong',[_vm._v(" "+_vm._s(item.paymentStatus))])])]}},{key:"item.deliveryStatus",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0",class:{
          'red--text': item.paymentStatus == 'SUCCESS' && item.deliveryStatus == 'PENDING',
        }},[_c('strong',[_vm._v(" "+_vm._s(item.deliveryStatus))])])]}},{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [(item.createdBy)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(_vm._s(item.createdBy.firstnameEn)+" "+_vm._s(item.createdBy.lastnameEn))]):_vm._e()]}},{key:"item.orderDate",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0 gray2--text"},[_vm._v(" "+_vm._s(_vm.moment(new Date(parseInt(item.createdAt))).format("DD MMM YYYY"))+" ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(item.paymentStatus == 'PENDING')?_c('CheckPaymentStatus',{attrs:{"item":item}}):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deliveryTimeDialog),callback:function ($$v) {_vm.deliveryTimeDialog=$$v},expression:"deliveryTimeDialog"}},[_c('v-card',[(_vm.status === 'DISPATCHED')?_c('v-card-text',{staticClass:"pt-5"},[_c('h5',{staticClass:"mb-4"},[_vm._v("Note")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Text","required":""},model:{value:(_vm.deliveryText),callback:function ($$v) {_vm.deliveryText=$$v},expression:"deliveryText"}}),_c('p',{staticClass:"warning--text text-center"},[_vm._v(" "+_vm._s(_vm.deliveryTimeRequired)+" ")])],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDeliveryTime()}}},[_vm._v(" close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.setDeliveryTime()}}},[_vm._v(" Save ")])],1)],1)],1),_c('OrderStatusConfirmationModal',{ref:"status_modal",attrs:{"status":_vm.status},on:{"deleteThisItem":_vm.updateConfirmed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }