var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('span',{staticClass:"  primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'Drivers' })}}},[_c('v-icon',{staticClass:"me-1",attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-left")]),_vm._v("back ")],1),_c('div',{staticClass:"d-flex justify-space-between "},[_c('h2',{staticClass:"primary--text text-h5 font-weight-bold mb-4 mt-4"},[_vm._v(" Driver Customer Report ")]),_c('span',[_c('ExportDriverDeliveryReport',{attrs:{"driverId":_vm.driverId,"date":_vm.date,"customersList":_vm.customers}})],1)]),_c('v-flex',{staticClass:"me-4",attrs:{"xs6":"","sm3":"","md2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.formatedDate,"label":"Date","outlined":"","append-icon":"mdi-calendar-month-outline","dense":""},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"change":_vm.selectDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),(_vm.driverName)?_c('p',[_vm._v("Driver Name :"+_vm._s(_vm.driverName))]):_vm._e(),_c('v-row',{staticClass:"mt-3 mt-sm-0"},[_c('h6',{staticClass:"text-body-2 black--text font-weight-bold ms-2 mb-6"},[_vm._v(" Total Deliveries Today: "+_vm._s(_vm.count ? _vm.count : 0)+" ")])]),_c('v-data-table',{staticClass:"elevation-1 mt-5 ",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.customers,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item._id },
          query: { customerId: item._id }
        }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }