<template>
  <v-container fluid class="px-0">
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="purchaseDetails"
      :items-per-page="5"
      class="elevation-1"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.quantity="{ item }">
        <v-layout class="mt-3">
          <v-flex md8>
            <v-text-field
              v-model="item.quantity"
              :rules="[requiredValidator('quantity')]"
              type="number"
              min="0"
              max="10000"
              @change="calculateTotalPrice(item)"
              outlined
              dense
            ></v-text-field>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:item.unitPrice="{ item }">
        <v-layout class="mt-3">
          <v-flex md8>
            <v-text-field
              v-model="item.unitPrice"
              :rules="[requiredValidator('quantity')]"
              type="number"
              min="0"
              @change="calculateTotalPrice(item)"
              outlined
              dense
              suffix="KD"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:item.totalPrice="{ item }">
        {{ parseFloat(item.totalPrice).toFixed(3) }} KD
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon color="red" @click="deleteItem(item.ingredient.itemId)"
          >mdi-delete</v-icon
        >
      </template>
      <template slot="body.append">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ purchasePrice }} KD</th>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    purchaseDetails: {
      require: true,
    },
  },
  watch: {
    purchaseDetails: {
      handler() {
        this.totalPurchasePrice();
      },
      deep: true,
    },
  },
  created() {
    this.purchaseDetails.map(function (elt) {
      if (elt.ingredient.quantityUnit != "PIECE") {
        elt.quantity = (elt.quantity / 1000).toString();
      }
    });
    this.totalPurchasePrice();
  },

  data() {
    return {
      requiredValidator,
      headers: [
        { text: "Item ID", value: "ingredient.itemId", width: "120" },
        { text: "Item Name", value: "ingredient.nameEn", width: "180" },
        { text: "Supplier", value: "description", width: "120" },
        { text: "Quantity", value: "quantity", width: "180" },
        { text: "Unit Price", value: "unitPrice", width: "180" },
        { text: "Total Price", value: "totalPrice", width: "120" },
        { text: "Action", value: "actions", sortable: false, width: "120" },
      ],
      totalItemsCount: 25,
      purchasePrice: 0,
    };
  },
  methods: {
    calculateTotalPrice(item) {
      item.unitPrice = item.unitPrice == "" ? 0 : item.unitPrice;
      item.quantity = item.quantity == "" ? 0 : item.quantity;
      item.totalPrice = parseFloat(item.unitPrice) * parseFloat(item.quantity);
    },
    deleteItem(id) {
      const index = this.purchaseDetails.findIndex(
        (item) => item.ingredient._id === id
      );
      this.purchaseDetails.splice(index, 1);
      // console.log(index);
    },
    totalPurchasePrice() {
      let price = 0.0;
      if (this.purchaseDetails) {
        this.purchaseDetails.map(function (elt) {
          price = price + elt.totalPrice;
        });
      }
      this.purchasePrice = price.toFixed(3);
      return this.purchasePrice;
    },
  },
};
</script>
