const USER_ROLES = [
    {
        title: "Sales",
        menus: [
            {
                name: "Dashboard",
                items: [
                    {
                        title: "Dashboard Permission",
                        data: [
                            {
                                key: "DASHBOARD_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Customer",
                items: [
                    {
                        title: "All Customer Permission",
                        data: [
                            {
                                key: "CUSTOMER_VIEW",
                                name: "Customer View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_ADD",
                                name: "Customer Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_EDIT",
                                name: "Customer Edit",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DELETE",
                                name: "Customer Delete",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_SALES_ORDER_VIEW",
                                name: "Sales Order View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_SALES_ORDER_ADD",
                                name: "Sales Order Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_SALES_ORDER_DELETE",
                                name: "Sales Order Delete",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_SALES_ORDER_UPDATE",
                                name: "Sales Order Update",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_PAUSE_VIEW",
                                name: "Pause View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DISLIKE_VIEW",
                                name: "Dislike View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DISLIKE_ADD",
                                name: "Dislike Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_ALLERGIES_VIEW",
                                name: "Allergies View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_ALLERGIES_ADD",
                                name: "Allergies Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_MEAL_VIEW",
                                name: "Meal Section View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_MEAL_RATING_VIEW",
                                name: "Meal Rating View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DIETITIAN_VIEW",
                                name: "Dietitian View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DIETITIAN_ADD",
                                name: "Dietitian Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DELIVERY_SCHEDULE_VIEW",
                                name: "Delivery Schedule View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_DELIVERY_SCHEDULE_ADD",
                                name: "Delivery Schedule Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_PAYMENT_VIEW",
                                name: "Customer Payment View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_HEALTH_INFO_VIEW",
                                name: "Customer Health Info View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_HEALTH_INFO_ADD",
                                name: "Customer Health Info Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_HEALTH_INFO_EDIT",
                                name: "Customer Health Info Edit",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Inactive Customer Permission",
                        data: [
                            {
                                key: "IN_ACTIVE_CUSTOMER_VIEW",
                                name: "Customer View",
                                value: false,
                            },]
                    },
                    {
                        title: "Customer without Meal Permission",
                        data: [
                            {
                                key: "CUSTOMER_WITHOUT_MEAL_VIEW",
                                name: "Customer Without Meal",
                                value: false,
                            },]
                    },
                    {
                        title: "Payment Option Permission",
                        data: [
                            {
                                key: "PAYMENT_OFFLINE",
                                name: "Payment Offline",
                                value: false
                            },
                            {
                                key: "PAYMENT_ONLINE",
                                name: "Payment Online",
                                value: false
                            },
                            {
                                key: "PAYMENT_FREE",
                                name: "Payment Free",
                                value: false
                            },
                        ]
                    }
                ],
            },
            {
                name: "Sales Order",
                items: [
                    {
                        title: "Sales Order Permission",
                        data: [
                            {
                                key: "SALES_ORDER_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "SALES_ORDER_EXPORT",
                                name: "Export",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Sales Order Day Wise Permission",
                        data: [
                            {
                                key: "SALES_ORDER_DAY_WISE_VIEW",
                                name: "View",
                                value: false,
                            }
                        ],
                    },
                    {
                        title: "End Date Reminder",
                        data: [
                            {
                                key: "END_DATE_REMINDER_VIEW",
                                name: "View",
                                value: false,
                            }
                        ],
                    },
                    {
                        title: "Sales Order Discount",
                        data: [
                            {
                                key: "SALES_ORDER_DISCOUNT",
                                name: "View",
                                value: false,
                            }
                        ],
                    },
                ],
            },
            {
                name: "Payment",
                items: [
                    {
                        title: "Payment Permission",
                        data: [
                            {
                                key: "PAYMENT_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Meals Rating",
                items: [
                    {
                        title: "Meals Rating Permission",
                        data: [
                            {
                                key: "MEALS_RATING_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Push Notification",
                items: [
                    {
                        title: "Push Notification Permission",
                        data: [
                            {
                                key: "PUSH_NOTIFICATION_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "PUSH_NOTIFICATION_SEND",
                                name: "Send",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Schedule Notification",
                items: [
                    {
                        title: "Schedule Notification Permission",
                        data: [
                            {
                                key: "SCHEDULE_NOTIFICATION_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "SCHEDULE_NOTIFICATION_SEND",
                                name: "Send",
                                value: false,
                            },
                            {
                                key: "SCHEDULE_NOTIFICATION_ADD",
                                name: "Send",
                                value: false,
                            },
                            {
                                key: "SCHEDULE_NOTIFICATION_EDIT",
                                name: "Send",
                                value: false,
                            },
                        ],
                    },
                ],
            },
    
        ],
    },
    
    {
        title: "Operations",
        menus: [
            {
                name: "Stocks",
                items: [
                    {
                        title: "Stock Permission",
                        data: [
                            {
                                name: "View",
                                key: "STOCK_VIEW",
                                value: false,
                            },
                            {
                                name: "Inward View",
                                key: "STOCK_INWARD_VIEW",
                                value: false,
                            },
                            {
                                name: "Outward View",
                                key: "STOCK_OUTWARD_VIEW",
                                value: false,
                            },
                            {
                                name: "Current View",
                                key: "STOCK_CURRENT_VIEW",
                                value: false,
                            },

                        ],
                    },
                    {
                        title: "Purchase Permission",
                        data: [
                            {
                                name: "View",
                                key: "PURCHASE_VIEW",
                                value: false,
                            },
                            {
                                name: "Add",
                                key: "PURCHASE_ADD",
                                value: false,
                            },
                            {
                                name: "Submit",
                                key: "PURCHASE_SUBMIT",
                                value: false,
                            }
                        ],
                    },
                    {
                        title: "Adjust Stock Permission",
                        data: [
                            {
                                name: "View",
                                key: "ADJUST_STOCK_VIEW",
                                value: false,
                            },
                            {
                                name: "Adjust",
                                key: "ADJUST_STOCK",
                                value: false,
                            }
                        ],
                    },
                    {
                        title: "Master Data Permission",
                        data: [
                            {
                                name: "View",
                                key: "MASTER_DATA_VIEW",
                                value: false,
                            },
                            {
                                name: "Add",
                                key: "MASTER_DATA_ADD",
                                value: false,
                            },
                            {
                                name: "Edit",
                                key: "MASTER_DATA_EDIT",
                                value: false,
                            },
                            {
                                name: "Delete",
                                key: "MASTER_DATA_DELETE",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Monthly Usage Permission",
                        data: [
                            {
                                name: "View",
                                key: "MONTHLY_USAGE_VIEW",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Monthly Purchase Permission",
                        data: [
                            {
                                name: "View",
                                key: "MONTHLY_PURCHASE_VIEW",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Plans",
                items: [
                    {
                        title: "Plan Permission",
                        data: [
                            {
                                key: "PLAN_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "PLAN_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "PLAN_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "PLAN_DELETE",
                                name: "Delete",
                                value: false,
                            },
                            {
                                key: "PLAN_DUPLICATE",
                                name: "Duplicate",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Meals",
                items: [
                    {
                        title: "Meal Permission",
                        data: [
                            {
                                name: "View Meals",
                                key: "MEAL_VIEW",
                                value: false,
                            },
                            {
                                name: "Add Meals",
                                key: "MEAL_ADD",
                                value: false,
                            },
                            {
                                name: "Edit Meals",
                                key: "MEAL_EDIT",
                                value: false,
                            },
                            {
                                name: "Duplicate Meals",
                                key: "MEAL_DUPLICATE",
                                value: false,
                            },
                            {
                                name: "Delete Meals",
                                key: "MEAL_DELETE",
                                value: false,
                            },
                            {
                                name: "Export Meals",
                                key: "MEAL_EXPORT",
                                value: false,
                            },
                            {
                                name: "Status Change",
                                key: "MEAL_STATUS",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Meals Category",
                items: [
                    {
                        title: "Meals Category Permission",
                        data: [
                            {
                                key: "MEALS_CATEGORY_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "MEALS_CATEGORY_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "MEALS_CATEGORY_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "MEALS_CATEGORY_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Diet Plan",
                items: [
                    {
                        title: "Diet Plan Permission",
                        data: [
                            {
                                key: "DIET_PLAN_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "DIET_PLAN_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "DIET_PLAN_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "DIET_PLAN_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Dietitian Appointments",
                items: [
                    {
                        title: "Dietitian Appointment Permission",
                        data: [
                            {
                                key: "DIETITIAN_APPOINTMENT_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_APPOINTMENT_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_APPOINTMENT_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Universal Pauses",
                items: [
                    {
                        title: "Universal Pauses Permission",
                        data: [
                            {
                                key: "UNIVERSAL_PAUSE_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "UNIVERSAL_PAUSE_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "UNIVERSAL_PAUSE_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Plan Switch",
                items: [
                    {
                        title: "Plan Switch Permission",
                        data: [
                            {
                                key: "PLAN_SWITCH_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "PLAN_SWITCH_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "PLAN_SWITCH_DELETE",
                                name: "Delete",
                                value: false,
                            },
                            {
                                key: "SWITCH_PLAN",
                                name: "Switch Plan",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Users",
                items: [
                    {
                        title: "Manager Permission",
                        data: [
                            {
                                key: "MANAGER_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "MANAGER_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "MANAGER_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "MANAGER_DELETE",
                                name: "Delete",
                                value: false,
                            },
                            {
                                key: "PERMISSION_VIEW",
                                name: "View Permissions",
                                value: false,
                            },
                            {
                                key: "PERMISSION_ADD",
                                name: "Add Permissions",
                                value: false,
                            },
                            {
                                key: "PERMISSION_EDIT",
                                name: "Edit Permissions",
                                value: false,
                            },
                            {
                                key: "PERMISSION_DELETE",
                                name: "Delete Permissions",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Driver Permission",
                        data: [
                            {
                                key: "DRIVER_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "DRIVER_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "DRIVER_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "DRIVER_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Dietitian Permission",
                        data: [
                            {
                                key: "DIETITIAN_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_DELETE",
                                name: "Delete",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_SETTINGS",
                                name: "Settings",
                                value: false,
                            },
                            {
                                key: "DIETITIAN_OFF_DAY",
                                name: "Off Days",
                                value: false,
                            },
                        ],
                    },
                    // {
                    //     title: "Celebrity Permission",
                    //     data: [
                    //         {
                    //             key: "CELEBRITY_VIEW",
                    //             name: "View",
                    //             value: false,
                    //         },
                    //         {
                    //             key: "CELEBRITY_ADD",
                    //             name: "Add",
                    //             value: false,
                    //         },
                    //         {
                    //             key: "CELEBRITY_EDIT",
                    //             name: "Edit",
                    //             value: false,
                    //         },
                    //         {
                    //             key: "CELEBRITY_DELETE",
                    //             name: "Delete",
                    //             value: false,
                    //         },
                    //     ],
                    // },
                ],
            },
            {
                name: "Location",
                items: [
                    {
                        title: "Location Permission",
                        data: [
                            {
                                key: "LOCATION_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "LOCATION_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "LOCATION_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "LOCATION_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "E-Clinic",
                items: [
                    {
                        title: "Activity Permission",
                        data: [
                            {
                                key: "ACTIVITY_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "ACTIVITY_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "ACTIVITY_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "ACTIVITY_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Health Issue Permission",
                        data: [
                            {
                                key: "HEALTH_ISSUE_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "HEALTH_ISSUE_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "HEALTH_ISSUE_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "HEALTH_ISSUE_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Tags",
                items: [
                    {
                        title: "Customer Tag Permission",
                        data: [
                            {
                                key: "CUSTOMER_TAG_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_TAG_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_TAG_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "CUSTOMER_TAG_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Meal Tag Permission",
                        data: [
                            {
                                key: "MEAL_TAG_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "MEAL_TAG_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "MEAL_TAG_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "MEAL_TAG_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Meal Portion Permission",
                        data: [
                            {
                                key: "MEAL_PORTION_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "MEAL_PORTION_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "MEAL_PORTION_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "MEAL_PORTION_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Referrals",
                items: [
                    {
                        title: "Referrals Permission",
                        data: [
                            {
                                key: "REFERRAL_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "REFERRAL_SETTINGS",
                                name: "Referral Settings",
                                value: false,
                            },

                        ],
                    },
                ],
            },
            {
                name: "Faq",
                items: [
                    {
                        title: "Faq Permission",
                        data: [
                            {
                                key: "FAQ_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "FAQ_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "FAQ_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "FAQ_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Contact Method",
                items: [
                    {
                        title: "Contact Permission",
                        data: [
                            {
                                key: "CONTACT_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "CONTACT_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "CONTACT_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "CONTACT_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Promo Code",
                items: [
                    {
                        title: "Promocode Permission",
                        data: [
                            {
                                key: "PROMO_CODE_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "PROMO_CODE_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "PROMO_CODE_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "PROMO_CODE_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Offers",
                items: [
                    {
                        title: "Offer Permission",
                        data: [
                            {
                                key: "OFFER_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "OFFER_ADD",
                                name: "Add",
                                value: false,
                            },
                            {
                                key: "OFFER_EDIT",
                                name: "Edit",
                                value: false,
                            },
                            {
                                key: "OFFER_DELETE",
                                name: "Delete",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            // {
            //     name: "Settings",
            //     items: [
            //         {
            //             title: "Settings Permission",
            //             data: [
            //                 {
            //                     key: "SETTINGS_VIEW",
            //                     name: "View and Modify",
            //                     value: false,
            //                 },

            //             ],
            //         },
            //     ],
            // },
        ],
    },
    {
        title: "Reports",
        menus: [
            {
                name: "Generations",
                items: [
                    {
                        title: "Ordered Items Permission",
                        data: [
                            {
                                key: "ORDER_ITEM_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "GENERATE_ITEMS",
                                name: "Generate",
                                value: false,
                            },
                        ],
                    },
                    {
                        title: "Ingredient Permission",
                        data: [
                            {
                                key: "INGREDIENT_VIEW",
                                name: "View",
                                value: false,
                            },

                        ],
                    },
                    {
                        title: "Delivery Report Permission",
                        data: [
                            {
                                key: "DELIVERY_REPORT_VIEW",
                                name: "View",
                                value: false,
                            }
                        ],
                    },
                    {
                        title: "Quick View Delivery Report Permission",
                        data: [
                            {
                                key: "QUICK_DELIVERY_REPORT_VIEW",
                                name: "View",
                                value: false,
                            },
                            {
                                key: "QUICK_VIEW_GENERATE",
                                name: "Generate",
                                value: false,
                            }
                        ],
                    },
                ],
            },
            {
                name: "Sales",
                items: [
                    {
                        title: "Sales Report Permission",
                        data: [
                            {
                                key: "SALES_REPORT_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Driver",
                items: [
                    {
                        title: "Driver Report Permission",
                        data: [
                            {
                                key: "DRIVER_REPORT_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "PromoCode",
                items: [
                    {
                        title: "PromoCode Report Permission",
                        data: [
                            {
                                key: "PROMO_CODE_REPORT_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Paused Meals",
                items: [
                    {
                        title: "Paused Meals Report Permission",
                        data: [
                            {
                                key: "PAUSED_MEALS_REPORT_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Monthly Sales",
                items: [
                    {
                        title: "Monthly Sales Report Permission",
                        data: [
                            {
                                key: "MONTHLY_SALES_VIEW",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Dietitian Appointments",
                items: [
                    {
                        title: "Dietitian Appointments Report Permission",
                        data: [
                            {
                                key: "DIETITIAN_APPOINTMENTS_REPORT",
                                name: "View",
                                value: false,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: "Settings",
        menus: [
            {
                name: "Settings",
                items: [
                    {
                        title: "Settings Permission",
                        data: [
                            {
                                key: "SETTINGS_VIEW",
                                name: "View and Modify",
                                value: false,
                            },

                        ],
                    },
                ],
            },
        ]
    },

]
const AdminUserPermissions = {
    DASHBOARD_VIEW: "DASHBOARD_VIEW",
    CUSTOMER_VIEW: "CUSTOMER_VIEW",
    CUSTOMER_ADD: "CUSTOMER_ADD",
    CUSTOMER_EDIT: "CUSTOMER_EDIT",
    CUSTOMER_DELETE: "CUSTOMER_DELETE",
    IN_ACTIVE_CUSTOMER_VIEW: "IN_ACTIVE_CUSTOMER_VIEW",
    CUSTOMER_SALES_ORDER_VIEW: "CUSTOMER_SALES_ORDER_VIEW",
    CUSTOMER_SALES_ORDER_PENDING_PAYMENT_VIEW:"CUSTOMER_SALES_ORDER_PENDING_PAYMENT_VIEW",
    CUSTOMER_SALES_ORDER_ADD: "CUSTOMER_SALES_ORDER_ADD",
    CUSTOMER_SALES_ORDER_UPDATE: "CUSTOMER_SALES_ORDER_UPDATE",
    CUSTOMER_SALES_ORDER_DELETE: "CUSTOMER_SALES_ORDER_DELETE",
    CUSTOMER_PAUSE_VIEW: "CUSTOMER_PAUSE_VIEW",
    CUSTOMER_DISLIKE_VIEW: "CUSTOMER_DISLIKE_VIEW",
    CUSTOMER_DISLIKE_ADD: "CUSTOMER_DISLIKE_ADD",
    CUSTOMER_ALLERGIES_VIEW: "CUSTOMER_ALLERGIES_VIEW",
    CUSTOMER_ALLERGIES_ADD: "CUSTOMER_ALLERGIES_ADD",
    CUSTOMER_MEAL_VIEW: "CUSTOMER_MEAL_VIEW",
    PAYMENT_VIEW: "PAYMENT_VIEW",
    CUSTOMER_MEAL_RATING_VIEW: "CUSTOMER_MEAL_RATING_VIEW",
    CUSTOMER_DIETITIAN_VIEW: "CUSTOMER_DIETITIAN_VIEW",
    CUSTOMER_DIETITIAN_ADD: "CUSTOMER_DIETITIAN_ADD",
    CUSTOMER_DELIVERY_SCHEDULE_VIEW: "CUSTOMER_DELIVERY_SCHEDULE_VIEW",
    CUSTOMER_DELIVERY_SCHEDULE_ADD: "CUSTOMER_DELIVERY_SCHEDULE_ADD",
    PAYMENT_OFFLINE: "PAYMENT_OFFLINE",
    PAYMENT_ONLINE: "PAYMENT_ONLINE",
    PAYMENT_FREE: "PAYMENT_FREE",
    SALES_ORDER_VIEW: "SALES_ORDER_VIEW",
    // SALES_ORDER_EXPORT: "SALES_ORDER_EXPORT",
    SALES_ORDER_DAY_WISE_VIEW: "SALES_ORDER_DAY_WISE_VIEW",
    END_DATE_REMINDER_VIEW: "END_DATE_REMINDER_VIEW",
    SALES_ORDER_DISCOUNT: "SALES_ORDER_DISCOUNT",
    MEALS_RATING_VIEW: "MEALS_RATING_VIEW",
    PUSH_NOTIFICATION_VIEW: "PUSH_NOTIFICATION_VIEW",
    PUSH_NOTIFICATION_SEND: "PUSH_NOTIFICATION_SEND",
    SCHEDULE_NOTIFICATION_VIEW: "SCHEDULE_NOTIFICATION_VIEW",
    SCHEDULE_NOTIFICATION_SEND: "SCHEDULE_NOTIFICATION_SEND",
    SCHEDULE_NOTIFICATION_ADD: "SCHEDULE_NOTIFICATION_ADD",
    SCHEDULE_NOTIFICATION_EDIT: "SCHEDULE_NOTIFICATION_EDIT",
    
    DIETITIAN_APPOINTMENT_VIEW: "DIETITIAN_APPOINTMENT_VIEW",
    DIETITIAN_APPOINTMENT_EDIT: "DIETITIAN_APPOINTMENT_EDIT",
    DIETITIAN_APPOINTMENT_DELETE: "DIETITIAN_APPOINTMENT_DELETE",
    STOCK_VIEW: "STOCK_VIEW",
    STOCK_INWARD_VIEW: "STOCK_INWARD_VIEW",
    STOCK_OUTWARD_VIEW: "STOCK_OUTWARD_VIEW",
    STOCK_CURRENT_VIEW: "STOCK_CURRENT_VIEW",
    PURCHASE_VIEW: "PURCHASE_VIEW",
    PURCHASE_ADD: "PURCHASE_ADD",
    PURCHASE_SUBMIT: "PURCHASE_SUBMIT",
    ADJUST_STOCK_VIEW: "ADJUST_STOCK_VIEW",
    ADJUST_STOCK: "ADJUST_STOCK",
    MASTER_DATA_VIEW: "MASTER_DATA_VIEW",
    MASTER_DATA_ADD: "MASTER_DATA_ADD",
    MASTER_DATA_EDIT: "MASTER_DATA_EDIT",
    MASTER_DATA_DELETE: "MASTER_DATA_DELETE",
    MONTHLY_USAGE_VIEW:"MONTHLY_USAGE_VIEW",
    MONTHLY_PURCHASE_VIEW:"MONTHLY_PURCHASE_VIEW",
    PLAN_VIEW: "PLAN_VIEW",
    PLAN_ADD: "PLAN_ADD",
    PLAN_EDIT: "PLAN_EDIT",
    PLAN_DELETE: "PLAN_DELETE",
    MEAL_VIEW: "MEAL_VIEW",
    MEAL_ADD: "MEAL_ADD",
    MEAL_EDIT: "MEAL_EDIT",
    MEAL_DELETE: "MEAL_DELETE",
    MEAL_EXPORT: "MEAL_EXPORT",
    MEAL_STATUS: "MEAL_STATUS",
    MEALS_CATEGORY_VIEW: "MEALS_CATEGORY_VIEW",
    MEALS_CATEGORY_ADD: "MEALS_CATEGORY_ADD",
    MEALS_CATEGORY_EDIT: "MEALS_CATEGORY_EDIT",
    MEALS_CATEGORY_DELETE: "MEALS_CATEGORY_DELETE",
    DIET_PLAN_VIEW: "DIET_PLAN_VIEW",
    DIET_PLAN_ADD: "DIET_PLAN_ADD",
    DIET_PLAN_EDIT: "DIET_PLAN_EDIT",
    DIET_PLAN_DELETE: "DIET_PLAN_DELETE",
    UNIVERSAL_PAUSE_VIEW: "UNIVERSAL_PAUSE_VIEW",
    UNIVERSAL_PAUSE_ADD: "UNIVERSAL_PAUSE_ADD",
    UNIVERSAL_PAUSE_DELETE: "UNIVERSAL_PAUSE_DELETE",
    PLAN_SWITCH_VIEW: "PLAN_SWITCH_VIEW",
    PLAN_SWITCH_ADD: "PLAN_SWITCH_ADD",
    PLAN_SWITCH_DELETE: "PLAN_SWITCH_DELETE",
    SWITCH_PLAN: "SWITCH_PLAN",
    MANAGER_VIEW: "MANAGER_VIEW",
    MANAGER_ADD: "MANAGER_ADD",
    MANAGER_EDIT: "MANAGER_EDIT",
    MANAGER_DELETE: "MANAGER_DELETE",
    PERMISSION_VIEW: "PERMISSION_VIEW",
    PERMISSION_ADD: "PERMISSION_ADD",
    PERMISSION_EDIT: "PERMISSION_EDIT",
    PERMISSION_DELETE: "PERMISSION_DELETE",
    DRIVER_VIEW: "DRIVER_VIEW",
    DRIVER_ADD: "DRIVER_ADD",
    DRIVER_EDIT: "DRIVER_EDIT",
    DRIVER_DELETE: "DRIVER_DELETE",
    DIETITIAN_VIEW: "DIETITIAN_VIEW",
    DIETITIAN_ADD: "DIETITIAN_ADD",
    DIETITIAN_EDIT: "DIETITIAN_EDIT",
    DIETITIAN_DELETE: "DIETITIAN_DELETE",
    DIETITIAN_SETTINGS: "DIETITIAN_SETTINGS",
    DIETITIAN_OFF_DAY: "DIETITIAN_OFF_DAY",
    CELEBRITY_VIEW: "CELEBRITY_VIEW",
    CELEBRITY_ADD: "CELEBRITY_ADD",
    CELEBRITY_EDIT: "CELEBRITY_EDIT",
    CELEBRITY_DELETE: "CELEBRITY_DELETE",
    LOCATION_VIEW: "LOCATION_VIEW",
    LOCATION_ADD: "LOCATION_ADD",
    LOCATION_EDIT: "LOCATION_EDIT",
    LOCATION_DELETE: "LOCATION_DELETE",
    CUSTOMER_TAG_VIEW: "CUSTOMER_TAG_VIEW",
    CUSTOMER_TAG_ADD: "CUSTOMER_TAG_ADD",
    CUSTOMER_TAG_EDIT: "CUSTOMER_TAG_EDIT",
    CUSTOMER_TAG_DELETE: "CUSTOMER_TAG_DELETE",
    MEAL_TAG_VIEW: "MEAL_TAG_VIEW",
    MEAL_TAG_ADD: "MEAL_TAG_ADD",
    MEAL_TAG_EDIT: "MEAL_TAG_EDIT",
    MEAL_TAG_DELETE: "MEAL_TAG_DELETE",
    MEAL_PORTION_VIEW: "MEAL_PORTION_VIEW",
    MEAL_PORTION_ADD: "MEAL_PORTION_ADD",
    MEAL_PORTION_EDIT: "MEAL_PORTION_EDIT",
    MEAL_PORTION_DELETE: "MEAL_PORTION_DELETE",
    ACTIVITY_VIEW: "ACTIVITY_VIEW",
    ACTIVITY_ADD: "ACTIVITY_ADD",
    ACTIVITY_EDIT: "ACTIVITY_EDIT",
    ACTIVITY_DELETE: "ACTIVITY_DELETE",
    HEALTH_ISSUE_VIEW: "HEALTH_ISSUE_VIEW",
    HEALTH_ISSUE_ADD: "HEALTH_ISSUE_ADD",
    HEALTH_ISSUE_EDIT: "HEALTH_ISSUE_EDIT",
    HEALTH_ISSUE_DELETE: "HEALTH_ISSUE_DELETE",
    REFERRAL_VIEW: "REFERRAL_VIEW",
    REFERRAL_SETTINGS: "REFERRAL_SETTINGS",
    FAQ_VIEW: "FAQ_VIEW",
    FAQ_ADD: "FAQ_ADD",
    FAQ_EDIT: "FAQ_EDIT",
    FAQ_DELETE: "FAQ_DELETE",
    CONTACT_VIEW: "CONTACT_VIEW",
    CONTACT_ADD: "CONTACT_ADD",
    CONTACT_EDIT: "CONTACT_EDIT",
    CONTACT_DELETE: "CONTACT_DELETE",
    PROMO_CODE_VIEW: "PROMO_CODE_VIEW",
    PROMO_CODE_ADD: "PROMO_CODE_ADD",
    PROMO_CODE_EDIT: "PROMO_CODE_EDIT",
    PROMO_CODE_DELETE: "PROMO_CODE_DELETE",
    OFFER_VIEW: "OFFER_VIEW",
    OFFER_ADD: "OFFER_ADD",
    OFFER_EDIT: "OFFER_EDIT",
    OFFER_DELETE: "OFFER_DELETE",
    SETTINGS_VIEW: "SETTINGS_VIEW",
    ORDER_ITEM_VIEW: "ORDER_ITEM_VIEW",
    GENERATE_ITEMS: "GENERATE_ITEMS",
    INGREDIENT_VIEW: "INGREDIENT_VIEW",
    MEALS_VIEW: "MEALS_VIEW",
    DELIVERY_REPORT_VIEW: "DELIVERY_REPORT_VIEW",
    QUICK_DELIVERY_REPORT_VIEW: "QUICK_DELIVERY_REPORT_VIEW",
    QUICK_VIEW_GENERATE: "QUICK_VIEW_GENERATE",
    SALES_REPORT_VIEW: "SALES_REPORT_VIEW",
    DRIVER_REPORT_VIEW: "DRIVER_REPORT_VIEW",
    PROMO_CODE_REPORT_VIEW: "PROMO_CODE_REPORT_VIEW",
    PAUSED_MEALS_REPORT_VIEW: "PAUSED_MEALS_REPORT_VIEW",
    CUSTOMER_PAYMENT_VIEW: "CUSTOMER_PAYMENT_VIEW",
    MONTHLY_SALES_VIEW: "MONTHLY_SALES_VIEW",
    CUSTOMER_WITHOUT_MEAL_VIEW: "CUSTOMER_WITHOUT_MEAL_VIEW",
    CUSTOMER_HEALTH_INFO_VIEW: "CUSTOMER_HEALTH_INFO_VIEW",
    CUSTOMER_HEALTH_INFO_ADD: "CUSTOMER_HEALTH_INFO_ADD",
    CUSTOMER_HEALTH_INFO_EDIT: "CUSTOMER_HEALTH_INFO_EDIT",
    DIETITIAN_APPOINTMENTS_REPORT: "DIETITIAN_APPOINTMENTS_REPORT",
    // DIET_PLAN_VIEW: "DIET_PLAN_VIEW",
    // DIET_PLAN_ADD: "DIET_PLAN_ADD",
    // DIET_PLAN_EDIT: "DIET_PLAN_EDIT",
    // DIET_PLAN_DELETE: "DIET_PLAN_DELETE",
}
export { USER_ROLES, AdminUserPermissions }
export class UserRoleSettings {
    hasRole(role) {
        let userRoles = localStorage.getItem("DONE_USER_ROLE_PERMISSIONS")
        let isAdmin = localStorage.getItem("DONE_USER_ROLE") == "ADMIN" ? true : false;
        // let isDietition = localStorage.getItem("DONE_USER_ROLE") == "DIETITIAN" ? true : false;

        // if (isDietition) {
        //     if (localStorage.getItem("DONE_USER_ROLE") == "DIETITIAN") {
        //         if (role == AdminUserPermissions.CUSTOMER_SALES_ORDER_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.CUSTOMER_DISLIKE_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.CUSTOMER_ALLERGIES_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.CUSTOMER_MEAL_RATING_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.CUSTOMER_DIETITIAN_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.CUSTOMER_DELIVERY_SCHEDULE_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.CUSTOMER_PAYMENT_VIEW) {
        //             return true
        //         }
        //         else if (role == AdminUserPermissions.DIETITIAN_APPOINTMENT_VIEW) {
        //             return true
        //         }
        //          else {
        //             return false
        //         }
        //     }
        // }

        if (userRoles) {
            let parsedRoles = JSON.parse(userRoles);
            if (parsedRoles.includes(role)) {
                return true;
            }
        }
        return isAdmin;
    }
}