<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout wrap class="d-flex">
      <v-flex>
        <h2 class="black--text text-h5 font-weight-bold">Shopping</h2>
      </v-flex>
    </v-layout>
    <OrdersDataTable />
  </v-container>
</template>

<script>
import OrdersDataTable from "../components/OrdersDataTable";
export default {
  components: {
    OrdersDataTable,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {
    if (!this.hasRole("ALL") && !this.hasRole(this.AdminUserPermissions.Orders)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },
};
</script>
<style scoped>
>>> .v-input__control {
  width: 0;
}
</style>
