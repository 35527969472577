var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('h2',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v("Meals Rating")])]),_c('v-data-table',{staticClass:"mt-6 elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.mealRatings,"items-per-page":10,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"small":"","color":"yellow","length":"5","size":"64","value":item.rating,"readonly":true}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.date)),"MMM DD YYYY"))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('div',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn)+" "+_vm._s(item.customer.user.lastnameEn)+" ")]):_vm._e()]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('div',[(item.customer)?_c('div',[(item.customer.user)?_c('div',[(!item.customer.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
                name: 'CustomerDetails',
                params: { customerId: item.customer._id },
                query: { customerId: item.customer._id }
              }}},[(item.customer)?_c('div',[(item.customer.user)?_c('div',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]):_vm._e()]):_c('div',[(item.customer)?_c('div',[(item.customer.user)?_c('div',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]):_vm._e()])],1):_vm._e()]):_vm._e()]):_vm._e()]}},{key:"item.phone",fn:function(ref){
              var item = ref.item;
return [(item.customer)?_c('div',[_vm._v(" "+_vm._s(item.customer.user.phone)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdAt)),"MMM DD YYYY"))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }