<template>
  <v-container>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-if="hasRole(AdminUserPermissions.CUSTOMER_HEALTH_INFO_EDIT)" v-on="on" class="me-1" small>mdi-pencil
        </v-icon>
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Add Category</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createFaq()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Category English</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field solo name="input-7-4" label="Category (English)" v-model="category.nameEn"
                    :rules="[requiredValidator('category')]"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Category Arabic</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field solo name="input-7-4" label="Category (Arabic)" v-model="category.nameAr"
                    :rules="[requiredValidator('category')]"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Active</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-radio-group v-model="category.status" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field :rules="[requiredValidator('order')]" solo dense @keypress="isNumber" label="Show Order"
                    type="number" v-model="category.order" min="0"></v-text-field>
                </v-flex>
              </v-layout>
              <div>
                <ProductCategoryDiscount :itemDiscount="itemDiscount" class="mt-4" type="product" />
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-end">
          <v-btn color="secondary" class="black--text mb-2 mb-sm-4 text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90" @click="updateProductCategory" :loading="btnloading"
            :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{ text }}</v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import ProductCategoryDiscount from "@/discount/components/ProductCategoryDiscount";
import moment from "moment";
export default {
  components: { ProductCategoryDiscount },
  props: {
    category: {
      required: true,
    },
  },

  methods: {
    getDiscountByItem() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getDiscountByItem.graphql"),
          variables: {
            category: this.category._id,
          },
        })
        .refetch({
          variables: {
            category: this.category._id,
          },
        })
        .then(data => {
          this.loading = false;
          if (data.data.getDiscountByItem) {
            let discount = data.data.getDiscountByItem;
            this.itemDiscount = {
              enableDiscount: true,
              discount: discount.discount,
              inPercentage: discount.inPercentage,
              maxDiscountAmount: discount.maxDiscountAmount,
              startDate: moment(
                new Date(parseInt(discount.startDate))
              ).format("YYYY-MM-DD"),
              endDate: moment(
                new Date(parseInt(discount.endDate))
              ).format("YYYY-MM-DD"),
            }
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    updateProductCategory() {
      this.btnloading = true;

      let discount = null;

      if (this.itemDiscount.enableDiscount) {

        discount = {
          startDate: this.itemDiscount.startDate,
          endDate: this.itemDiscount.endDate,
          discount: parseFloat(this.itemDiscount.discount),
          inPercentage: this.itemDiscount.inPercentage,
          maxDiscountAmount: parseFloat(this.itemDiscount.maxDiscountAmount),
        }
      }
      this.$apollo
        .mutate({
          mutation: require("../api/updateProductCategory.graphql"),
          variables: {
            id: this.category._id,
            input: {
              nameEn: this.category.nameEn,
              nameAr: this.category.nameAr,
              status: this.category.status,
              order: parseInt(this.category.order),
              discount: discount
            },
          },
          update: () => {
            try {
              this.text = "Product Category Updated";
              this.snackbar = true;
              this.btnloading = false;
              this.show_dialog_box = false;
            } catch (e) {
              console.log("Something bad happend- FaqForm.vue");
              console.log(e);
              this.btnloading = false;
            }

            this.show_dialog_box = false;
            this.btnloading = false;
          },
        })

        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.show_dialog_box = false;
        });
    },
  },
  data: () => ({
    loading: true,
    valid: false,
    requiredValidator,
    snackbar: false,
    text: "",
    btnloading: false,
    show_dialog_box: false,
    itemDiscount: {
      enableDiscount: false,
      discount: 0,
      inPercentage: false,
      maxDiscountAmount: 0,
      startDate: "",
      endDate: ""
    }
  }),

  created() {
    this.getDiscountByItem();
  },
};
</script>
