<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="black--text text-capitalize" color="secondary">
          <v-icon small class="me-1">mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <v-card class="" color="pop_bg">
        <v-card-title class="ps-8 pe-3 py-5">
          <span class="text-h6 black--text">Add Promo Code </span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
        class="px-3"
          @submit="createPromoCode()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Promo Code</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="promoCode"
                    @keyup="onPromoChange"
                    @click="doesPromoCodeExist = false"
                    :rules="[
                      requiredValidator('promo code'),
                      this.doesPromoCodeExist != true ||
                        'promo code already exists.'
                    ]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mt-2 ms-md-16 pe-md-4">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Currently Active</h4>
                </v-flex>
                <v-flex xs1 sm2 md1 class="mt-n3">
                  <v-checkbox v-model="isActive"></v-checkbox>
                </v-flex>
                <v-flex xs6 sm4 md2 class="mt-2 ms-2 ms-sm-n16 ms-md-n6">
                  <h4 class="font-weight-medium">isActive</h4>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-4">
                <v-flex xs12 sm4 md2 class="mt-n2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Discount</h4>
                </v-flex>
                <v-flex xs12 sm8 md3 class="mt-sm-n4">
                  <v-text-field
                    solo
                    dense
                    v-model="discount"
                    :append-icon="isPercentage == true ? 'mdi-percent' : ''"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('discount')]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mt-2 mt-md-n2 ms-md-16">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Discount Type</h4>
                </v-flex>
                <v-flex xs12 sm5 md3 class="mt-md-n6">
                  <v-radio-group
                    v-model="isPercentage"
                    :mandatory="true"
                    column
                  >
                    <v-radio label="Fixed Amount" :value="false"></v-radio>

                    <v-radio label="Percentage" :value="true"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout> </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-1">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Total Usage Count</h4>
                </v-flex>
                <v-flex xs12 sm8 md3 class="mt-1">
                  <v-text-field
                    solo
                    dense
                    v-model="usageCount"
                    :rules="totalUsageCountRule"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="mt-1 ms-md-16" v-if="isPercentage">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">
                    Maximum Discount Amount
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md3 class="mt-1" v-if="isPercentage">
                  <v-text-field
                    solo
                    dense
                    v-model="maxDiscountAmount"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('discount')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Start Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        dense
                        :value="formatStartDate"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        :rules="[requiredValidator('start date')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="startDate"
                      @input="menuStartDate = false"
                      :min="moment().format('YYYY-MM-DD')"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs12 sm4 md2 class="ms-md-16 mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">End Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        dense
                        :value="formatEndDate"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        :rules="[requiredValidator('end date')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="endDate"
                      @input="menuEndDate = false"
                      :min="moment().format('YYYY-MM-DD')"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Title (English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="titleEn"
                    :rules="[requiredValidator('title (english)')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md2 class="ms-md-16 mt-2">
                  <h4 class="mb-2 mb-sm-0 text-body-2 black--text">Title (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field
                    v-model="titleAr"
                    :rules="[requiredValidator('title (arabic)')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout justify-center class="mt-8 mb-5 text-end">
                <AssignPackages @addNewPackage="addNewPackage" />
              </v-layout>

              <v-data-table
                v-if="planChoices.length > 0"
                :mobile-breakpoint="0"
                hide-default-footer
                :items-per-page="-1"
                :headers="headers"
                :items="packagesWithSno"
                class="elevation-1 mt-6 mb-5"
              >
                <template v-slot:item.choices="{ item }">
                  <div v-if="item.choices">
                    <div v-for="choice in item.choices" :key="choice._id">
                      {{ choice.choiceNameEn }}
                    </div>
                  </div>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon color="red" @click="removePackage(item)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions class="pe-8 pb-5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="black--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createPromoCode"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
          <!-- {{ isPercentage }} -->
        </v-form>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
        </v-snackbar>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </v-container>
</template>

<script>
import {
  requiredValidator,
  percentageValidator
} from "../../core/methods/validations";
import { DATE_FORMAT } from "../../utils/utils";
import moment from "moment";
import AssignPackages from "./AssignPackages.vue";
export default {
  components: {
    AssignPackages
  },
  data() {
    return {
      moment:moment,
      requiredValidator: requiredValidator,
      percentageValidator,
      headers: [
        {
          text: "Sl.No",
          value: "sno"
        },
        {
          text: "Package",
          value: "plan.titleEn"
        },
        {
          text: "Choice",
          value: "choices"
        },
        {
          text: "Actions",
          value: "action"
        }
      ],
      dialog: false,
      isPercentage: false,
      discount: "",
      maxDiscountAmount: 0,
      btnloading: false,
      nowDate: new Date(),
      isActive: true,
      usageCount: "",
      promoCode: "",
      doesPromoCodeExist: false,
      titleAr: "",
      titleEn: "",
      snackbar: false,
      text: "",
      valid: true,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      selectPackage: "",
      packagesList: [],
      variables: {
        limit: 25,
        skip: 0
      },
      choices: [],
      isNewPlan: false,
      planChoices: [],
      snack: false,
      snackColor: "",
      snackText: "",
      enableAddBtn: true,
      totalUsageCountRule: [
        v => v.length > 0 || "Total usage count is required",
        v => v >= 0 || "Enter a positive No"
      ]
    };
  },
  apollo: {
    getAllPackagesAllInfo() {
      return {
        query: require("@/menu-packages/api/getAllPackagesAllInfo.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.packagesList = data.getAllPackages.packages.filter(
            x => x.active
          );
        }
      };
    }
  },
  methods: {
    addNewPackage(packageAssign) {
      if (this.planChoices.find(x => x.plan._id == packageAssign.plan._id)) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Package already assigned.";
      } else {
        this.planChoices.push(packageAssign);
      }
    },
    removePackage(packageToRemove) {
      //  debugger;
      this.planChoices = this.planChoices.filter(
        x => x.plan._id != packageToRemove.plan._id
      );
    },

    createPromoCode() {
      let minimumChoiceAmount = 0;
      let assignedPackages = new Array();
      // debugger;
      if (this.planChoices && this.planChoices.length > 0) {
        this.planChoices.forEach(packageInfo => {
          let packageAssign = {
            plan: packageInfo.plan._id,
            choices: []
          };
          let assignedChoices = new Array();
          // debugger;
          if (packageInfo.choices && packageInfo.choices.length > 0) {
            packageInfo.choices.forEach(choice => {
              assignedChoices.push(choice._id);
              minimumChoiceAmount = Math.min.apply(
                null,
                choice.mealChoice.map(function(item) {
                  return item.price;
                })
              );
            });
            packageAssign.choices = assignedChoices;
          } else {
            packageInfo.plan.availableChoices.forEach(choice => {
              minimumChoiceAmount = Math.min.apply(
                null,
                choice.mealChoice.map(function(item) {
                  return item.price;
                })
              );
            });
          }

          assignedPackages.push(packageAssign);
        });

        if (!this.isPercentage) {
          if (parseFloat(this.discount) >= minimumChoiceAmount) {
            this.snack = true;
            this.snackColor = "error";
            this.snackText =
              "Discount amount should be less than the package choice amount.";
            return false;
          }
        }
        this.btnloading = true;
      }
      this.$apollo
        .mutate({
          mutation: require("../api/createPromoCode.graphql"),
          variables: {
            promoCode: this.promoCode,
            startDate: this.startDate,
            endDate: this.endDate,
            titleEn: this.titleEn,
            titleAr: this.titleAr,
            isActive: this.isActive,
            discount: parseFloat(this.discount),
            inPercentage: this.isPercentage,
            planChoices: assignedPackages,
            maxDiscountAmount: parseFloat(this.maxDiscountAmount),
            usageCount: parseFloat(this.usageCount)
          }
        })
        .then(() => {
          this.$root.$emit("updatePromoCode");
          this.btnloading = false;
          this.dialog = false;
          this.planChoices = [];
          this.isNewPlan = false;
          this.$refs.form.reset();
          this.isActive = false;
          this.text = "Promo Code Added";
          this.snackbar = true;
          this.startDate = "";
          this.endDate = "";
        })
        .catch(error => {
          this.btnloading = false;
          if (error.graphQLErrors != null) {
            // debugger;
            if (error.message === "GraphQL error: ALREADY_EXIST") {
              this.doesPromoCodeExist = true;
            } else {
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          }
        });
    },

    close() {
      this.dialog = false;
    },
    onPromoChange() {
      // debugger;
      this.promoCode = this.promoCode.toUpperCase();
    }
  },

  computed: {
    formatStartDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    },
    formatEndDate() {
      return this.endDate ? moment(this.endDate).format(DATE_FORMAT) : "";
    },
    packagesWithSno() {
      return this.planChoices.map((d, index) => ({ ...d, sno: index + 1 }));
    }
  }
};
</script>
