<template>
  <v-container>
    <v-row>
      <v-btn
        class="black--text text-capitalize ms-sm-4 mb-4 mt-3"
        color="secondary"
        @click.stop="onAddClick()"
        :disabled="checkActivePackage()"
      >
        <v-icon class="me-1" small>mdi-plus</v-icon>Add
      </v-btn>

      <h5 v-if="checkActivePackage()" class="red--text ml-2">
        No active package !!!
      </h5>
    </v-row>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="pa-8" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n4"
            >Pause a Date
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab> Single day </v-tab>
          <v-tab> Freeze Plan </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item key="singleDay">
            <v-row class="mt-4">
              <v-col cols="12" md="10" class="mx-auto">
                <v-row justify="center">
                  <vc-date-picker
                    :key="number"
                    :min-date="minDate"
                    :max-date="maxDate"
                    v-model="pausedDate"
                    :disabled-dates="disabledDates"
                  />
                </v-row>
                <v-row justify="end" class="mt-6">
                  <v-form ref="form" v-model="valid">
                    <v-text-field
                      v-model="pausedDate"
                      v-show="false"
                      :rules="[requiredValidator('payable amount')]"
                      solo
                      dense
                    ></v-text-field>
                    <v-btn
                      :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
                      class="black--text me-3 text-capitalize mb-4"
                      color="secondary"
                      @click="saveDate()"
                      :disabled="!valid"
                      :loading="btnloading"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Pause
                    </v-btn>
                  </v-form>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="multiple day">
            <CustomerAddPauseDateMultiple
              :pausedDates="pausedDates"
              :subscribedPackage="subscribedPackage"
              @updatePausedDates="updatePausedDates"
              :closeDialog="close"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import VCalendar from "v-calendar";
import { tz } from "moment-timezone";
import moment from "moment";
import { TIME_ZONE, Utils } from "../../../utils/utils";
import { requiredValidator } from "@/core/methods/validations";
import CustomerAddPauseDateMultiple from "./CustomerAddPauseDateMultiple.vue";
Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});
export default Vue.extend({
  props: {
    pausedDates: {
      require: true
    },
    subscribedPackage: {
      require: true
    }
  },
  components: {
    CustomerAddPauseDateMultiple
  },
  watch: {
    pausedDates: {
      handler(newVal) {
        this.setUnavailableDates();
        // // debugger;
        newVal.forEach(element => {
          let paused = {
            start: new Date(
              moment(element.date)
                .add(3, "h")
                .toDate()
            ),
            end: new Date(
              moment(element.date)
                .add(3, "h")
                .toDate()
            )
          };
          this.disabledDates.push(paused);
        });
      },
      deep: true
    },
    subscribedPackage: {
      handler() {
        this.maxDate = new Date(
          moment(new Date(parseInt(this.subscribedPackage.end_date)))
            .add(3, "h")
            .toDate()
        );

        this.minDate = new Date(
          moment(new Date(parseInt(this.subscribedPackage.start_date)))
            .add(3, "h")
            .toDate()
        );
        this.number = this.number + 1;
      },
      deep: true
    }
  },
  created() {
    this.setUnavailableDates();
    this.pausedDates.forEach(element => {
      let paused = {
        start: new Date(
          moment(element.date)
            .add(3, "h")
            .toDate()
        ),
        end: new Date(
          moment(element.date)
            .add(3, "h")
            .toDate()
        )
      };
      this.disabledDates.push(paused);
    });
  },
  methods: {
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },

    checkActivePackage() {
      if (!this.subscribedPackage) {
        return true;
      } else if (this.subscribedPackage.payment_status === "PENDING") {
        return true;
      }
      const today = new Date();
      const currentDate = moment(today);
      const endDate = moment(
        new Date(parseInt(this.subscribedPackage.end_date))
      ).tz(TIME_ZONE);
      let dateDiff = endDate.diff(currentDate, "days");
      if (dateDiff < 0) {
        return true;
      }
      return false;
    },
    onAddClick() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    updatePausedDates(data) {
      this.$emit("updatePausedDates", data);
    },
    isPackageNotAvailableOnThisDay(date, availabelWeekDaysInNumbers) {
      let day = date.day();
      let available = availabelWeekDaysInNumbers.filter(x => x == day);
      return available.length > 0 ? false : true;
    },
    setUnavailableDates() {
      let fromDate = new Date(parseInt(this.subscribedPackage.start_date));
      let toDate = new Date(parseInt(this.subscribedPackage.end_date));
      let momentFromDate = moment(fromDate)
        .add(3, "h")
        .startOf("day");
      let momentToDate = moment(toDate)
        .add(3, "h")
        .endOf("day");
      var dateArray = new Array();
      let availableWeekDays = this.subscribedPackage.package.availableChoices[0]
        .availableDays;
      let availabelWeekDays = Utils.getAvailabelWeekDaysInNumbers(
        availableWeekDays
      );

      while (momentFromDate <= momentToDate) {
        if (
          this.isPackageNotAvailableOnThisDay(momentFromDate, availabelWeekDays)
        ) {
          dateArray.push(momentFromDate.toDate());
        }
        momentFromDate.add(1, "days");
      }

      this.disabledDates = [];

      dateArray.forEach(element => {
        let paused = {
          start: new Date(
            moment(element)
              .add(3, "h")
              .toDate()
          ),
          end: new Date(
            moment(element)
              .add(3, "h")
              .toDate()
          )
        };
        this.disabledDates.push(paused);
      });
    },
    saveDate() {
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("../../api/pauseCustomerMenu.graphql"),
          variables: {
            date: moment(this.pausedDate).format("MM-DD-YYYY"),
            customerId: this.$route.query.customerId,
            subscribed_package_id: this.subscribedPackage._id
          },
          update: () => {
            this.btnloading = false;
            this.showDialog = false;
          }
        })
        .then(data => {
          this.$emit(
            "updatePausedDates",
            data.data.pauseCustomerMenu.subscribed_packages
          );
          this.pausedDate = "";
          this.$root.$emit("updateGetCustomerById");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    }
  },

  data() {
    return {
      tab: null,
      loading: true,
      requiredValidator,
      valid: false,
      tz: tz,
      btnloading: false,
      pausedDate: "",
      disabledDates: [],
      days: [],
      number: 0,
      showDialog: false,
      minDate: moment(new Date(parseInt(this.subscribedPackage.start_date)))
        .add(3, "h")
        .toDate(),
      maxDate: moment(new Date(parseInt(this.subscribedPackage.end_date)))
        .add(3, "h")
        .toDate(),
      weekDays: ""
    };
  }
});
</script>
