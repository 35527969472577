<template>
  <v-container class="mt-5" fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <span
      @click="$router.push({ name: 'Drivers' })"
      class="  primary--text"
      style="cursor:pointer"
    >
      <v-icon color="primary" class="me-1"> mdi-arrow-left</v-icon>back
    </span>

    <div class="d-flex justify-space-between ">
      <h2 class="primary--text text-h5 font-weight-bold mb-4 mt-4">
        Driver Customer Report
      </h2>
      <span>
        <ExportDriverDeliveryReport
          :driverId="driverId"
          :date="date"
          :customersList="customers"
        />
      </span>
    </div>

    <v-flex xs6 sm3 md2 class="me-4">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatedDate"
            label="Date"
            class="ms-sm-2"
            outlined
            append-icon="mdi-calendar-month-outline"
            v-bind="attrs"
            v-on="on"
            @click:clear="date = null"
            dense
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" @change="selectDate"></v-date-picker>
      </v-menu>
    </v-flex>

    <p v-if="driverName">Driver Name :{{ driverName }}</p>
    <!-- {{customers}} -->
    <v-row class="mt-3 mt-sm-0">
      <h6 class="text-body-2 black--text font-weight-bold ms-2 mb-6">
        Total Deliveries Today: {{ count ? count : 0 }}
      </h6>
    </v-row>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="customers"
      :items-per-page="10"
      class="elevation-1 mt-5 "
      :loading="loading"
      :options.sync="options"
      :server-items-length="count"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }"
    >
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <template v-slot:item.name="{ item }">
        <router-link
          v-if="item.user"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item._id },
            query: { customerId: item._id }
          }"
          class="mx-auto"
        >
          {{
            item.user.firstnameEn[0].toUpperCase() +
              item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
              item.user.lastnameEn.substring(1)
          }}
        </router-link>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
//import { format, parseISO } from "date-fns";
//import { DATE_FORMAT } from '../../utils/utils';
import ExportDriverDeliveryReport from "../components/ExportDriverDeliveryReport.vue";
export default {
  components: {
    ExportDriverDeliveryReport
  },
  apollo: {
    getDriverDeliveryReport() {
      console.log("date ", this.driverId);
      debugger;
      return {
        query: require("../api/getDriverDeliveryReport.graphql"),
        variables: {
          driverId: this.driverId,
          date: this.date
        },
        result({ data, loading }) {
          debugger;
          this.loading = loading;
          if (data) {
            console.log("Data ", data);
            const { customers, driver } = data.getDriverDeliveryReport;
            this.driver = driver;

            this.customers = customers.map((customer, index) => {
              return { ...customer, index: index + 1 };
            });
            this.count = this.customers.length;
            console.log("Customers ", this.customers);
          }
        },
        skip() {
          return !this.driverId || !this.date;
        }
      };
    }
  },
  watch: {
    driverId: {
      handler() {
        if (this.driverId) {
          this.$apollo.queries.getDriverDeliveryReport.refetch({
            driverId: this.driverId,
            date: this.date
          });
        }
      }
    },
    date: {
      handler() {
        if (this.date) {
          this.$apollo.queries.getDriverDeliveryReport.refetch({
            date: this.date,
            driverId: this.driverId
          });
        }
      }
    }
  },

  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      dateMenu: false,
      driverId: null,
      loading: true,
      customers: [],
      driver: null,
      count: 0,
      headers: [
        {
          text: "#",
          value: "index",
          width: 100
        },
        // { text: "Avatar", value: "photo" },
        { text: "Customer Name", value: "name", width: 150 },
        {
          text: "Contact Number",
          value: "user.phone",
          width: 150
        }
      ],
      options: {
        itemsPerPage: -1,
        page: 1
      }
    };
  },
  created() {
    this.driverId = this.$route.params.driverId;

    this.driverName = this.$route.params.driverName;

    if (!this.driverId) {
      this.$router.push({
        name: "Drivers"
      });
    }
  },
  methods: {
    selectDate() {
      this.dateMenu = false;
      this.$apollo.queries.getDriverDeliveryReport.refetch({ date: this.date });
    }
  },
  computed: {
    formatedDate() {
      return this.date ? moment(this.date).format("DD-MM-YYYY") : "";
    }
  }
};
</script>
