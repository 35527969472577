<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="black--text text-h5 font-weight-bold mt-2">Order Details</h2>

    <v-tabs
      class="mt-8 mb-n5"
      center-active
      v-model="tabs"
      background-color="bg"
      slider-size="49"
      slider-color="primary"
    >
      <v-tab
        v-if="hasRole(AdminUserPermissions.ORDER_ITEM_VIEW)"
        class="
          font-weight-medium
          text-capitalize text-caption text-sm-body-1
          white
          elevation-2
          mb-1
          rounded-lg
        "
        :class="{ 'font-weight-bold background--text': tabs === 0 }"
        ><label style="cursor: pointer" class="custom_slider"
          >Ordered Items</label
        ></v-tab
      >
      <v-tab
        v-if="hasRole(AdminUserPermissions.INGREDIENT_VIEW)"
        class="
          font-weight-medium
          text-capitalize text-caption text-sm-body-1
          white
          elevation-2
          ms-4
          mb-1
          rounded-lg
        "
        :class="{ 'font-weight-bold background--text': tabs === 1 }"
        ><label style="cursor: pointer" class="custom_slider"
          >Ingredients</label
        ></v-tab
      >
      <v-tab
        v-if="hasRole(AdminUserPermissions.MEALS_VIEW)"
        class="
          font-weight-medium
          text-capitalize text-caption text-sm-body-1
          white
          elevation-2
          ms-4
          mb-1
          rounded-lg
          
        "
        :class="{ 'font-weight-bold background--text': tabs === 2 }"
        ><label style="cursor: pointer" class="custom_slider"
          >Meals</label
        ></v-tab
      >
      <v-tab
        v-if="hasRole(AdminUserPermissions.MEALS_VIEW)"
        class="
          font-weight-medium
          text-capitalize text-caption text-sm-body-1
          white
          elevation-2
          ms-4
          mb-1
          rounded-lg
          
        "
        :class="{ 'font-weight-bold background--text': tabs === 3 }"
        ><label style="cursor: pointer" class="custom_slider"
          >Calorie</label
        ></v-tab
      >
    </v-tabs>

    <!-- <v-system-bar height="1"></v-system-bar> -->

    <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0 mt-10">
      <v-tab-item v-if="hasRole(AdminUserPermissions.ORDER_ITEM_VIEW)">
        <OrderedItemList />
      </v-tab-item>
      <v-tab-item v-if="hasRole(AdminUserPermissions.INGREDIENT_VIEW)">
        <IngredientsList />
      </v-tab-item>
      <v-tab-item v-if="hasRole(AdminUserPermissions.MEALS_VIEW)">
        <MealList />
      </v-tab-item>
      <v-tab-item v-if="hasRole(AdminUserPermissions.MEALS_VIEW)">
        <MealCalorie />
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-btn-toggle
      tile
      color="primary"
      v-model="tabs"
      mandatory
      dense
      background-color="bg"
      class="mt-6"
    >
      <v-btn
        color="white"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleOrder"
        elevation="1"
        class="font-weight-bold text-body-2 text-capitalize"
        >Ordered Items</v-btn
      >
      <v-btn
        color="white"
        elevation="1"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleIngredient"
        class="font-weight-bold text-body-2 text-capitalize ms-6"
        >Ingredients</v-btn
      ></v-btn-toggle
    >
    <OrderedItemList v-show="showOrder" />
    <IngredientsList v-show="showIngredient" /> -->
  </v-container>
</template>

<script>
import OrderedItemList from "../components/OrderedItemList.vue";
import IngredientsList from "../components/IngredientsList.vue";
import MealList from "../components/MealList.vue";
import MealCalorie from "../components/MealCalorie.vue";
export default {
  components: {
    OrderedItemList,
    IngredientsList,
    MealList,
    MealCalorie
  },
  data() {
    return {
      tabs: ""
      // showOrder: true,
      // showIngredient: false,
    };
  },
  methods: {
    // toggleOrder() {
    //   this.showOrder = true;
    //   this.showIngredient = false;
    // },
    // toggleIngredient() {
    //   this.showOrder = false;
    //   this.showIngredient = true;
    // },
  },
  created() {
    if (
      !this.hasRole(this.AdminUserPermissions.ORDER_ITEM_VIEW) &&
      !this.hasRole(this.AdminUserPermissions.INGREDIENT_VIEW) &&
      !this.hasRole(this.AdminUserPermissions.MEALS_VIEW)
    ) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
<style>
.v-tabs-slider {
  border-radius: 8px;
  height: 97%;
  margin-top: 1px;
}
/* .theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  color: #ffe500 !important;
} */
</style>
<style>
.button_tab {
  background-color: white;
}
.custom_slider {
  z-index: 1 !important;
}
</style>
