import { jsPDF } from "jspdf";
import moment from "moment";
var pageWidth = 210;
var pageHeight = 297; //will calcluate dynamically before initializing jsPDF
var start = 5;
var end = pageWidth - 5;
var normalFontSize = 13;
var mediumFontSize = 11;
var lineHeight = Math.floor(normalFontSize / 2);
var currentY = 4 * lineHeight;
var pageNumber = 1;
var xCenter = Math.floor(pageWidth / 2);
const printA4Orders = orders => {
  orders.map(order => {
    printA4Order(order);
  });
};
const printA4Order = order => {
  currentY = 4 * lineHeight;
  //utility functions
  // var tableHeight = order.orders.length * 5;

  var doc = new jsPDF("p", "mm", "a4");
  //rectangular border for the entire page
  doc.setLineWidth(1.0);
  doc.line(0, 0, 0, pageHeight);
  doc.line(pageWidth, 0, pageWidth, pageHeight);
  doc.line(0, 0, pageWidth, 0);
  doc.line(0, pageHeight, pageWidth, pageHeight);
  doc.setLineWidth(0.2);
  // You'll need to make your image into a Data URL
  // Use http://dataurl.net/#dataurlmaker
  const checkForNextPage = taxAreaHeight => {
    if (currentY > 297 - taxAreaHeight - 12) {
      doc.addPage();
      pageNumber += 1;
      doc.text(`Page No:${pageNumber}`, xCenter, 294, { align: "center" });
      currentY = 15;
      if (!taxAreaHeight) {
        debugger;
        horizLine();
      }
    }
  };
  const fontType = type => doc.setFont("helvetica", type);
  const trimString = (str, separator = " ") => {
    let maxLen = 32;
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  };
  const fontSize = size => {
    doc.setFontSize(size);
    lineHeight = Math.floor(size / 2);
  };
  const horizLine = () => {
    doc.line(0, currentY, pageWidth, currentY);
  };
  const nextLine = numberOfLines => (currentY += numberOfLines * lineHeight + 1);

  fontSize(mediumFontSize - 2);
  doc.text(`Page No:${pageNumber}`, xCenter, 294, { align: "center" });
  fontSize(normalFontSize);
  fontType("bold");
  //Design Starts
  doc.text("Order Details", start, currentY);
  nextLine(1);
  fontType("medium");
  debugger;
  doc.text(`${order.customer.user.firstnameEn} ${order.customer.user.lastnameEn}`, start, currentY);
  doc.text(`Order #Dietbux:${order.orderId}`, end, currentY, { align: "right" });
  nextLine(1);
  doc.text(`${order.customer.user?.phone}`, start, currentY);
  doc.text(`${moment(new Date(parseInt(order.createdAt))).format("DD-MM-YYYY")}`, end, currentY, { align: "right" });
  nextLine(1);
  doc.text(`${order.address?.area.nameEn} Area`, start, currentY);
  nextLine(1);
  doc.text(`${order.address?.street}  ${order.address?.house_number}`, start, currentY);
  nextLine(3);
  if (order.zone) {
    doc.text(`Zone - ${order.zone.nameEn || ""}`, start, currentY);
  }
  nextLine(1);

  horizLine();
  doc.line(start + 14.5, currentY, start + 14.5, currentY + 6.5); //vertical line on the headers table
  doc.line(start + 74, currentY, start + 74, currentY + 6.5);
  doc.line(start + 124, currentY, start + 124, currentY + 6.5);

  nextLine(0.5);
  fontSize(mediumFontSize);
  fontType("bold");
  doc.text("#", start + 4, currentY);
  doc.text("Product Name", start + 16, currentY);
  doc.text("Qty ", start + 76, currentY);
  doc.text("Price", start + 126, currentY);

  fontType("medium");

  nextLine(0.3);
  horizLine();

  let deliveryCharge = order.deliveryCharge || 0;

  order.products.forEach(function(item, index) {
    nextLine(0.5);
    // values
    debugger
    doc.text(String(index + 1), start + 4, currentY);
    doc.text(trimString(item.productInfo.nameEn), start + 16, currentY);
    doc.text(String(item.quantity), start + 76, currentY);
    doc.text(String(item.price) + " KWD" || "-", start + 126, currentY);

    //vertical line on the left side of a  value
    doc.line(start + 14.5, currentY - 3.5, start + 14.5, currentY + 1.5);
    doc.line(start + 74, currentY - 3.5, start + 74, currentY + 1.5);
    doc.line(start + 124, currentY - 3.5, start + 124, currentY + 1.5);

    nextLine(0.1);
    horizLine();
    checkForNextPage(0);
  });
  doc.line(start + 14.5, currentY, start + 14.5, currentY + 6);
  doc.line(start + 74, currentY, start + 74, currentY + 6);
  doc.line(start + 124, currentY, start + 124, currentY + 6);

  nextLine(1);
  horizLine();
  nextLine(2);
  checkForNextPage(18);
  doc.text("Total Price", start, currentY);
  doc.text(String(order.total.toFixed(3) + " KWD"), end - 11, currentY, {
    align: "right",
  });
  nextLine(1);
  doc.text("Delivery Charge", start, currentY);
  doc.text(String(deliveryCharge.toFixed(2) + " KWD"), end - 11, currentY, {
    align: "right",
  });

  nextLine(1);
  doc.text("Total Discount", start, currentY);
  if (order.discount) {
    doc.text(String(order.discount.toFixed(3) + " KWD"), end - 11, currentY, {
      align: "right",
    });
  } else {
    doc.text("0.00", end - 11, currentY, {
      align: "right",
    });
  }
  nextLine(2);
  doc.text("Order Amount", start, currentY);
  doc.text(String(order.netPrice.toFixed(3) + " KWD"), end - 11, currentY, {
    align: "right",
  });
  //Design ends
  doc.save(`INV${order.orderId}.pdf`);
};

export { printA4Orders };
