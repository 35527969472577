var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[(_vm.totalItemsCount == 0)?_c('div',[_c('v-alert',{staticClass:"text-center secondary--text",attrs:{"dense":"","color":"primary","dark":""}},[_vm._v(" No Active plans is "),_c('strong',[_vm._v(" expires")]),_vm._v(" in coming "),_c('strong',[_vm._v("3 ")]),_vm._v(" days ")])],1):_vm._e(),(_vm.salesOrderList.length !== 0)?_c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('h2',{staticClass:"primary--text text-h5 font-weight-bold"},[_vm._v(" End Date Reminder ")])]),_c('v-spacer'),_c('v-layout',{staticClass:"text-end"},[_c('ExportEndDate',{attrs:{"headers":_vm.headers}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.salesOrderList,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.created_At)),"MMM DD YYYY")))]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.start_date)),"MMM DD YYYY")))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.end_date)),"MMM DD YYYY")))]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.package.titleEn))]}},{key:"item.package_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.package_price)))]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.discount)))]}},{key:"item.net_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberWithCommas(item.net_price))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('PriceSplitup',{attrs:{"subscribedPackage":item}})]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item.customer },
            query: { customerId: item.customer }
          }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.lastnameEn))+" ")])]}}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }