<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n5  white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportdietitianList"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="dietitianList"
        class="elevation-1 "
      >
        <template v-slot:[`item.user`]="{ item }">
          <!-- <v-span v-if="item.user">  -->
          {{
            item.user.firstnameEn[0].toUpperCase() +
              item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
              item.user.lastnameEn.substring(1)
          }}
          <!-- </v-span> -->
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    dietitianList: {
      required: true
    }
  },
  data() {
    return {
      dietPlansExportList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      headers: [
        { text: "Name", value: "user", sortable: false, width: 200 },
        { text: "Phone", value: "user.phone", sortable: false, width: 200 },
        { text: "Is Active", value: "isActive", sortable: false, width: 120 }
      ]
    };
  },
  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportdietitianList", "Dietitian List", false);
      this.loading = false;
    }
  }
};
</script>
