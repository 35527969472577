var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"white--text text-capitalize mt-5",attrs:{"small":"","width":"100","loading":_vm.loading,"color":"primary"},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v("mdi-export")]),_vm._v("Export ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"id":("tblOrders_" + _vm.type),"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderNumber)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!item.is_deleted)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item.customer._id },
          query: { customerId: item.customer._id },
        }}},[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.user.lastnameEn))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.toTitleCase(item.customer.user.firstnameEn))+" "+_vm._s(_vm.toTitleCase(item.customer.user.lastnameEn))+" ")])]}},{key:"item.phone",fn:function(ref){
        var item = ref.item;
return [(item.customer)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.customer.user.phone)+" ")]):_vm._e()]}},{key:"item.zone",fn:function(ref){
        var item = ref.item;
return [(item.zone)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.zone ? item.zone.zoneName : "-")+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total))+" ")])]}},{key:"item.discount",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.discount))+" ")])]}},{key:"item.netPrice",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.netPrice))+" ")])]}},{key:"item.paymentStatus",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.paymentStatus)+" ")])]}},{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [(item.createdBy)?_c('h4',{staticClass:"grey1--text font-weight-medium my-0 py-0"},[_vm._v(" "+_vm._s(item.createdBy.firstnameEn)+" "+_vm._s(item.createdBy.lastnameEn)+" ")]):_vm._e()]}},{key:"item.orderDate",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"font-weight-medium my-0 py-0 gray2--text"},[_vm._v(" "+_vm._s(_vm.moment(new Date(parseInt(item.createdAt))).format("DD MMM YYYY"))+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }