import { jsPDF } from "jspdf";
import moment from "moment";
var pageWidth = 80;
//var pageHeight = 297; //will calcluate dynamically before initializing jsPDF
var start = 5;
var end = 75;
var normalFontSize = 9;
var mediumFontSize = 9;
var lineHeight = Math.floor(normalFontSize / 2);
var currentY = 2.5 * lineHeight;
//var xCenter = Math.floor(pageWidth / 2);
const printPosOrders = orders => {
  orders.map(order => {
    printPosOrder(order);
  });
};
const printPosOrder = order => {
  currentY = 2.5 * lineHeight;
  //utility functions

  const trimString = (str, separator = " ") => {
    let maxLen = 22;
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  };
  const fontType = type => doc.setFont("helvetica", type);
  const fontSize = size => {
    doc.setFontSize(size);
    lineHeight = Math.floor(size / 2);
  };
  const horizLine = () => {
    doc.line(0, currentY, pageWidth, currentY);
  };
  const nextLine = numberOfLines => (currentY += numberOfLines * lineHeight + 1);

  //utility functions end
  //Dynamic height calculation based on number of products and product name length
  var tableHeight = order.products.length ? order.products.length * 9.8 : 0;

  var pageHeight = tableHeight + 110;
  var doc = new jsPDF("p", "mm", [pageWidth, pageHeight]);
  fontSize(normalFontSize);
  fontType("bold");
  //Design Starts
  doc.text("Order Details", start, currentY);
  nextLine(1);
  fontType("medium");
  doc.text(`${order.customer.user.firstnameEn} ${order.customer.user.lastnameEn}`, start, currentY);
  doc.text(`Order #Dietbux:${order.orderId}`, end, currentY, { align: "right" });

  nextLine(1);
  doc.text(`${order.customer.user?.phone}`, start, currentY);
  doc.text(`${moment(new Date(parseInt(order.createdAt))).format("DD-MM-YYYY")}`, end, currentY, { align: "right" });
  nextLine(1);
  doc.text(`Address - ${order.address.addressType || ""}`, start, currentY, { maxWidth: 50 });

  nextLine(1);

  if (order.address.area) {
    doc.text(`${order.address.area.nameEn || ""}`, start, currentY, { maxWidth: 50 });
    nextLine(1);
  }
  doc.text(`${order.address.apartment_number || ""}`, start, currentY, { maxWidth: 50 });
  nextLine(1);
  doc.text(`${order.address.building_number || ""}`, start, currentY, { maxWidth: 50 });
  // nextLine(1);
  // doc.text(`${order.address.jedha || ""}`, start, currentY, { maxWidth: 50 });
  // nextLine(1);
  // doc.text(`${order.address.street || ""}`, start, currentY, { maxWidth: 50 });

  nextLine(3);
  // if (order.deliveryBoy) {

  //     doc.text(`Delivery Boy - ${order.deliveryBoy.name || ''}`, start, currentY);
  // }
  nextLine(1);

  horizLine();
  doc.line(start + 4.5, currentY, start + 4.5, currentY + 5);
  //doc.line(start + 39, currentY, start + 39, currentY + 5)
  doc.line(start + 59, currentY, start + 59, currentY + 5);
  doc.line(start + 65, currentY, start + 65, currentY + 5);
  nextLine(0.5);
  fontSize(mediumFontSize);
  fontType("bold");
  doc.text("#", start, currentY);
  doc.text("Product Name", start + 6, currentY);
  //doc.text("Variant", start + 40, currentY);
  doc.text("Qty", start + 59.2, currentY);
  doc.text("Price", start + 66, currentY);
  fontType("medium");

  doc.line(start + 4.5, currentY + 2, start + 4.5, currentY + 2 + tableHeight);
  //doc.line(start + 39, currentY + 2, start + 39, currentY + 2 + tableHeight)
  doc.line(start + 59, currentY + 2, start + 59, currentY + 2 + tableHeight);
  doc.line(start + 65, currentY + 2, start + 65, currentY + 2 + tableHeight);
  nextLine(0.3);
  horizLine();

  let deliveryCharge = order.deliveryCharge || 0;

  order.products.forEach(function(item, index) {
    nextLine(0.5);
    doc.text(String(index + 1), start, currentY + 2);
    doc.text(trimString(item.productInfo.nameEn), start + 6, currentY);

    // doc.text(item.product.brand.name || '-', start + 6, currentY + 4)

    // doc.text(String(item.productInfo.variant), start + 40, currentY + 2, { maxWidth: 20 })
    doc.text(String(item.quantity), start + 60, currentY + 2);
    doc.text(String(item.price.toFixed(2)), start + 66, currentY + 2);
    nextLine(1.4);
    horizLine();
  });
  //doc.line(start + 4.5, currentY, start + 4.5, currentY + 3)
  // doc.line(start + 39, currentY, start + 39, currentY + 3)
  //doc.line(start + 59, currentY, start + 59, currentY + 3)
  //doc.line(start + 65, currentY, start + 65, currentY + 3)
  nextLine(0.5);
  //horizLine()
  nextLine(2);
  //doc.text("Base Price", start, currentY)
  //doc.text(String(order.total.toFixed(2)), end, currentY, "right")
  //  nextLine(1)
  //doc.text("GST Amount", start, currentY)
  // doc.text(String(order.pricing.tax.toFixed(2)), end, currentY, "right")
  //nextLine(1);
  
  doc.text("Total Amount", start, currentY);
  doc.text(String(order.total.toFixed(2)), end, currentY, "right");
  nextLine(1);

  doc.text("Delivery Charge", start, currentY);
  doc.text(String(deliveryCharge.toFixed(2)), end, currentY, "right");
  nextLine(1);

  doc.text("Total Discount", start, currentY);
  doc.text(String(order.discount ? order.discount.toFixed(2) : "0.00"), end, currentY, "right");
  nextLine(1);

  doc.text("Net Amount", start, currentY);
  doc.text(String(order.netPrice.toFixed(2)), end, currentY, "right");
  // doc.text("Order Amount", start, currentY)
  //doc.text(String((order.pricing.netAmount).toFixed(2)), end, currentY, "right")
  //Desgin Ends
  doc.save(`INV${order.orderId}.pdf`);
};

export { printPosOrders };
