<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black">mdi-square-edit-outline</v-icon>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Update Appointment</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
          <v-form @submit="updateAppointment()" v-model="valid" ref="form">
            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Date</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate"
                      readonly
                      label="Date"
                      :rules="[requiredValidator('date')]"
                      solo
                      append-icon="mdi-calendar-month-outline"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                      dense
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    color="secondary"
                    v-model="date"
                    @change="menu = false"
                    :min="currentDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <!-- offset-md-1 -->
            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Time</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      label="Time"
                      :rules="[requiredValidator('time')]"
                      solo
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    color="secondary"
                    v-if="menu1"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <!-- <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Dietitian</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-autocomplete
                  v-model="dietitian"
                  :items="dietitianList"
                  item-text="user.firstnameEn"
                  item-value="_id"
                  solo
                  dense
                  label="Dietitians"
                >
                  <template slot="item" slot-scope="data"
                    >{{ data.item.user.firstnameEn }}
                    {{ data.item.user.lastnameEn }}
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout> -->

            <v-layout row class="mt-4" justify-center>
              <v-btn
                width="120"
                color="secondary"
                class="text-capitalize black--text"
                @click="updateAppointment()"
                :loading="btnloading"
                :disabled="!valid"
                >Update</v-btn
              >
            </v-layout>
          </v-form>
        </v-container>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  requiredValidator
  // requiredValidatorSelect,
} from "../../../core/methods/validations";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  components: {},
  props: {
    appointment: {
      required: true
    }
  },
  // apollo: {
  //   getAllDietitians() {
  //     return {
  //       query: require("@/dietitian/api/getAllDietitians.graphql"),
  //       variables: {
  //         skip: 0,
  //         limit: 1000,
  //         searchKey: "",
  //       },
  //       result({ data, loading }) {
  //         this.loading = loading;

  //         this.dietitianList = data.getAllDietitians.dietitians;
  //       },
  //     };
  //   },
  // },
  created() {
    this.dietitian = this.appointment.dietitian._id;
    if (this.appointment.appointmentDate) {
      // debugger;
      const date = new Date(parseInt(this.appointment.appointmentDate));

      this.date = moment(
        new Date(parseInt(this.appointment.appointmentDate))
      ).format("YYYY-MM-DD");

      //this.date = format(parseISO(date.toISOString()), "dd-MM-yyyy");
      const time = `${date.getHours()}:${date.getMinutes()}`;
      this.time = time;
    }
  },

  data() {
    return {
      date: "",
      time: "",
      menu: "",
      menu1: "",
      dietitianLoading: false,
      dietitiansSearchKey: "",
      dietitianList: [],
      dietitian: "",
      awaitingSearch: false,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      variables: {
        limit: 0,
        skip: 0
      }
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    updateAppointment() {
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("@/appointments/api/updateAppointment.graphql"),
          variables: {
            id: this.appointment._id,
            appointmentTime: this.time,
            appointmentDate: this.date
          }
        })
        .then(() => {
          this.snackbar = true;
          this.text = "Dietitian appoinment updated";
          this.$root.$emit("updateDietitianAppointments");
          this.btnloading = false;
          this.close();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.close();
        });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
    currentDate() {
      let data = new Date();
      return moment(data).format("YYYY-MM-DD");
    }
  }
};
</script>
