<template>
    <div>
      <v-layout justify-end>
        <v-snackbar
          v-model="successAlert"
          :bottom="false"
          color="success"
          :multi-line="false"
          :right="true"
          :top="true"
        >
          Sales order successfully uploaded.
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="successAlert = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
  
        <v-snackbar
          v-model="errorAlert"
          :bottom="false"
          color="error"
          :multi-line="false"
          :right="true"
          :top="true"
        >
          Error occured, please check your csv file.
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="errorAlert = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
  
        <v-btn
          class="white-bg  me-3 text-capitalize primary--text"
          width="195px"
          height="40px"
          color="secondary"
          @click.stop="showDialog = true"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>Upload Ingredients
        </v-btn>
  
        <v-dialog v-model="showDialog" max-width="800">
          <v-card class="pa-4 pa-md-12 ">
            <v-row justify-sm="space-between" justify="start" class="mb-4 px-4">
              <v-col cols="12" sm="5">
                <p class="primary--text">Upload Sales Order (csv)</p>
              </v-col>
              <v-col cols="12" sm="3" class="d-flex justify-end">
                <v-btn
                  class=""
                  color="black"
                  @click="sampleFileDownload"
                  outlined
                  depressed
                  small
                >
                  <v-icon left>mdi-export</v-icon> Sample
                </v-btn>
              </v-col>
            </v-row>
  
            <v-file-input
              v-model="fileUpload"
              label="File input"
              outlined
              placeholder="Select your file"
              dense
              @change="readFile"
            ></v-file-input>
  
            <v-data-table :headers="headers" :items="tableData"></v-data-table>
  
            <v-row justify="space-between" class="mt-4">
              <v-btn
                class="ms-3"
                depressed
                @click="
                  showDialog = false;
                  clearFields();
                "
                >Close</v-btn
              >
  
              <v-btn
                class="mt-1 me-4"
                color="error"
                v-show="errorMessages.length > 0"
                @click="showErrorDialog = true"
                outlined
                depressed
                small
              >
                Errors
              </v-btn>
              <v-btn
                class="primary me-3 text-capitalize "
                @click.stop="onUploadClick"
                :disabled="errorMessages.length > 0"
                :loading="btnLoading"
              >
                <!-- <v-icon left>mdi-plus</v-icon> -->
                Upload SalesOrders
              </v-btn>
            </v-row>
  
            <v-overlay :value="btnLoading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <span v-if="this.errorFromApi" class="mx-7 ">
              <p class="red--text">
                {{ errorFromApi }}
              </p>
            </span>
          </v-card>
        </v-dialog>
  
        <v-dialog v-model="showErrorDialog" max-width="800">
          <v-card class="pa-4 pa-md-12 ">
            <v-row v-for="item in errorMessages" :key="item" class="error">
              <span class="ml-2"> {{ item }}</span>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { emailValidator } from "../../core/methods/validations";
  import VuePapaParse from "vue-papa-parse";
  
//import { parse } from "path";
  Vue.use(VuePapaParse);
  
  export default Vue.extend({
    components: {},
    created() {
      // this.loadLanguages();
    },
    methods: {
      clearFields() {
        this.tableData = [];
        this.btnLoading = false;
      },
      sampleFileDownload() {
        const url = "../sampleSalesOrderCSV.csv";
        window.open(url);
      },
      readFile(event) {
        debugger
        this.errorMessages = [];
        this.errorFromApi = "";
        const self = this;
        if (!event) {
          self.clearFields();
          return false;
        }
        let errorMessages = new Array();
        this.$papa.parse(event, {
          header: true,
          dynamicTyping: true,
          complete: function(results) {
            const arrVal = new Array();

            console.log(results.data);
            for (let [index, element] of results.data.entries()) {
            //   if (element["Item ID"] !== null) {
              console.log("INDEX ele ",index, element)
              if(element["Item Name (English)"]==null){
                break

              }
                const obj = {
                  nameEn: String(element["Item Name (English)"]).trim(),
                  nameAr: String(element["Item Name (Arabic)"]).trim(),
                  quantityPrice:parseFloat(element["Quantity Price"]),
                  quantityUnit :String(element["Quantity Unit"]).trim(),
                  availableQuantity:String(element["Available Quantity"]).trim(),
                  brand: String(element["Brand Name"]).trim(),
                  label:String(element["Label"]).trim(),
                  isIngredient:Boolean(element["Is Ingredient"]),
                  isCommonDislikable:Boolean(element["Is Common Dislikable"]),
                  ingredientCategory:String(element["Ingredient Category"]),
                  alertQuantity:String(element["Alert Quantity"]).trim(),
                  meanInfo:{
                    protein:String(element["Protein"]).trim(),
                    fat:String(element["Fat"]).trim(),
                    carbs:String(element["Carbs"]).trim(),
                    calories:String(element["Calories"]).trim(),
                  }

                };
                console.log("Obj ",obj)
                arrVal.push(obj);
                console.log(index);
            //   } else {
            //     errorMessages.push(
            //       `Error row  - Error column customer Phone is null`
            //     );
            //     console.log(index);
            //   }
            }
            self.errorMessages = errorMessages;
            const validatedData = self.validateIngredients(arrVal); //validation for required fields
            console.log("Validate data ",validatedData);
            self.tableData = validatedData;
          }
        });
      },
      validateIngredients(ingredients) {
        debugger
        let validEntries = [];
        let errorMessages = new Array();

        // let allPhoneNos = ingredients.map(x => x.phone);
        // const duplicates = allPhoneNos.filter(
        //   (item, index) => allPhoneNos.indexOf(item) !== index
        // );
  
        // if (duplicates.length) {
        //   for (let duplicate of duplicates) {
        //     errorMessages.push(
        //       `Duplicate Phone No, coloumn - phone (customer phone:${duplicate})`
        //     );
        //   }
        // }
        for (const [index, salesOrder] of ingredients.entries()) {
          let salesOrderInput = new Object();
          let isValid = true;
  
        //   if (!salesOrder.phone || salesOrder.phone === "null") {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index + 1}, coloumn - phone (customer phone:${
        //         salesOrder.phone
        //       })`
        //     );
        //   } else {
        //     if (salesOrder.phone.length !== 8) {
        //       isValid = false;
        //       errorMessages.push(
        //         `Error row - ${index +
        //           1}, coloumn - phone(should 8 digit) (customer phone:${
        //           salesOrder.phone
        //         })`
        //       );
        //     } else {
        //       salesOrderInput.phone = salesOrder.phone;
        //     }
        //   }


          if (!salesOrder.nameEn || salesOrder.nameEn === "null") {
            isValid = false;
            errorMessages.push(
              `Error row - ${index + 1}, coloumn - Item Name (English):${
                salesOrder.nameEn
              })`
            );
          } else {
            salesOrderInput.nameEn = salesOrder.nameEn;
          }
        //   if (!salesOrder.choice || salesOrder.choice === "null") {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index + 1}, coloumn - choice (customer phone:${
        //         salesOrder.phone
        //       })`
        //     );
        //   } else {
        //     salesOrderInput.choice = salesOrder.choice;
        //   }


        //   if (!salesOrder.variant || salesOrder.variant === "null") {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index + 1}, coloumn - variant (customer phone:${
        //         salesOrder.phone
        //       })`
        //     );
        //   } else {
        //     salesOrderInput.variant = salesOrder.variant;
        //   }


        //   if (!salesOrder.start_date || salesOrder.start_date === "null") {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index + 1}, coloumn - start date (customer phone:${
        //         salesOrder.phone
        //       })`
        //     );
        //   } else {
        //     let result = this.validateDate(salesOrder.start_date);
        //     if (result == "DAY") {
        //       isValid = false;
        //       errorMessages.push(
        //         `Error row - ${index +
        //           1}, coloumn - dob day (format) (customer phone:${
        //           salesOrder.phone
        //         })`
        //       );
        //     } else if (result == "MONTH") {
        //       isValid = false;
        //       errorMessages.push(
        //         `Error row - ${index +
        //           1}, coloumn - dob month (format) (customer phone:${
        //           salesOrder.phone
        //         })`
        //       );
        //     } else if (result == "YEAR") {
        //       isValid = false;
        //       errorMessages.push(
        //         `Error row - ${index +
        //           1}, coloumn - dob year (format) (customer phone:${
        //           salesOrder.phone
        //         })`
        //       );
        //     } else {
        //       salesOrderInput.start_date = result;
        //       // signUpInput.dob = result;
        //     }
        //   }


  
          if (isValid) {
            salesOrderInput.quantityPrice = parseFloat(salesOrder.quantityPrice)
            this.importCustomerData.push(salesOrder);
            console.log("salesOrder ",salesOrder)
            validEntries.push(salesOrder);
          }
        }
        this.errorMessages = errorMessages;
  
        return validEntries;
      },
      validateDate(date) {
        let result = date.split("-");
        let day = parseInt(result[0]);
        let month = parseInt(result[1]);
        let year = result[2];
        if (month > 12 || month < 1) {
          return "MONTH";
        }
        if (year.length !== 4) {
          return "YEAR";
        }
        if (day > 31 || day < 1) {
          return "DAY";
        }
        let dateFormat = `${parseInt(year)}-${month}-${day}`;
  
        return dateFormat;
      },
      onUploadClick() {
        console.log("CustomerData ",this.importCustomerData)
        debugger
        if (this.tableData.length > 0) {
          this.btnLoading = true;
          this.$apollo
            .mutate({
              mutation: require("../api/importIngredients.graphql"),
              variables: {
                ImportIngredient: {
                  importInputs: this.importCustomerData
                }
              }
            })
            .then(data => {
              console.log(data);
              this.successAlert = true;
              this.btnLoading = false;
              this.showDialog = false;
            })
            .catch(error => {
              this.btnLoading = false;
              let errorFromApi = JSON.parse(error.graphQLErrors[0].message);
              if (errorFromApi.message) {
                console.log("message");
                this.errorFromApi = `Error From API : ${errorFromApi.message}`;
                let err = new Array();
                if (errorFromApi.data.length) {
                  for (let errFromApi of errorFromApi.data) {
                    let errData = errFromApi.message;
                    let errtype = errFromApi.type;
                    let errResult = `${errData}  ( ${errtype} )`;
                    err.push(errResult);
                  }
                }
                this.errorMessages = err;
              } else {
                if (errorFromApi == "NO_DATA") {
                  this.errorFromApi = "Some Error";
                }
              }
            });
        } else {
          this.errorAlert = true;
        }
      }
    },
    data() {
      return {
        showErrorFromApi: false,
        errorFromApi: null,
        validInputs: null,
        totalInputs: null,
        errorsFromApi: [],
        importCustomerData:[],
        importcustomerData: [],
        btnLoading: false,
        showErrorDialog: false,
        errorMessages: [],
        fileUpload: [],
        tableData: [],
        showDialog: false,
        successAlert: false,
        errorAlert: false,
        emailValidator,
        headers: [
          {
            text: "ItemId",
            value: "itemId",
            sortable: false
          },
          {
            text: "Item Name (English)",
            value: "nameEn",
            sortable: false
          },
          {
            text: "Item Name (Arabic)",
            value: "nameAr",
            sortable: false
          },
          { text: "Brand Name", value: "brand",  sortable: false},
          { text: "Quantity Price", value: "quantityPrice",  sortable: false},
          { text: "Quantity Unit", value: "quantityUnit",  sortable: false},
          { text: "Available Quantity", value: "quantityPrice",  sortable: false},
          { text: "Label", value: "label",  sortable: false},
          { text: "Alert Quantity", value: "alertQuantity",  sortable: false},
          { text: "Is Ingredient", value: "isIngredient",  sortable: false},
          { text: "Is Common Dislikable", value: "isCommonDislikable",  sortable: false},
          { text: "Ingredient Category", value: "ingredientCategory",  sortable: false},

          { text: "Protein", value: "meanInfo.protein",  sortable: false},
          { text: "Fat", value: "meanInfo.fat",  sortable: false},
          { text: "Carbs", value: "meanInfo.carbs",  sortable: false},
          { text: "Calories", value: "meanInfo.calories",  sortable: false},
          
         

        ]
      };
    }
  });
  </script>
  
  <style scoped>
  .vue-csv-mapping {
    display: none;
  }
  </style>
  