<template>
  <v-container fluid>
    <v-row v-show="fromCustomer">
      <!-- <Shopping /> -->
      <v-btn class="white--text text-capitalize mt-1 mb-5" color="secondary" @click="onShopping"> <v-icon class="me-1"
          small>mdi-plus</v-icon>Add </v-btn>
    </v-row>

    <v-row class="">
      <v-col cols="6" sm="6" md="3" v-show="!fromCustomer">
        <v-autocomplete v-model="filter.zone" dense outlined clearable @change="zoneChange()" :loading="zoneLoading" flat
          prepend-inner-icon="mdi-filter-variant" background-color="white" :items="zoneList" item-text="zoneName"
          item-value="_id" label="Zone"></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-autocomplete v-model="filter.deliveryStatus" dense outlined clearable background-color="white"
          @change="statusChange()" flat prepend-inner-icon="mdi-filter-variant" color="secondary" :items="statusTypes"
          label="Delivery Status"></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="6" sm="6" md="3">
        <v-menu v-model="menuDeliveryDate" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="deliveryDateFormatted" label="Order from date" class="" outlined
              append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" background-color="white"
              dense></v-text-field>
          </template>
          <v-date-picker color="secondary" @change="selectFromDate" v-model="filter.deliveryDate"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="6" sm="6" md="3">
        <v-menu v-model="menuOrderDate" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="orderDateFormatted" label="Order to date" outlined class="" background-color="white"
              append-icon="fa-calendar-alt black--text" v-bind="attrs" v-on="on" dense></v-text-field>
          </template>
          <v-date-picker color="secondary" @change="selectFromDate" v-model="filter.orderDate"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <!-- <v-row justify="space-between" align="center">
          <v-col cols="12" sm="4" md="4" class="pb-0 mb-md-0 mb-4">
            <v-text-field
              v-model="searchKey"
              :search-input.sync="searchKey"
              @change="searchChange()"
              dense
              outlined
              prepend-inner-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row> -->
    <!--  -->

    <v-tabs v-model="tabs" background-color="primary">
      <v-tab> Current Orders </v-tab>
      <v-tab> Past Orders </v-tab>
      <v-tab> Pending Orders </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item :key="0">
        <CurrentOrders :fromCustomer="fromCustomer" :filter="filter" :customer="customer" />
      </v-tab-item>

      <v-tab-item :key="2">
        <PastOrders :fromCustomer="fromCustomer" :filter="filter" :customer="customer" />
      </v-tab-item>

      <v-tab-item :key="2">
        <PendingOrders :fromCustomer="fromCustomer" :filter="filter" :customer="customer" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { printA4Orders } from "../methods/printA4Orders";
import { printPosOrders } from "../methods/printPosOrders";

import PastOrders from "../components/PastOrders.vue";
import CurrentOrders from "../components/CurrentOrders.vue";
import PendingOrders from "../components/PendingOrders.vue";
// import Shopping from "../components/Shopping.vue";

import moment from "moment";
import { format, parseISO } from "date-fns";

export default {
  components: {
    PendingOrders,
    PastOrders,
    CurrentOrders,
    // Shopping,
  },

  props: {
    fromCustomer: {
      required: false,
    },
    customer: {
      required: false,
    },
  },

  mounted() {
    this.$root.$on("getAllOrders", () => {
      this.getAllOrders();
    });
  },
  watch: {
    tabs: {
      handler() {
        if (this.tabs == 0) {
          this.statusTypes = ["PENDING", "DISPATCHED"];
        }
        if (this.tabs == 1) {
          this.statusTypes = ["DELIVERED", "CANCELLED"];
        }
        if (this.tabs == 2) {
          this.statusTypes = ["PENDING"];
        }
      },
    },
    fromDate: {
      handler() {
        this.getAllOrders();
      },
    },
    toDate: {
      handler() {
        this.getAllOrders();
      },
    },
    orderDate: {
      handler() {
        this.getAllOrders();
      },
    },
    searchKey: {
      handler(val) {
        this.variables.searchKey = val;
        this.getAllOrders();
      },
    },
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : this.totalOrders),
          (this.variables.skip = this.options.page * this.options.itemsPerPage - this.options.itemsPerPage);
        this.getAllOrders();
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      moment: moment,
      regions: [],
      zones: [],
      zoneLoading: true,
      region: "",
      zone: "",
      deliveryStatus: "",
      paymentStatus: "SUCCESS",
      //
      menuDeliveryDate: false,
      menuOrderDate: false,
      search: "",
      searchKey: "",
      tabs: "",
      headers: [
        { text: "", value: "checkbox", sortable: false, width: 50 },

        {
          text: "Order Id",
          align: "left",
          value: "orderId",
          sortable: false,
          width: 70,
        },
        {
          text: "Delivery Status",
          align: "center",
          value: "deliveryStatus",
          sortable: false,
          width: 60,
        },
        {
          text: "Payment Status",
          align: "center",
          value: "paymentStatus",
          sortable: false,
          width: 60,
        },
        {
          text: "Customer",
          align: "center",
          width: 180,
          value: "customer",
          sortable: false,
        },
        {
          text: "Phone",
          align: "center",
          width: 100,
          value: "phone",
          sortable: false,
        },
        {
          text: "Total",
          align: "center",
          width: 120,
          value: "total",
          sortable: false,
        },
        {
          text: "Discount",
          align: "center",
          width: 100,
          value: "discount",
          sortable: false,
        },
        {
          text: "Net Price",
          align: "center",
          width: 120,
          value: "netPrice",
          sortable: false,
        },
        {
          text: "Zone",
          align: "center",
          value: "zone",
          sortable: false,
          width: 120,
        },
        {
          text: "Order Date",
          align: "center",
          width: 120,
          value: "orderDate",
          sortable: false,
        },
        {
          text: "Created By",
          align: "center",
          width: 150,
          value: "createdBy",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          width: 80,
          value: "action",
          sortable: false,
        },
      ],
      statusTypes: ["PENDING", "DISPATCHED", "DELIVERED", "CANCELLED"],
      variables: {
        limit: 10,
        skip: 0,
        regionFilter: "",
        zoneFilter: "",
        status: null,
        searchKey: "",
        deliveryDate: null,
        orderDate: null,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      currentOrder: {},
      allOrders: [],
      totalOrders: 10,
      currentStatus: null,
      currentOrderID: 0,
      prevStatus: [],

      fromDate: "",
      toDate: "",

      showMarkAsDespatch: false,
      showMarkAsDeliverd: false,
      showEditOrder: false,
      showProductReturn: false,
      orderEditCustomerId: "",
      productReturnCustomerId: "",
      isEdit: false,
      orderToEdit: null,
      selectedIds: [],
      isAnyOrderSelected: false,
      status: "",
      deliveryTimeDialog: null,
      deliveryText: null,
      deliveryTimeTo: null,
      deliveryTimeRequired: "",
      zoneList: [],
      filter: {
        deliveryStatus: "",
        paymentStatus: "SUCCESS",
        deliveryDate: format(
          parseISO(
            moment()
              .subtract(7, "days")
              .toDate()
              .toISOString()
          ),
          "yyyy-MM-dd"
        ),

        orderDate: format(
          parseISO(
            moment()
              .toDate()
              .toISOString()
          ),
          "yyyy-MM-dd"
        ),
        zone: "",
      },
    };
  },

  computed: {
    deliveryDateFormatted() {
      return this.filter.deliveryDate ? moment(this.filter.deliveryDate).format("DD/MM/YYYY") : "";
    },
    orderDateFormatted() {
      return this.filter.orderDate ? moment(this.filter.orderDate).format("DD/MM/YYYY") : "";
    },

    toDateFormatted() {
      return this.toDate ? moment(this.toDate).format("DD/MM/YYYY") : "";
    },
    fromDateFormatted() {
      return this.fromDate ? moment(this.fromDate).format("DD/MM/YYYY") : "";
    },
    filteredHeaders() {
      return this.headers.filter(header => {
        return header.value !== "checkbox" && header.value !== "action";
      });
    },
  },
  created() {
    this.getAllOrders();
    this.getAllZones();
  },
  methods: {
    onShopping() {
      this.$router.push({
        name: "CustomerShopping",
        query: { customerId: this.$route.query.customerId },
      });
    },
    getAllZones() {
      this.zoneLoading = true;
      this.$apollo
        .query({
          query: require("@/customer/api/getAllZones.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey: "",
          },
        })
        .then(data => {
          this.zoneLoading = false;
          if (data.data.getAllZones) {
            this.zoneList = data.data.getAllZones.zones;
          }
        })
        .catch(error => {
          this.zoneLoading = false;
          console.log(error);
        });
    },
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },

    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    closeDeliveryTime() {
      this.deliveryTimeDialog = false;
    },
    setDeliveryTime() {
      if (this.deliveryText) {
        this.updateConfirmed("DISPATCHED");
        this.deliveryTimeDialog = false;
      } else {
        this.deliveryTimeRequired = "Please enter the text";
      }
    },

    padLeadingZeros(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    printA4Orders() {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      printA4Orders(selectedOrders);
    },
    printPosOrders() {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      printPosOrders(selectedOrders);
    },

    addProduct(data) {
      this.orderEditCustomerId = data.customerId;
      this.isEdit = false;
      this.orderToEdit = {
        shippingDetails: data.address,
      };

      this.showSideDrawer("EditUserProduct");
    },
    getAllOrders() {
      this.loading = true;
      let pageInput = {
        skip: 0,
        limit: 25,
      };

      let filter = {
        paymentStatus: this.filter.paymentStatus,
        deliveryStatus: this.filter.deliveryStatus,
        zone: this.filter.zone,
        fromDate: this.filter.deliveryDate,
        toDate: this.filter.orderDate,
        customer: this.fromCustomer ? this.customer._id : "",
      };
      this.$apollo
        .watchQuery({
          query: require("../api/listOrders.graphql"),
          variables: {
            input: pageInput,
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .refetch({
          variables: {
            input: {
              limit: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
              skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
            },
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .then(data => {
          this.isAnyOrderSelected = false; // posOrder button disabling
          this.loading = false;
          this.allOrders = [];
          this.totalOrders = 0;
          if (data.data.listOrders) {
            let allOrders = data.data.listOrders.orders;
            allOrders.forEach(element => {
              element.isChecked = false;
            });
            this.allOrders = allOrders;
            this.totalOrders = data.data.listOrders.totalCount;
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    fromDateChange() {
      if (this.fromDate) {
        this.getAllOrders();
      }
    },
    toDateChange() {
      debugger;
      if (this.toDate) {
        this.getAllOrders();
      }
    },
    regionChange() {
      if (this.region) {
        let selectedRegion = this.regions.find(x => x._id == this.region);
        this.zones = selectedRegion.zones;
        this.variables.regionFilter = this.region;
        this.getAllOrders();
      } else {
        this.zones = [];
        this.variables.regionFilter = "";
        this.variables.zoneFilter = "";
        this.getAllOrders();
      }
    },
    zoneChange() {
      this.variables.zoneFilter = this.zone;
      this.getAllOrders();
    },
    statusChange() {
      this.getAllOrders();
    },
    searchChange() {
      this.variables.searchKey = this.searchKey;
      this.getAllOrders();
    },

    selectFromDate() {
      this.menuDeliveryDate = false;
      this.menuOrderDate = false;
    },
    updateConfirmed(val) {
      if (val) this.updateOrderStatuses(this.status);
    },
    onDelivered() {
      this.status = "DELIVERED";
      this.$refs.status_modal.show();
    },
    onDispatched() {
      this.status = "DISPATCHED";
      this.deliveryTimeDialog = true;
    },
    updateOrderStatuses(status) {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      let selectedIds = [];
      selectedOrders.forEach(order => {
        selectedIds.push(order._id);
      });
      this.$apollo
        .mutate({
          mutation: require("../api/updateOrderStatuses.graphql"),
          variables: {
            ids: selectedIds,
            status: status,
            deliveryText: this.deliveryText,
          },
        })
        .then(data => {
          if (data.data.updateOrderStatuses === true) {
            debugger;
            this.getAllOrders();
          }
        });
    },
    onOrderSelect() {
      let selectedOrders = this.allOrders.filter(x => x.isChecked);
      if (selectedOrders.length) {
        this.isAnyOrderSelected = true;
      } else {
        this.isAnyOrderSelected = false;
      }

      let dispatchedOrders = selectedOrders.filter(x => x.deliveryStatus == "DISPATCHED");

      let deliveredOrders = selectedOrders.filter(x => x.deliveryStatus == "DELIVERED");

      let cancelledOrders = selectedOrders.filter(x => x.deliveryStatus == "CANCELLED");

      let pendingOrders = selectedOrders.filter(x => x.deliveryStatus == "PENDING");

      this.showMarkAsDespatch = true;
      this.showMarkAsDeliverd = true;
      this.showEditOrder = false;
      this.showProductReturn = false;
      if (selectedOrders == 0) {
        this.showMarkAsDespatch = false;
        this.showMarkAsDeliverd = false;
      }

      if (dispatchedOrders.length > 0) {
        this.showMarkAsDespatch = false;
      }

      if (deliveredOrders.length > 0) {
        this.showMarkAsDespatch = false;
        this.showMarkAsDeliverd = false;
      }

      if (cancelledOrders.length > 0) {
        this.showMarkAsDespatch = false;
        this.showMarkAsDeliverd = false;
      }
      if (selectedOrders.length == 1 && pendingOrders.length == 1) {
        this.showEditOrder = true;
      } else if (selectedOrders.length == 1 && dispatchedOrders.length == 1) {
        this.showEditOrder = true;
      }
      if (selectedOrders.length == 1 && deliveredOrders.length == 1) {
        this.showProductReturn = true;
      }
    },
    editOrder() {
      let selectedOrder = this.allOrders.filter(x => x.isChecked)[0];
      this.orderEditCustomerId = selectedOrder.customer._id;
      this.isEdit = true;
      this.orderToEdit = selectedOrder;
      this.showSideDrawer("EditUserProduct");
    },
    productReturn() {
      let selectedOrder = this.allOrders.filter(x => x.isChecked)[0];
      this.productReturnCustomerId = selectedOrder.customer._id;
      this.orderToEdit = selectedOrder;
      this.showSideDrawer("ProductReturnForm");
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer>>>tbody tr :hover {
  cursor: pointer;
}
</style>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
