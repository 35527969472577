<template>
  <v-container fluid class="px-0">
    <v-row>
      <h3 class="black--text mt-6 ms-3 mb-4">Ingredients</h3>
    </v-row>

    <v-row align="center" class="mt-n4 mt-md-2">
      <v-col cols="8" sm="6" lg="4">
        <v-text-field
          placeholder="Name.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              color="black"
              v-if="search.length > 0"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon color="black" v-if="search.length == 0" @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <app-master-form
          :unitList="unitList"
          v-if="hasRole(AdminUserPermissions.MASTER_DATA_ADD)"
        ></app-master-form>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="6" lg="2" sm="4">
        <StockMasterExport :unitList="unitList"/>
      </v-col>
      <v-col cols="6" lg="1" sm="2">
        <AuditTrialLog auditType="INGREDIENTS_LOG" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" lg="1" sm="2">
        <ImportIngredient  />

      </v-col>
        
    </v-row>
    <app-master-table
      class="mt-n14 ms-n2"
      :searchKey="searchKey"
      :unitList="unitList"
    ></app-master-table>
  </v-container>
</template>

<script>
import ImportIngredient from "../components/ImportIngredient.vue"
import StockMasterTable from "../components/StockMasterTable.vue";
import StockMasterForm from "../components/StockMasterForm.vue";
import StockMasterExport from "../components/export/StockMaster.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  components: {
    "app-master-table": StockMasterTable,
    "app-master-form": StockMasterForm,
    AuditTrialLog,
    StockMasterExport,
    ImportIngredient
  },

  methods: {
    getAllUnits() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAllUnits.graphql"),
          variables: this.variables
        })
        .refetch({})
        .then(data => {
          if (data.data) {
            this.unitList = data.data.getAllUnits.units;
          }
        });
    },
    clearSearch() {
      this.search = "";
      this.searchKey = "";
    },
    runSearch() {
      this.searchKey = this.search;
    }
  },
  data: () => ({
    search: "",
    searchKey: "",
    unitList: []
  }),
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MASTER_DATA_VIEW)) {
      if (
        this.hasRole(this.AdminUserPermissions.STOCK_VIEW) ||
        this.AdminUserPermissions.PURCHASE_VIEW ||
        this.AdminUserPermissions.ADJUST_STOCK_VIEW
      ) {
        this.$router.push("/stock");
      } else
        this.$router.push({
          name: "Error404"
        });
    }
    this.getAllUnits(); 
  }
};
</script>
