<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-progress-linear v-if="loading" color="primary"></v-progress-linear>
    <!-- {{ dietitian }} -->
    <div v-if="dietitian.user">
      <!-- {{ dietitian.image }} -->
      <v-row>
        <v-col cols="4" md="1">
          <v-avatar :width="80" :height="80">
            <v-img
              v-if="dietitian.image != null"
              :src="imageUrl + dietitian.image"
            ></v-img>
            <v-img
              v-else
              :src="require('../../assets/user.jpg')"
              contain
              :position="!$vuetify.rtl ? 'left' : 'right'"
            />
          </v-avatar>
        </v-col>
        <v-col cols="6" md="4" align-self="center" class="ms-sm-4">
          <v-row>
            <h2 class="black--text mb-n2">
              {{ dietitian.user.firstnameEn }}
              {{ dietitian.user.lastnameEn }}
              <a
                v-if="dietitian.image != null"
                :href="imageUrl + dietitian.image"
                download
              >
                <v-icon>mdi-cloud-download</v-icon>
              </a>
            </h2>
          </v-row>
          <v-row class="mt-4">
            <h4>{{ dietitian.descriptionEn }}</h4>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-8 mt-sm-0">
        <!-- {{dietitian.slotTiming}} -->
        <v-spacer></v-spacer>
        <DietitianSetting
          :dietitian="dietitian"
          v-if="hasRole(AdminUserPermissions.DIETITIAN_SETTINGS)"
        />

        <app-dietitian-form
          class="ml-5 "
          v-if="hasRole(AdminUserPermissions.DIETITIAN_EDIT)"
          :dietitian="dietitian"
          :buttonMode="true"
        ></app-dietitian-form>
        <!-- <ReferralTransations :user="dietitian.user" isUserTable="false" /> -->
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <DietitianInfo :dietitian="dietitian" />
        </v-col>
        <v-divider
          vertical
          class="mx-md-4"
          v-show="$vuetify.breakpoint.smAndUp"
        ></v-divider>
        <v-divider class="mx-6" v-show="$vuetify.breakpoint.xs"></v-divider>
        <v-col cols="12" sm="6" md="5">
          <DietitianPersonalInfo :dietitian="dietitian" />
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <v-layout wrap class="mt-8 mb-6">
        <v-tabs
          v-model="tabs"
          :mobile-breakpoint="0"
          background-color="bg"
          slider-size="3"
          slider-color="black"
        >
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
            >Pending Appointments</v-tab
          >
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
            >Completed Appointments</v-tab
          >
        </v-tabs>
      </v-layout>
      <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0">
        <v-tab-item>
          <DietitianAppointments :dietitian_id="dietitian._id" type="PENDING" />
        </v-tab-item>
        <v-tab-item>
          <DietitianAppointments
            :dietitian_id="dietitian._id"
            type="COMPLETED"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import DietitianUpdateForm from "../components/DietitianUpdateForm.vue";
import DietitianInfo from "@/dietitian/components/DietitianInfo.vue";
import DietitianPersonalInfo from "@/dietitian/components/DietitianPersonalInfo";
import DietitianAppointments from "../components/DietitianAppointments.vue";
// import ReferralTransations from "@/referral-transactions/components/ReferralTransations.vue";
import DietitianSetting from "../dietitian_settings/components/DietitianSetting.vue";
export default {
  components: {
    "app-dietitian-form": DietitianUpdateForm,
    DietitianInfo,
    DietitianAppointments,
    DietitianPersonalInfo,
    // ReferralTransations,
    DietitianSetting
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getDietitianDetails();
  },
  mounted() {
    this.$root.$on("updateGetDietitianById", () => {
      this.getDietitianDetails();
    });
  },
  watch: {
    "$route.query.dietitianId": {
      handler() {
        this.getDietitianDetails();
      }
    }
  },
  methods: {
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    getDietitianDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getDietitianById.graphql"),
          variables: {
            id: this.$route.query.dietitianId
          }
        })
        .refetch({
          variables: {
            id: this.$route.query.dietitianId
          }
        })
        .then(data => {
          this.loading = false;

          if (data.data.getDietitianById) {
            this.dietitian = data.data.getDietitianById;
            console.log(this.dietitian) 
            
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      isDisable: true,
      imageUrl: process.env.VUE_APP_BASE_URL,
      dietitian: this.$route.query.dietitianId,
      snackbar: false,
      text: "",
      tabs: null,
      btnloading: false,
      loading: true
    };
  }
};
</script>
