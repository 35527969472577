<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn
          class="black--text text-capitalize mt-1 mb-5"
          color="secondary"
          v-on="on"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon>Add
        </v-btn>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2"
            >Add Dietitian Appointment</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
          <v-form @submit="createAppointment()" v-model="valid" ref="form">
            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Date</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate"
                      readonly
                      label="Date"
                      :rules="[requiredValidator('date')]"
                      solo
                      dense
                      append-icon="mdi-calendar-month-outline"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                    ></v-text-field>
                  </template>
                  <!-- @change="menu = false" -->
                  <v-date-picker
                    v-model="date"
                    :min="currentDate"
                    @change="onChangeDietitian"
                    color="secondary"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <!-- offset-md-1 -->
            <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Dietitian</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <!-- item-text="user.firstnameEn" -->
                <v-autocomplete
                  label="Dietitian"
                  v-model="dietitian"
                  solo
                  dense
                  
                  :rules="[requiredValidator('Dietitian'), dietitianOff()]"
                  :items="dietitianList"
                  item-text="user.firstnameEn"
                  item-value="_id"
                  clearable
                  deletable-chips
                  return-object
                  @change="onChangeDietitian('data')"
                >
                  <template slot="no-data">
                    <v-flex xs12 md12 class="mt-2 mb-2">
                      <h5 class="font-weight-medium ml-4">
                        Search dietitian by name...
                      </h5>
                    </v-flex>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.user.firstnameEn }}
                    {{ data.item.user.lastnameEn }}
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>

            <!-- <v-layout wrap>
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Time</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      label="Time"
                      :rules="[requiredValidator('time')]"
                      solo
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu1"
                    v-model="time"
                    full-width
                    color="secondary"
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout> -->
            <v-layout wrap v-if="dietitian">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">
                  Mode of appointment
                </h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-select
                  :items="modeList"
                  :rules="[requiredValidator('Mode of appointment')]"
                  v-model="mode"
                  item-text="text"
                  item-value="value"
                  dense
                  attach
                  solo
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="dietitian && mode">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">
                  {{ mode == "ON_CALL" ? "Fee" : "BookingFee" }}
                </h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-text-field
                  :value="formatFee()"
                  readonly
                  dense
                  attach
                  solo
                  @change="shiftChange"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="date && dietitian">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Shift</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-select
                  :items="shiftList"
                  :rules="[requiredValidator('shift')]"
                  v-model="shift"
                  dense
                  attach
                  solo
                  @change="onShiftChange"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="shift">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Slot</h5>
              </v-flex>
              <!-- {{shiftSlot}}  -->
              <v-flex xs12 sm7 md7>
                <v-combobox
                  :items="shiftSlotList"
                  :rules="[requiredValidator('slot')]"
                  v-model="shiftSlot"
                  dense
                  solo
                  item-text="startTime"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="shift">
              <v-flex xs12 sm4 md3>
                <h5 class="font-weight-medium text-body-2">Payment Method</h5>
              </v-flex>
              <v-flex xs12 sm7 md7>
                <v-select
                  :items="avalilablePaymentMethod"
                  :rules="[requiredValidator('payment method')]"
                  v-model="paymentMethod"
                  dense
                  solo
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row class="mt-4" justify-center>
              <v-btn
                width="120"
                color="secondary"
                class="text-capitalize black--text"
                @click="createAppointment()"
                :loading="btnloading"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-layout>
          </v-form>
        </v-container>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requiredValidator } from "../../../core/methods/validations";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  components: {},
  props: {
    customer_id: {
      required: true
    }
  },
  apollo: {
    getAllDietitians() {
      return {
        query: require("@/dietitian/api/getAllDietitians.graphql"),
        variables: {
          skip: 0,
          limit: 1000,
          searchKey: ""
        },
        result({ data, loading }) {
          this.loading = loading;

          this.dietitianList = data.getAllDietitians.dietitians.filter(dietitian=>dietitian.isActive != false); //filter not active dietitians
        }
      };
    }
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[2];
      this.avalilablePaymentMethod.push(data);
    }
  },
  data() {
    return {
      avalilablePaymentMethod: [],

      paymentMethod: "",
      date: "",
      time: "",
      menu: "",
      menu1: "",
      dietitianLoading: false,
      dietitiansSearchKey: "",
      dietitianList: [],
      dietitian: "",
      awaitingSearch: false,
      btnDisabled: false,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackColor: "",
      snackbar: false,
      text: "",
      variables: {
        limit: 0,
        skip: 0
      },
      shiftList: ["Morning Shift", "Evening Shift"],
      shift: "",
      shiftSlotList: [],
      shiftSlot: "",
      slotList: null,
      slot: "",
      paymentMethods: [
        { text: "Online", value: "ONLINE" },
        { text: "Offline", value: "OFFLINE" },
        { text: "Free", value: "FREE" }
      ],
      isDietitianOff: false,
      dietitianLeaveSlot: null,
      mode: "",
      slotMasterId: "",
      slotId: "",
      modeList: []
    };
  },
  methods: {
    feeModeChange() {
      console.log("changed");
    },
    onShiftChange() {
      this.shiftSlot = "";
      this.shiftChange();
    },
    dietitianOff() {
      if (this.isDietitianOff) {
        return "Dietitian is Off on this day";
      }
      if (this.dietitianLeaveSlot == "FULL_DAY") {
        return "Dietitian is leave on this day";
      }
      return true;
    },
    shiftChange() {
      if (this.slotList !== null) {
        this.shiftSlotList = this.slotList.slots.filter(
          x => x.shift == this.shift && x.customer == null
        );
      } else {
        this.shiftSlotList = [];
      }
    },
    onChangeDietitian(data) {
      console.log("hey");
      this.shiftSlot = "";
      this.menu = false;
      if (data) {
        this.mode = "";
        let modeArray = new Array();
        this.modeList = [];
        console.log(this.dietitian);

        if (this.dietitian.consultationMode)
          if (this.dietitian.consultationMode.length) {
            modeArray = this.dietitian.consultationMode;
          }
        if (modeArray.includes("ON_CALL")) {
          let data = { text: "On Call", value: "ON_CALL" };
          this.modeList.push(data);
        }
        // else {
        //   this.modeList.filter(x => x.value !== "ON_CALL");
        // }
        if (modeArray.includes("ON_VISIT")) {
          let data = { text: "Visit", value: "ON_VISIT" };
          this.modeList.push(data);
        }
        //  else {
        //   this.modeList.filter(x => x.value !== "ON_VISIT");
        // }
      }
      if (this.date && this.dietitian) {
        this.getDietitianSlots();
      }
    },
    getDietitianSlots() {
      this.dietitianLeaveSlot = null;
      this.$apollo
        .watchQuery({
          // .query({
          query: require("@/dietitian/dietitian_settings/api/getDietitanSlots.graphql"),
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
            // dietitianId: "61e3b93ba4581739e42ae9db",
            // date: "2022-01-26"
          }
        })
        .refetch({
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
          }
        })
        .then(data => {
          if (data.data) {
            console.log(data.data.getAvailableSlot);
            this.slotList = data.data.getAvailableSlot;
            this.slotMasterId = this.slotList._id;
            this.isDietitianOff = false;
            this.shiftChange();
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            // console.error(error.graphQLErrors);
            this.shiftChange();
            if (error.graphQLErrors[0].message === "DIETITIAN_IS_OFF") {
              this.isDietitianOff = true;
              this.slotList = null;
              this.shiftChange();
            } else if (error.graphQLErrors[0].message === "FULL_DAY") {
              console.log("full day");
              this.dietitianLeaveSlot = "FULL_DAY";
            } else {
              this.text = "Something bad is happend";
              this.snackColor = "error";
              this.snackbar = true;
            }
          }
          this.btnloading = false;
        });
    },
    formatFee() {
      if (this.dietitian) {
        let data = 0;
        if (this.mode == "ON_CALL") {
          if (this.dietitian.callModeFee) data = this.dietitian.callModeFee.fee;
        } else if (this.mode == "ON_VISIT") {
          if (this.dietitian.visitModeBookingFee)
            data = this.dietitian.visitModeBookingFee.fee;
        }
        return data;
      }
    },
    close() {
      this.show_dialog_box = false;
    },
    createAppointment() {
      this.btnloading = true;
      // "Morning Shift", "Evening Shift"
      let shift;
      this.shift == "Morning Shift"
        ? (shift = "MORNING_SHIFT")
        : (shift = "EVENING_SHIFT");
      let input = {
        customer: this.customer_id,
        appointmentTime: this.shiftSlot.startTime,
        appointmentDate: this.date,
        dietitian: this.dietitian._id,
        slotId: this.shiftSlot._id,
        paymentMethod: this.paymentMethod,
        modeOfAppointment: this.mode,
        shift
      };
      this.$apollo
        .mutate({
          mutation: require("@/dietitian/dietitian_settings/api/createSlotAppointment.graphql"),
          variables: {
            input
          }
        })
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.text = "Dietitian appoinment added";
          this.$root.$emit("updateDietitianAppointments");
          this.$emit("updateDietitianAppointment");
          this.$refs.form.reset();
          this.btnloading = false;
          this.close();
        })
        .catch(error => {
          this.btnloading = false;
          console.log(error);
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            if (error.graphQLErrors[0].message === "ALREADY_CREATED") {
              this.text = "Appoinment already created";
              this.snackColor = "error";
              this.snackbar = true;
            }
          }
        });
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
    currentDate() {
      let data = new Date();
      return moment(data).format("YYYY-MM-DD");
    }
  }
};
</script>
