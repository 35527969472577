<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <div v-if="salesOrder == 'SalesOrder'">
      <v-layout wrap>
        <h2 class="black--text text-h5 font-weight-bold">Sales Orders</h2>
      </v-layout>
      <app-salesorder-table></app-salesorder-table>
    </div>
<div v-if="salesOrder == 'SalesOrderPending'">
      <v-layout wrap>
        <h2 class="black--text text-h5 font-weight-bold">Sales Orders Pending</h2>
      </v-layout>
      <SalesOrderPendingTable/>
    </div>
    <div v-if="salesOrder == 'SalesOrderDayWise'">
      <v-layout wrap>
        <h2 class="black--text text-h5 font-weight-bold">
          Sales Order Day Wise
        </h2>
        <SalesOrderDayWiseTable />
      </v-layout>
    </div>
    <div v-if="salesOrder == 'SalesOrderDiscount'">
      <v-layout wrap>
        <h2 class="black--text text-h5 font-weight-bold">
          Sales Order Discount
        </h2>
        <SalesOrderDiscount />
      </v-layout>
    </div>
    <div v-if="salesOrder == 'SalesOrderStatus'">
      <v-layout column>
        <!-- <h2 class="black--text text-h5 font-weight-bold">
          Sales Order Status
        </h2> -->
        <SalesOrderPendingApproved />
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import SalesOrderTable from "../components/SalesOrderTable.vue";
import SalesOrderPendingTable from "../components/SalesOrderPendingTable.vue";
import SalesOrderDayWiseTable from "../components/SalesOrderDayWiseTable.vue";
import SalesOrderDiscount from "../components/SalesOrderDiscount.vue";
import SalesOrderPendingApproved from "../components/SalesOrderPendingApproved.vue";
export default {
  components: {
    "app-salesorder-table": SalesOrderTable,
    SalesOrderDayWiseTable,
    SalesOrderDiscount,
    SalesOrderPendingApproved,
   SalesOrderPendingTable
  },
  data() {
    return {
      salesOrder: "",
      routeName: "",
      startDatexx: "",
      endDate: "",
      textFields: [],
      tags: [],
      variables: {
        limit: 25,
        skip: 0
      },
      loading: true,
      areaList: null,
      totalItemsCount: null,
      area: {
        NameEn: "",
        NameAr: ""
      }
    };
  },
  created() {
    if (
      !this.hasRole(this.AdminUserPermissions.SALES_ORDER_VIEW) &&
      !this.hasRole(this.AdminUserPermissions.SALES_ORDER_DAY_WISE_VIEW)
    ) {
      this.$router.push({
        name: "Error404"
      });
    } else {
      this.routeName = this.$route.name;
      this.routePage();
    }
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    fetchData() {
      this.routeName = this.$route.name;
      this.routePage();
    },
    routePage() {
      this.salesOrder = this.routeName;
    }
  }
};
</script>
