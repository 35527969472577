<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="black--text text-h5 font-weight-bold ms-3 mb-6">Create Order</h2>
    <!-- Hello {{ products }} -->
    <ShoppingDialog @updateShopping="updateShopping" @onOrder="onOrder" @updateShoppingAddress="updateShoppingAddress"
      @updateShoppingInfo="updateShoppingInfo" :btnloading="btnloading" :fromSales="false" />


    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
  </v-container>
</template>

<script>
import ShoppingDialog from "../components/ShoppingDialog.vue";

export default {
  components: { ShoppingDialog },
  mounted() { },
  methods: {
    updateShoppingInfo(input) {
      debugger;
      this.paymentMethod = input.paymentMethod;
      this.shoppingDiscount = input.discount;

    },
    updateShoppingAddress(selectdAddress) {
      debugger

      this.selectdAddress = selectdAddress;
    },
    updateShopping(input) {
      debugger;
      this.shoppingDiscount = input.discount;
      this.shoppingNetAmount = 0;
      this.shoppingTotal = 0;
      this.paymentMethod = input.paymentMethod;

      let totalPrice = 0;
      input.purchaseProducts.forEach((element) => {
        totalPrice =
          totalPrice +
          parseFloat(element.product.price) * parseInt(element.quantity);
      });
      this.purchaseProducts = input.purchaseProducts;
      this.shoppingTotal = totalPrice;
      this.shoppingNetAmount =
        totalPrice - parseFloat(input.discount ? input.discount : 0);
      if (this.shoppingNetAmount < 0) {
        this.shoppingDiscount = 0;
        this.shoppingNetAmount = totalPrice;
      }
    },
    onOrder() {
      debugger;

      let productsInput = new Array();
      this.purchaseProducts.forEach((product) => {
        productsInput.push({
          product: product.id,
          quantity: parseInt(product.quantity),
        });
      });
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../../customer/api/adminCheckoutCustomerPackage.graphql"),
          variables: {
            checkoutInput: {
              customerId: this.$route.query.customerId,
              paymentMethod: this.paymentMethod,
            },

            shoppingInput: {
              products: productsInput,
              addressId: this.selectdAddress,
              discount: parseFloat(this.shoppingDiscount),
            },
          },
        })
        .then((data) => {
          debugger;
          console.log(data);
          this.snackbar = true;
          this.text = "Customer Order Placed";
          this.$root.$emit("updateGetCustomerById");
          this.$root.$emit("orderPlaced");
          this.snackColor = "success";
          // this.$refs.form.reset();

          this.$router.push({
            name: "CustomerDetails",
            query: { customerId: this.$route.query.customerId, msg: "success" },
          });
          this.btnloading = false;
        })
        .catch((error) => {
          debugger;
          this.snackbar = true;
          this.snackColor = "error";
          this.text = "Something went wrong";
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
  },
  data() {
    return {
      loading: false,
      shoppingNetAmount: 0,
      totalPrice: 0,
      shoppingDiscount: 0,
      shoppingTotal: 0,
      purchaseProducts: "",
      selectdAddress: "",
      paymentMethod: "",
      snackColor: "",
      text: "",
      snackbar: false,
      btnloading: false,
    };
  },

  created() { },
};
</script>
