<template>
  <div class="pt-5">
    <v-card class="mx-4" outlined>
      <div class="mt-5">
        <span
          @click="$router.push({ name: 'PackageReport' })"
          class="ms-4 primary--text"
          style="cursor: pointer"
        >
          <v-icon color="primary" class="me-1"> mdi-arrow-left</v-icon>back
        </span>
        <div class="py-2 ps-4">
          <div class="d-flex justify-space-between">
            <p class="primary--text font-weight-bold text-h5">
              {{ packageName }}
            </p>
            <span class="me-4">
              <!-- <ExportPackageReview :headers="headers" :packageId="packageId" :dateRange="{fDate,tDate}"/> -->
            </span>
          </div>
          <v-row>
            <v-col cols="2"> Total Subscribers :</v-col>
            <v-col cols="4">
              <span> {{ totalCount }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-0">Total Income :</v-col>
            <v-col cols="4" class="pt-0">
              <span>{{ totalIncome.toFixed(2) }} KD</span>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-layout class="ps-2 mt-4">
        <v-flex xs7 sm3 md2>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="fromDate"
                label="From"
                outlined
                class="ms-md-2"
                append-icon="mdi-calendar-month-outline"
                v-bind="attrs"
                v-on="on"
                @click:clear="fDate = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fDate"
              @change="selectFromDate"
              :max="fromDateMaxDates"
            ></v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs7 sm3 md2>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="toDate"
                label="To"
                class="ms-sm-2"
                outlined
                append-icon="mdi-calendar-month-outline"
                v-bind="attrs"
                v-on="on"
                @click:clear="tDate = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="tDate"
              @change="selectToDate"
              :max="toDateMaxDates"
              :min="toDateMinDates"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-btn
          @click="getAllCustomersInCurrentPackage"
          class="primary--text ms-4 elevation-0"
          outlined
          >Search</v-btn
        >
      </v-layout>
      <div class="ms-4 mb-6">
        <v-row>
          <v-col cols="2">Customers : </v-col>
          <v-col cols="4">
            <span> {{ count }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="pt-0">Income :</v-col>
          <v-col cols="4" class="pt-0">
            <span> {{ income.toFixed(2) }} KD</span>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="customers"
      :items-per-page="10"
      class="elevation-1 mt-5 mx-4"
      :loading="loading"
      :options.sync="options"
      :server-items-length="count"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }"
    >
      <template v-slot:item.customerId="{ item }">
        {{ item.customerId }}
      </template>
      <template v-slot:item.name="{ item }">
        <router-link
          v-if="item.user"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item._id },
            query: { customerId: item._id },
          }"
          class="mx-auto"
        >
          {{
            item.user.firstnameEn[0].toUpperCase() +
            item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
            item.user.lastnameEn.substring(1)
          }}
        </router-link>
        <!-- </v-row> -->
      </template>
    </v-data-table>
  </div>
</template>
  
  <script>
import moment from "moment";
// import ExportPackageReview from "@/reports/components/ExportPackageReview.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    // ExportPackageReview
  },
  data() {
    return {
      packageId: null,
      packageName: "",
      menu1: false,
      menu2: false,
      count: 0,
      income: 0,
      totalCount: 0,
      totalIncome: 0,

      options: {
        itemsPerPage: 10,
        page: 1,
      },
      variables: {
        limit: 10,
        skip: 0,
      },

      fDate: format(
        parseISO(moment().subtract(1, "month").toDate().toISOString()),
        "yyyy-MM-dd"
      ),
      tDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      customers: [],
      loading: false,
      headers: [
        {
          text: "#",
          value: "customerId",
          width: 100,
        },
        // { text: "Avatar", value: "photo" },
        { text: "Customer Name", value: "name", width: 150 },
        {
          text: "Contact Number",
          value: "user.phone",
          width: 150,
        },
      ],
    };
  },
  created() {
    this.packageId = this.$route.params.packageId;
    this.packageName = this.$route.params.packageName;
    if (!this.packageId) {
      this.$router.push({
        name: "PackageReport",
      });
    }
  },
  methods: {
    selectFromDate() {
      this.menu1 = false;
      let diff = moment(new Date(this.tDate)).diff(
        moment(new Date(this.fDate)),
        "days"
      );
      if (diff > 90) {
        let momentDate = moment(new Date(this.fDate)).add("days", 90);
        this.tDate = format(
          parseISO(momentDate.toDate().toISOString()),
          "yyyy-MM-dd"
        );
      }
    },
    selectToDate() {
      this.menu2 = false;
      let diff = moment(new Date(this.tDate)).diff(
        moment(new Date(this.fDate)),
        "days"
      );
      if (diff > 90) {
        let momentDate = moment(new Date(this.tDate)).add("days", -90);
        this.fDate = format(
          parseISO(momentDate.toDate().toISOString()),
          "yyyy-MM-dd"
        );
      }
    },
    getAllCustomersInCurrentPackage() {
      this.loading = true;
      if (!this.packageId) {
        return false;
      }
      this.$apollo
        .query({
          query: require("../api/getAllCustomersInCurrentPackage.graphql"),
          variables: {
            input: this.variables,
            packageId: this.packageId,
            fromDate: this.fDate,
            toDate: this.tDate,
          },
        })
        .then((data) => {
          if (data.data) {
            this.loading = false;
            const { count, income, totalCount, totalIncome, customers } =
              data.data.getAllCustomersInCurrentPackage;
            this.count = count;
            this.income = income;
            this.totalCount = totalCount;
            this.totalIncome = totalIncome;
            this.customers = customers;
          }
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.variables.limit = this.options.itemsPerPage;
        this.variables.skip =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
        this.getAllCustomersInCurrentPackage();
      },
      deep: true,
    },
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format("DD/MM/YYYY") : "";
    },
    fromDateMinDates() {
      return this.tDate
        ? moment(this.tDate).subtract(1, "month").format("YYYY-MM-DD")
        : "";
    },
    fromDateMaxDates() {
      let today = moment().format("YYYY-MM-DD");
      return today;
    },
    toDateMaxDates() {
      let today = moment();
      return today.format("YYYY-MM-DD");
    },
    toDateMinDates() {
      return this.fDate ? moment(this.fDate).format("YYYY-MM-DD") : "";
    },
  },
};
</script>
  
  <style></style>
  