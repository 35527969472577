<template>
  <div>
    <v-btn small width="100" :loading="loading" @click="exportExcel" color="primary"
      class="white--text text-capitalize mt-5">
      <v-icon size="17" class="me-1">mdi-export</v-icon>Export
    </v-btn>

    <div v-show="false">
      <v-data-table :headers="headers" :items="orders" :id="`tblOrders_${type}`" :items-per-page="-1" hide-default-footer>
        <template v-slot:[`item.orderNumber`]="{ item }">
          {{ item.orderNumber }}
        </template>

        <template v-slot:[`item.customer`]="{ item }">
          <router-link v-if="!item.is_deleted" :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id },
          }
            " class="mx-auto">
            {{ toTitleCase(item.customer.user.firstnameEn) }}
            {{ toTitleCase(item.customer.user.lastnameEn) }}
            <!-- <div class="mx-1" /> -->
          </router-link>

          <span v-else>
            <!-- <div class="mx-1" /> -->
            {{ toTitleCase(item.customer.user.firstnameEn) }}
            {{ toTitleCase(item.customer.user.lastnameEn) }}
          </span>
        </template>

        <template v-slot:[`item.phone`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0" v-if="item.customer">
            {{ item.customer.user.phone }}
          </h4>
        </template>

        <template v-slot:[`item.zone`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0" v-if="item.zone">
            {{ item.zone ? item.zone.zoneName : "-" }}
          </h4>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0">
            {{ numberWithCommas(item.total) }}
          </h4>
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0">
            {{ numberWithCommas(item.discount) }}
          </h4>
        </template>
        <template v-slot:[`item.netPrice`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0">
            {{ numberWithCommas(item.netPrice) }}
          </h4>
        </template>

        <template v-slot:[`item.paymentStatus`]="{ item }">
          <h4 class="font-weight-medium my-0 py-0">
            {{ item.paymentStatus }}
          </h4>
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
          <h4 class="grey1--text font-weight-medium my-0 py-0" v-if="item.createdBy">
            {{ item.createdBy.firstnameEn }} {{ item.createdBy.lastnameEn }}
          </h4>
        </template>
        <template v-slot:[`item.orderDate`]="{ item }">
          <h4 class="font-weight-medium my-0 py-0 gray2--text">
            {{
              moment(new Date(parseInt(item.createdAt))).format("DD MMM YYYY")
            }}
          </h4>
        </template>

        <!-- <template v-slot:[`item.action`]="{ item }"> </template> -->
      </v-data-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Utils } from "../../utils/utils";
export default {
  components: {},
  computed: {
    toDateFormatted() {
      return this.toDate ? moment(this.toDate).format("DD/MM/YYYY") : "";
    },
    fromDateFormatted() {
      return this.fromDate ? moment(this.fromDate).format("DD/MM/YYYY") : "";
    },
  },
  props: {
    headers: {
      required: true,
    },
    deliveryStatus: {
      required: false,
    },
    paymentStatus: {
      required: false,
    },
    fromDate: {
      required: true,
    },
    toDate: {
      required: true,
    },
    zone: {
      required: false,
    },
    type: {
      required: false,
    },
    customer: {
      required: false,
    },
    fromCustomer: {
      required: false,
    },
  },

  data() {
    return {
      moment: moment,
      loading: false,
      orders: [],
      dialog: false,
      menuFromDate: false,
      menuToDate: false,

      valid: true,
      snackbar: false,
      text: "",
    };
  },
  methods: {
    numberWithCommas(x) {
      if (x)
        return x
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return 0;
    },

    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    selectFromDate() {
      this.menuFromDate = false;
      this.menuToDate = false;
    },

    getAllOrders() {
      this.loading = true;
      let pageInput = {
        skip: 0,
        limit: 0,
      };

      let filter = {
        paymentStatus: this.paymentStatus,
        deliveryStatus: this.deliveryStatus,
        zone: this.zone,
        tab: this.type,
        fromDate: this.fromDate,
        toDate: this.toDate,
        customer: this.customer ? this.customer._id : "",
      };
      this.$apollo
        .watchQuery({
          query: require("../api/listOrders.graphql"),
          variables: {
            input: pageInput,
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .refetch({
          variables: {
            input: pageInput,
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .then((data) => {
          this.allOrders = [];
          this.totalOrders = 0;
          if (data.data.listOrders) {
            this.orders = data.data.listOrders.orders;
          }
          //
          if (this.orders.length) {
            setTimeout(() => {
              this.loading = false;
              Utils.htmlToExcel("tblOrders_" + this.type, "orders", false);
            }, 2000);

          } else {
            this.snackbarColor = "red";
            this.text = "No Data is available on selected dates";
            this.snackbar = true;
          }
          this.loading = false;
          return true;
          //
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    exportExcel() {
      this.getAllOrders();
    },
  },
};
</script>
