
<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <DietitianSlotAppointments /> 
  </v-container>
</template>

<script>
  import DietitianSlotAppointments from "@/dietitian/dietitian_settings/pages/DietitianSlotAppointments.vue";

  export default {
    components: {
      DietitianSlotAppointments,
    },
    mounted() {
    this.$root.$on("updateAppointment", () => {
      this.loading = true;
      this.getAllActiveAppoinmentsByUserID();
    });
  },
  created() {
    this.getAllActiveAppoinmentsByUserID();
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false
        },
        { text: "Customer Name", value: "name", sortable: false },
        {
          text: "Contact Number",
          value: "customer.user.phone",
          sortable: false
        },
        { text: "Date", value: "createdDate", sortable: false },
        {
          text: "Appointment Date and Time",
          value: "appointmentTime",
          sortable: false
        },

        { text: "Actions", value: "actions", sortable: false }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 0,
        page: 1
      },
      variables: {
        limit: 0,
        skip: 0
      },
      loading: true
    };
  },

  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  },
  methods: {
    getAllActiveAppoinmentsByUserID() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllActiveAppoinmentsByUserID.graphql"),
          variables: this.variables
        })
        .refetch({
          variables: this.variables
        })
        .then(data => {
          this.loading = false;

          this.customerList =
            data.data.getAllActiveAppoinmentsByUserID.appointments;
          this.totalItemsCount =
            data.data.getAllActiveAppoinmentsByUserID.totalCount;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  }
  }
</script>