<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="red" class="ms-2">mdi-delete</v-icon>
      </template>
      <v-card class="pa-6" color="pop_bg">
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text">Do you really want to delete this?</v-card-text>

        <v-card-text v-if="error" class="error--text font-weight-bold">{{ error }}</v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 success"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                deleteMethod(category._id);
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      button_loading: false,
      show_alert: false,
      error: null,
      snackbar: false,
      snackbarColor: "",
      text: "",
    };
  },
  methods: {
    deleteMethod(id) {
      //   console.log(id);
      this.$apollo
        .mutate({
          mutation: require("../api/deleteProductCategory.graphql"),
          variables: {
            id: id,
          },
        })
        .then(() => {
          this.$root.$emit("updateProductCategory");
          this.button_loading = false;
          this.error = null;
          this.dialog = false;
          this.text = "Product Category Deleted";
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
            this.text = error.graphQLErrors[0].message;
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dialog = false;
          }
          this.button_loading = false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
    },
  },
};
</script>
