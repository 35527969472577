var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0"},[_c('v-btn',{staticClass:" text-capitalize",attrs:{"outlined":"","disabled":_vm.customersList.length == 0,"loading":_vm.loading,"width":"150","color":"primary"},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("fa-file-export")]),_vm._v("Export ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"id":"exportDriverDeliveryReport","hide-default-footer":"","items-per-page":-1,"headers":_vm.headers,"items":_vm.customers},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item._id },
            query: { customerId: item._id }
          }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }