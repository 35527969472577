<template>
    <div>
        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
        <v-dialog v-model="dialog" max-width="800px" v-if="hasRole(AdminUserPermissions.SHOP_ORDER_UPDATE)">
            <template v-slot:activator="{ on }">
                <v-btn v-if="reassign" v-on="on" class="primary" style="border-radius: 8px;"> <v-icon small
                        class="mr-1">mdi-sync-circle</v-icon> Reassign</v-btn>
                <v-btn v-else v-on="on" color="success" outlined text>Assign Driver</v-btn>

            </template>
            <v-card class=" pop_bg">
                <v-card-title>
                    <span class="text-h6 black--text"></span>
                    <v-spacer></v-spacer>
                    <v-btn color="black" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="subtitle-1 mb-0 mt-6">
                    <v-layout>
                        <v-flex class="black--text">
                            Assign Driver
                        </v-flex>
                    </v-layout>
                    <v-layout>
                        <v-flex class="black--text">
                            <v-autocomplete v-model="driver" clearable @change="driverUpdate(item)" :items="drivers" solo
                                item-text="user.firstnameEn" item-value="_id" label="Driver" dense>
                                <template slot="item" slot-scope="data">{{ data.item.user.firstnameEn }}, {{
                                    data.item.user.lastnameEn
                                }}</template>
                            </v-autocomplete>

                        </v-flex>
                    </v-layout>
                    <v-layout>
                        <v-flex class="error--text" v-if="!loading">
                            <strong> Pending Orders to deliver : {{ driverOrders.length }}</strong>
                        </v-flex>
                        <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                    </v-layout>
                </v-card-text>
                <v-card-actions class="mt-n8 justify-end">
                    <v-btn color="secondary" class="mb-2 mb-sm-4 text-capitalize"
                        :width="$vuetify.breakpoint.smAndUp ? 120 : 90" @click="assignDriver" :loading="btnloading"
                        :disabled="!valid">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
export default {
    props: {
        item: {
            required: true
        },
        reassign: {
            required: true
        }
    },
    apollo: {
        getAllDrivers() {
            return {
                query: require("@/reports/api/getAllDrivers.graphql"),
                variables: {
                    limit: 10000,
                    skip: 0,
                    type: 'SHOP'
                },
                result({ data, loading }) {
                    this.loading = loading;
                    this.drivers = data.getAllDrivers.drivers.filter(x => x.isShopDriver);
                }
            };
        }
    },
    data() {
        return {
            valid: true,
            dialog: false,
            btnloading: false,
            show_alert: false,
            error: null,
            snackbar: false,
            snackbarColor: "",
            text: "",
            drivers: [],
            driverOrders: [],
            driver: "",
            loading: false
        };
    },
    methods: {
        driverUpdate() {
            this.loading = true;
            this.$apollo
                .watchQuery({
                    query: require("../api/checkDriverPendingOrders.graphql"),
                    variables: {
                        driver: this.driver,
                    }
                })
                .refetch({
                    driver: this.driver,
                })
                .then(data => {
                    this.loading = false;
                    if (data.data.checkDriverPendingOrders) {
                        this.driverOrders = data.data.checkDriverPendingOrders
                    }
                })
                .catch(error => {

                    this.loading = false;
                    console.log(error);
                });
        },
        assignDriver() {
            // debugger;
            this.btnloading = true;
            this.$apollo
                .mutate({
                    mutation: require("../api/assignDriverToOrder.graphql"),
                    variables: {
                        orderId: this.item._id,
                        driver: this.driver
                    }
                })
                .then(data => {
                    this.btnloading = false;
                    this.$root.$emit("getAllOrders");
                    if (data.data.assignDriverToOrder) {
                        this.dialog = false;
                        this.text = "Driver Assigned.";
                        this.snackbarColor = "success";
                        this.snackbar = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.btnloading = false;
                    this.text = "Something went wrong";
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.dialog = false;
                });
        },

        close() {
            this.error = null;
            this.dialog = false;
        }
    }
};
</script>
  