<template>
    <v-container fluid class="pa-4 pa-sm-8">
      <v-layout wrap class="d-flex">
        <v-flex>
          <h2 class="black--text text-h5 font-weight-bold">
            <!-- <v-icon size="32" class="primary--text me-2 pb-1">mdi-account</v-icon
            > -->
            Customers Without Meal
          </h2>
        </v-flex>
      </v-layout>
      <v-layout class="mt-8">
        <v-flex xs8 sm3 md3 lg2>
          <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="fromDate" label="From" solo class="" append-icon="fa-calendar-alt black--text"
                v-bind="attrs" v-on="on" @click:clear="fDate = null" dense></v-text-field>
            </template>
            <v-date-picker color="secondary" v-model="fDate" @change="selectFromDate"></v-date-picker>
          </v-menu>
        </v-flex>
  
  
  
      </v-layout>
  
      <v-data-table :headers="headers" :items="itemsWithSno" class="elevation-1" :items-per-page="25"
        :options.sync="options" :loading="loading" :mobile-breakpoint="0" :server-items-length="totalItemsCount"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
        <template v-slot:item.customerId="{ item }">
          {{ padLeft(item.customerId, 4) }}
        </template>
        <template v-slot:item.name="{ item }">
          <router-link v-if="item.user" :to="{
            name: 'CustomerDetails',
            params: { customerId: item._id },
            query: { customerId: item._id }
          }" class="mx-auto">
            {{
                item.user.firstnameEn[0].toUpperCase() +
                item.user.firstnameEn.substring(1)
            }}
            <!-- <div class="mx-1" /> -->
            {{
                item.user.lastnameEn[0].toUpperCase() +
                item.user.lastnameEn.substring(1)
            }}
          </router-link>
          <!-- </v-row> -->
        </template>
        <template #item.tag="{ item }">
          <span v-for="tag in item.tags" :key="tag._id">
            {{ tag.name }}<br />
          </span>
        </template>
  
        <template v-slot:item.area="{ item }">
          <CustomerAddressColumn :address="defaultAddress(item.addressDetails)" type="area" />
        </template>
  
        <template v-slot:item.block="{ item }">
          <CustomerAddressColumn :address="defaultAddress(item.addressDetails)" type="block" />
        </template>
  
        <template v-slot:item.building="{ item }">
          <CustomerAddressColumn :address="defaultAddress(item.addressDetails)" type="building" />
        </template>
  
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  import CustomerAddressColumn from "../components/CustomerAddressColumn.vue";
  import moment from 'moment'
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      CustomerAddressColumn,
    },
    data() {
      return {
        menu1: false,
        filters: {},
        fDate: format(parseISO(moment().toDate().toISOString()), "yyyy-MM-dd"),
        headers: [
          {
            text: "#",
            value: "customerId",
            width: 100
          },
          // { text: "Avatar", value: "photo" },
          { text: "Customer Name", value: "name", width: 150 },
          { text: "Tag", value: "tag", width: 100, sortable: false },
          {
            text: "Contact Number",
            value: "user.phone",
            width: 170,
            sortable: false
          },
  
          { text: "Area", value: "area", width: 150, sortable: false },
          {
            text: "Block, Street, Jedha",
            value: "block",
            width: 220,
            sortable: false
          },
          { text: "Building", value: "building", width: 200, sortable: false },
  
        ],
        tags: [],
        customerList: [],
        totalItemsCount: 25,
        options: {
          itemsPerPage: 25,
          page: 1,
        },
        variables: {
          limit: 25,
          skip: 0,
          date: ""
        },
        loading: false,
        filter: { tag: "", area: "", block: "" },
        areas: [],
        areasLoading: false,
        blocks: [],
        areaBlocks: [],
      };
    },
    created() {
      if (!this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW)) {
        this.$router.push({
          name: "Error404"
        });
      }
      this.getAllAreas();
      this.getAllBlocks();
    },
    apollo: {
      customerTags() {
        return {
          query: require("../api/customerTags.graphql"),
          result({ data }) {
            this.tags = data.customerTags;
          }
        };
      },
      getCustomersWithNoMeals() {
        return {
          query: require("../api/getCustomersWithNoMeals.graphql"),
          variables: { ...this.variables, date: this.fDate },
          result({ data, loading }) {
            this.loading = loading;
            this.customerList = data.getCustomersWithNoMeals.customers;
            this.customerList.forEach(element => {
              if (element.addressDetails) {
                element.addressDetails.forEach(address => {
                  if (address.area) {
                    let area = this.areas.find(x => x._id == address.area._id);
                    address.area = area;
                  }
                  if (address.block) {
                    let block = this.blocks.find(x => x._id == address.block._id);
                    address.block = block;
                  }
                });
              }
            });
            this.totalItemsCount = data.getCustomersWithNoMeals.totalCount;
          },
          skip() {
            return !this.fDate
          }
        };
      }
    },
    computed: {
      fromDate() {
        return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
      },
      itemsWithSno() {
        if (this.customerList == null) return [];
        return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
      }
    },
    methods: {
      selectFromDate() {
        this.menu1 = false;
        this.runSearch()
      },
      getAllAreas() {
        this.$apollo
          .query({
            query: require("@/customer/api/getAllAreas.graphql"),
            variables: {
              searchKey: ""
            }
          })
          .then(data => {
            let areas = data.data.getAllAreas.areas;
            areas = areas.sort((a, b) =>
              a.nameEn > b.nameEn ? 1 : b.nameEn > a.nameEn ? -1 : 0
            );
  
            this.areas = areas;
            this.areasLoading = false;
          });
      },
      getAllBlocks() {
        this.$apollo
          .query({
            query: require("@/customer/api/getAllBlocks.graphql"),
            variables: {
              searchKey: "",
              skip: 0,
              limit: 0
            }
          })
          .then(data => {
            let blocks = data.data.getAllBlocks.blocks;
            blocks = blocks.sort((a, b) =>
              a.block > b.block ? 1 : b.block > a.block ? -1 : 0
            );
  
            this.blocks = blocks;
          });
      },
      padLeft(number, length) {
        var stringValue = "" + number;
        while (stringValue.length < length) {
          stringValue = "0" + stringValue;
        }
        return stringValue;
      },
      defaultAddress(addresses) {
        if (addresses.length > 0) {
          let defaultAddress = addresses.find(x => x.is_default == true);
          if (defaultAddress) {
            return defaultAddress;
          } else {
            return addresses[0];
          }
        }
      },
      runSearch() {
        this.$apollo.queries.getCustomersWithNoMeals.refetch({
          limit: 25,
          skip: 0,
          date: this.fDate,
        });
      },
    },
    watch: {
      options: {
        handler() {
          if (!this.loading) {
            this.variables.limit = this.options.itemsPerPage;
            this.variables.skip =
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage;
            this.$apollo.queries.getCustomersWithNoMeals.refetch({
              limit:
                this.options.itemsPerPage !== -1
                  ? this.options.itemsPerPage
                  : 500,
              skip: this.variables.skip,
            });
          }
        },
        deep: true
      },
    }
  };
  </script>
  
  <style scoped>
  </style>
  