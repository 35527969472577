var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5"},[_c('v-card',{staticClass:"mx-4",attrs:{"outlined":""}},[_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"ms-4 primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'PackageReport' })}}},[_c('v-icon',{staticClass:"me-1",attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-left")]),_vm._v("back ")],1),_c('div',{staticClass:"py-2 ps-4"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"primary--text font-weight-bold text-h5"},[_vm._v(" "+_vm._s(_vm.packageName)+" ")]),_c('span',{staticClass:"me-4"})]),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Total Subscribers :")]),_c('v-col',{attrs:{"cols":"4"}},[_c('span',[_vm._v(" "+_vm._s(_vm.totalCount))])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_vm._v("Total Income :")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('span',[_vm._v(_vm._s(_vm.totalIncome.toFixed(2))+" KD")])])],1)],1)]),_c('v-layout',{staticClass:"ps-2 mt-4"},[_c('v-flex',{attrs:{"xs7":"","sm3":"","md2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-md-2",attrs:{"value":_vm.fromDate,"label":"From","outlined":"","append-icon":"mdi-calendar-month-outline","dense":""},on:{"click:clear":function($event){_vm.fDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"max":_vm.fromDateMaxDates},on:{"change":_vm.selectFromDate},model:{value:(_vm.fDate),callback:function ($$v) {_vm.fDate=$$v},expression:"fDate"}})],1)],1),_c('v-flex',{attrs:{"xs7":"","sm3":"","md2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ms-sm-2",attrs:{"value":_vm.toDate,"label":"To","outlined":"","append-icon":"mdi-calendar-month-outline","dense":""},on:{"click:clear":function($event){_vm.tDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"max":_vm.toDateMaxDates,"min":_vm.toDateMinDates},on:{"change":_vm.selectToDate},model:{value:(_vm.tDate),callback:function ($$v) {_vm.tDate=$$v},expression:"tDate"}})],1)],1),_c('v-btn',{staticClass:"primary--text ms-4 elevation-0",attrs:{"outlined":""},on:{"click":_vm.getAllCustomersInCurrentPackage}},[_vm._v("Search")])],1),_c('div',{staticClass:"ms-4 mb-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("Customers : ")]),_c('v-col',{attrs:{"cols":"4"}},[_c('span',[_vm._v(" "+_vm._s(_vm.count))])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_vm._v("Income :")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('span',[_vm._v(" "+_vm._s(_vm.income.toFixed(2))+" KD")])])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-5 mx-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.customers,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customerId)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CustomerDetails',
          params: { customerId: item._id },
          query: { customerId: item._id },
        }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }