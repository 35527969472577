<template>
  <div>
    <v-layout wrap class="mb-6">
      <v-flex>
        <h2 class="black--text text-h5 font-weight-bold">
          Sales Order Status 
        </h2>
      </v-flex>
      <v-flex>
        <ImportSalesOrder v-if="!isDietitan" />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start>
      <v-flex xs12 sm4 lg4>
        <v-text-field
          placeholder="Search.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:prepend-inner>
            <v-icon
              color="black"
              v-if="search.length > 0"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon v-if="search.length == 0" color="black" @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-flex>
      <v-flex xs8 sm3 md3 lg2>
        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fromDate"
              label="From"
              solo
              class="ms-sm-2"
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="fDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="fDate"
            :max="tDate"
            @change="selectFromDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs8 sm3 md3 lg2>
        <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="toDate"
              label="To"
              class="ms-sm-2"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              @click:clear="tDate = null"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="secondary"
            v-model="tDate"
            :min="fDate"
            @change="selectToDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <!-- <v-flex xs1 sm3 md3 lg2>
        <v-btn>Search</v-btn>
      </v-flex> -->
    </v-layout>
    <v-tabs
      grow
      :color="tab == 0 ? 'yellow' : tab == 1 ? 'green' : 'red'"
      v-model="tab"
    >
      <v-tab v-for="item in items" :key="item.tab">
        <span class="text-capitalize black--text"> {{ item.tab }}</span>
      </v-tab>
    </v-tabs>
    <div class="mt-5">
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="item in items" :key="item.tab">
          <!-- Yellow tab section -->
         <div v-if="tab == 0"> 
            <v-tabs
              color="yellow"
              fixed-tabs
              background-color="primary"
              v-model="yellowTab"
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-for="item in yellowItems" :key="item.tab" >
                <span class="text-capitalize white--text"> {{ item.tab }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items touchless v-model="yellowTab">
              <v-tab-item v-for="item in yellowItems" :key="item.tab">
                <PendingSalesOrder
                  :tab="tab"
                  :yellowTab="yellowTab"
                  :filter="filter"
                  :updated="updated" 
                />
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div v-if="tab == 2">
            <v-tabs
              color="red"
              fixed-tabs
              background-color="primary"
              v-model="redTab"
            >
              <v-tabs-slider color="red"></v-tabs-slider>
              <v-tab v-for="item in redItems" :key="item.tab">
                <span class="text-capitalize white--text"> {{ item.tab }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items touchless v-model="redTab">
              <v-tab-item v-for="item in redItems" :key="item.tab">
                <PendingSalesOrder
                  :tab="tab"
                  :redTab="redTab"
                  :filter="filter"
                  :updated="updated" 
                />
              </v-tab-item>
            </v-tabs-items>
          </div>

          <div v-if="tab==1"> 
            <PendingSalesOrder :tab="tab" :filter="filter" :updated='updated' />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PendingSalesOrder from "@/orders/components/PendingSalesOrder.vue";
import ImportSalesOrder from "@/orders/components/ImportSalesOrder.vue";
export default {
  components: {
    PendingSalesOrder,
    ImportSalesOrder
  },
  data: () => ({
    filter: {},
    search: "",
    menu1: false,
    menu2: false,
    fDate: "",
    tDate: "",
    tab: null,
    items: [{ tab: "YELLOW" }, { tab: "GREEN" }, { tab: "RED" }],
    redTab: null,
    yellowTab: null,
    redItems: [
      { tab: "PAY LATER" },
      { tab: "PAYMENT FAILED" },
      { tab: "PAYMENT PENDING" }
    ],

    yellowItems: [
      { tab: "Pending Subscribed packages" },
      { tab: "Subscribed packages" },
    ],
    isDietitan: false,
    updated:0
  }),
  created() {
    this.routeName = this.$route.name;
    if (this.routeName == "SetCalorieValue") {
      this.items = this.items.filter(x => x.tab !== "RED");
      this.isDietitan = true;
    }
  },
  methods: {
    runSearch() {
      this.filter.search = this.search;
      this.updated++
    },
    clearSearch() {
      this.search='' 
      this.filter.search = "";
      this.updated++
    },
    selectFromDate() {
      this.menu1 = false;
      this.filter.fDate = this.fDate;
      this.updated++ 
    },
    selectToDate() {
      this.menu2 = false;
      this.filter.tDate = this.tDate;
      this.updated++
    }
    
  },
  computed: {
    fromDate() {
      return this.fDate ? moment(this.fDate).format("DD/MM/YYYY") : "";
    },
    toDate() {
      return this.tDate ? moment(this.tDate).format("DD/MM/YYYY") : "";
    }
  }
};
</script>

<style scoped></style>
