<template>
    <div>
        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
        <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined color="red" style="border-radius:8px">
                    Cancel Order
                </v-btn>

            </template>
            <v-card class="pt-1 pb-5" color="pop_bg">
                <v-card-text class="subtitle-1 mb-0 mt-6 text-center black--text">Do you really want to cancel this?
                </v-card-text>

                <v-card-text v-if="error" class="error--text font-weight-bold">{{
                    error
                }}</v-card-text>
                <v-card-actions justify="center">
                    <v-row justify="center">
                        <v-btn color=" mx-2 error" depressed @click="close">No</v-btn>
                        <v-btn color=" mx-2 success" depressed :loading="btnloading" @click="cancelOrder();">Yes</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
export default {
    props: {
        item: {
            required: true
        },
    },

    data() {
        return {
            dialog: false,
            btnloading: false,
            show_alert: false,
            error: null,
            snackbar: false,
            snackbarColor: "",
            text: "",
            drivers: [],
            driverOrders: [],
            driver: "",
            loading: false
        };
    },
    methods: {
        cancelOrder() {
            this.btnloading = true;
            this.$apollo
                .mutate({
                    mutation: require("../api/updateOrderStatuses.graphql"),
                    variables: {
                        ids: [this.item._id],
                        status: 'CANCELLED',
                        deliveryText: ''
                    }
                })
                .then(data => {
                    this.btnloading = false;
                    this.$root.$emit("getAllOrders");
                    if (data.data.updateOrderStatuses) {
                        this.dialog = false;
                        this.text = "Order Cancelled.";
                        this.snackbarColor = "success";
                        this.snackbar = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.btnloading = false;
                    this.text = "Something went wrong";
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.dialog = false;
                });
        },

        close() {
            this.error = null;
            this.dialog = false;
        }
    }
};
</script>
  