<template>
  <v-container>
    <v-form
      class="ms-8 ms-sm-0 pa-sm-8"
      @submit="createNotification()"
      v-model="valid"
      ref="form"
    >
    <v-layout class="mb-5">
        <v-flex md5 class="mt-4">
          Placeholder :<strong> EN_FIRSTNAME / AR_FIRSTNAME</strong>
        </v-flex>
      </v-layout>
    
      <v-layout wrap class="mt-5 mt-md-0">
        <v-flex xs6 sm2 md2>
          <v-text-field
            label="Title"
            v-model="title"
            :rules="[requiredValidator('title')]"
            solo
            dense
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 md5 class="ms-sm-10">
          <v-autocomplete
            v-model="customer"
            :items="customerList"
            item-text="user.firstnameEn"
            item-value="_id"
            solo
            chips
            dense
            attach
            small-chips
            label="Search customers"
            multiple
            clearable
            deletable-chips
            :search-input.sync="customersSearchKey"
            :loading="customerLoading"
            return-object
          >
            <template slot="no-data">
              <v-flex xs12 md12 class="mt-2 mb-2">
                <h5 class="font-weight-medium ml-4">Search for customer...</h5>
              </v-flex>
            </template>
            <template slot="item" slot-scope="data"
              >{{ data.item.user.firstnameEn }}
              {{ data.item.user.lastnameEn }}</template
            >
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs6 sm3 lg2 class="d-flex">
          <v-checkbox
            :disabled="customer.length > 0"
            label="Female"
            v-model="reciverType"
            value="Female"
          ></v-checkbox>

          <v-icon
            class="black--text ms-2 ms-sm-3"
            :large="$vuetify.breakpoint.smAndUp"
            >fa-female</v-icon
          >
        </v-flex>
        <v-flex xs6 sm3 lg2 class="d-flex">
          <v-checkbox
            :disabled="customer.length > 0"
            label="Male"
            v-model="reciverType"
            value="Male"
          ></v-checkbox>
          <v-icon
            class="black--text ms-2 ms-sm-3"
            :large="$vuetify.breakpoint.smAndUp"
            >fa-male</v-icon
          >
        </v-flex>
        <v-flex xs3 sm2>
          <v-checkbox
            :disabled="customer.length > 0"
            label="All"
            v-model="reciverType"
            value="All"
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm8 md4>
          <v-checkbox
            label="Include customer first name"
            v-model="is_IncludeFirstName"
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm8 md6>
          <v-textarea
            solo
            name="input-7-4"
            label="Description"
            v-model="description"
            :rules="[requiredValidator('description')]"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-container>
        Notification Type
        <v-radio-group
          v-model="notificationType"
          @change="onPackageModeChange"
          color="primary"
          :rules="[requiredValidator('notificationType')]"
        >
        <v-layout wrap>
          <v-flex xs6 sm3 lg2 class="d-flex">
            <v-radio value="DateAndTime" label="Date And Time" color="primary">
            </v-radio>
          </v-flex>
          <v-flex xs6 sm3 lg2 class="d-flex">
            <v-radio value="DayAndTime" label="Day And Time" color="primary">
            </v-radio>
          </v-flex>
          <v-flex xs3 sm2>
            <v-radio value="EveryMonth" label="EveryMonth" color="primary">
            </v-radio>
          </v-flex>
        </v-layout>
        </v-radio-group>
      </v-container>
      <v-col v-if="notificationType == 'DateAndTime'">
        <v-layout wrap class="mt-5 mt-md-0">
        
          <v-flex xs6 sm2 md2>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  :value="formatDate"
                  dense
                  label = "Notification Date"
                  :rules="[requiredValidator('notification date')]"
                  append-icon="fa-calendar-alt black--text"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="new Date() | moment('YYYY-MM-DD')"
                v-model="notificationDate"
                @input="menu2 = false"
                color="secondary"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md5 class="ms-sm-10">
            <v-time-picker
              v-model="notificationTime"
              :rules="[requiredValidator('notificationTime')]"
              :allowed-minutes="allowedStep"
              format="24hr"
              scrollable
          ></v-time-picker> 
        </v-flex>
      </v-layout>
       
      </v-col>
      <v-col v-if="notificationType == 'DayAndTime'">
      <v-layout wrap>
        <v-flex xs6 sm3 lg2 class="d-flex">
          <v-checkbox
           
            label="Sun"
            v-model="weeklyNotificationDays"
            value="Sunday"
          ></v-checkbox>


        </v-flex>
        <v-flex xs6 sm3 lg2 class="d-flex">
          <v-checkbox
            
            label="Mon"
            v-model="weeklyNotificationDays"
            value="Monday"
          ></v-checkbox>

        </v-flex>
        <v-flex xs3 sm2>
          <v-checkbox
            
            label="Tues"
            v-model="weeklyNotificationDays"
            value="Tuesday"
          ></v-checkbox>
        </v-flex>
          <v-flex xs3 sm2>
          <v-checkbox
            
            label="Wed"
            v-model="weeklyNotificationDays"
            value="Wednesday"
          ></v-checkbox>
        </v-flex>
        <v-flex xs3 sm2>
          <v-checkbox
            
            label="Fri"
            v-model="weeklyNotificationDays"
            value="Friday"
          ></v-checkbox>
        </v-flex>
          <v-flex xs3 sm2>
          <v-checkbox
           
            label="Sat"
            v-model="weeklyNotificationDays"
            value="Saturday"
          ></v-checkbox>
        </v-flex>
        <v-flex xs2 sm2>
          <v-checkbox
           
            label="Repeat Every Week"
            v-model="repeatEveryWeek"
            
          ></v-checkbox>
        </v-flex>
        <v-time-picker
          v-model="notificationTime"
          :rules="[requiredValidator('time')]"
          :allowed-minutes="allowedStep"
          format="24hr"
          scrollable
        ></v-time-picker>
      </v-layout>
      </v-col>
      <v-col v-if="notificationType == 'EveryMonth'">
        <v-layout wrap class="mt-5 mt-md-0">
          <v-flex xs6 sm2 md2>
            <v-select
              :items=dayList
              :rules="[requiredValidator('day')]"
              v-model="montlyNotificationDay"
              item-text="text"
              item-value="value"
              dense
              attach
              solo
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6 md5 class="ms-sm-10">
          <v-time-picker
              v-model="notificationTime"
              :rules="[requiredValidator('time')]"
              :allowed-minutes="allowedStep"
              format="24hr"
              scrollable
          ></v-time-picker> 
          </v-flex>
        </v-layout>   
      </v-col>

      <v-layout class="mt-5 mt-md-0">
        <v-btn
          color="secondary"
          class="black--text text-capitalize"
          @click="createNotification"
          :width="$vuetify.breakpoint.smAndUp ? 150 : 120"
          :loading="btnloading"
          :disabled="!valid"
          >Send</v-btn
        >
      </v-layout>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";

export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      text: "",
      snackbar: false,
      valid: true,
      snackbarColor: "",
      title: "",
      description: "",
      reciverType: "",
      customer: [],
      is_IncludeFirstName: false,
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 0,
        page: 1
      },
      variables: {
        limit: 25,
        skip: 0
      },
      customersSearchKey: "",
      customerLoading: false,
      menu2: false,
      notificationDate:"",
      notificationTime:"",
      notificationType:"",
      repeatEveryWeek:false,
      weeklyNotificationDays:[],
      allowedStep: m => m % 30 === 0,
      dayList:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      montlyNotificationDay:"",


    };
  },
    computed: {
    formatDate() {
      return this.notificationDate
        ? moment(this.notificationDate).format(DATE_FORMAT)
        : "";
    },
  },
  watch: {
    customersSearchKey: function(val) {
      
      if (val == null || val == undefined) return;
      if (val.length < 3) return;
      let customer = JSON.parse(JSON.stringify(this.customer));
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.awaitingSearch = false;
          this.customerLoading = true;
          this.$apollo
            .query({
              query: require("@/customer/api/customers.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val
              }
            })
            .then(data => {
              let customerList = data.data.customers.customers;

              customer.forEach(element => {
                let item = customerList.find(x => x._id == element._id);
                if (!item) customerList.unshift(element);
              });

              this.customerList = customerList;
              console.log(this.customerList);
              this.customerLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    reciverType: {
      handler() {
        if (!this.reciverType) {
          this.reciverType = " ";
        }
      }
    }
  },

  methods: {
    createNotification() {
      this.btnloading = true;
      if (!this.reciverType) {
        this.reciverType = "";
      }
      if (this.is_IncludeFirstName == undefined) {
        this.is_IncludeFirstName = false;
      }
      let customers = new Array();
      this.customer.forEach(element => {
        customers.push(element._id);
      });

      if(this.notificationType =="EveryMonth"){
        this.variables.montlyNotificationDay=this.montlyNotificationDay
      }

      this.$apollo
        .mutate({
          mutation: require("../api/scheduleNotification.graphql"),
          variables: {
            title: this.title,
            description: this.description,
            reciverType: this.reciverType,
            is_IncludeFirstName: this.is_IncludeFirstName,
            customer: customers,
            notificationDate:this.notificationDate,
            notificationType:this.notificationType,
            weeklyNotificationDays:this.weeklyNotificationDays,
            notificationTime:this.notificationTime,
            repeatEveryWeek:this.repeatEveryWeek,
          },

        })
        .then(() => {
          this.$root.$emit("updateNotifications");
          this.dialog = false;
          this.$refs.form.reset();
          this.text = "Notification Added";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.btnloading = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            this.btnloading = false;
            // this.text = error.graphQLErrors[0].message;
            this.text = "Some error";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
          this.$refs.form.reset();
          this.btnloading = false;
          console.log(error);
        });
    }
  }
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black !important;
  font-weight: 400;
}
</style>
