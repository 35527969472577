<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" class="ms-2">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="px-4 py-6 py-sm-8" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2">Update Product</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <v-divider></v-divider>

        <v-card-text class="pa-0 pt-2">
          <v-container class="mt-2">
            <v-form @submit="createProduct()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Order</h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="productDetails.order" :rules="[requiredValidator('order')]" solo
                    placeholder="order" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Product Name
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field v-model="productDetails.nameEn" :rules="[requiredValidator('product')]" solo
                    placeholder="Product Name English" dense></v-text-field>
                </v-flex>

                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-text-field v-model="productDetails.nameAr" :rules="[requiredValidator('product')]" solo
                    placeholder="Product Name Arabic" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Product Description
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-textarea v-model="productDetails.descriptionEn" :rules="[requiredValidator('description')]" solo
                    placeholder="Description English" dense></v-textarea>
                </v-flex>

                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-textarea v-model="productDetails.descriptionAr" solo placeholder="Description Arabic"
                    dense></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Categories
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md9>
                  <v-select v-model="productCategories" :items="categories" solo dense item-value="_id" item-text="nameEn"
                    attach chips small-chips label="Select Category" multiple deletable-chips></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Minimum order qty
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md3>
                  <v-text-field v-model="productDetails.minimumOrderQty" :rules="[requiredValidator('minimum order qty')]"
                    solo placeholder="Minimum order qty" dense></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 md2 offset-md-0 class="ms-md-5">
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Maximum order qty
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md3 offset-sm-3 offset-md-0 class="ms-md-5">
                  <v-text-field v-model="productDetails.maximumOrderQty" :rules="[requiredValidator('maximum order qty')]"
                    solo placeholder="Maximum order qty" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Price</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field v-model="productDetails.price" :rules="[requiredValidator('price')]" solo
                    placeholder="Product Price" type="number" dense></v-text-field>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-checkbox v-model="productDetails.status" label="Active"> </v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Stock</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-select :items="['IN_STOCK', 'OUT_OF_STOCK']" label="" v-model="productDetails.stockType" dense
                    solo></v-select>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-checkbox v-model="productDetails.isMeal" label="Is Meal"> </v-checkbox>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Image</h5>
                </v-flex>

                <v-flex xs12 sm7 class="mt-3 mt-sm-0 ml-n2">
                  <croppa class="ml-2 mt-2" v-model="myCroppa" :width="170" :height="120" canvas-color="black"
                    :placeholder="'Choose an image'" :placeholder-font-size="16" placeholder-color="yellow"
                    :accept="'image/*'" :file-size-limit="0" :quality="5" :zoom-speed="3" :disabled="false"
                    :disable-drag-and-drop="false" :disable-click-to-choose="false" :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false" :disable-rotation="false" :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false" :show-remove-button="true" :remove-button-color="'red'"
                    :remove-button-size="10"></croppa>

                  <!-- <input
                    type="file"
                    accept="image/*"
                    @change="onFileSelected($event)"
                  /> -->
                  <v-layout>
                    <span style="color: red">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mt-4  " v-if="productDetails.isMeal">
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2 mt-3">Fat</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="productDetails.meanInfo.fat" min="0" @keypress="formatDecimal" solo
                    :rules="[requiredValidator('fat')]" type="number" suffix="gm"></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2 mt-3">Protein</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="productDetails.meanInfo.protein" solo min="0"
                    :rules="[requiredValidator('protein')]" @keypress="formatDecimal" type="number"
                    suffix="gm"></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3>
                  <h4 class="black--text text-body-2 mt-3">Carb</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="productDetails.meanInfo.carbs" solo min="0" :rules="[requiredValidator('carb')]"
                    @keypress="formatDecimal" type="number" suffix="gm"></v-text-field>
                </v-flex>
                <v-flex xs5 sm4 md3 class="ms-md-16">
                  <h4 class="black--text text-body-2 mt-3">Calories</h4>
                </v-flex>
                <v-flex xs6 sm5 md2 class="ms-md-n16">
                  <v-text-field v-model="productDetails.meanInfo.calories" solo min="0"
                    :rules="[requiredValidator('calories')]" @keypress="formatDecimal" type="number"
                    suffix="gm"></v-text-field>
                </v-flex>
              </v-layout>

              <div>
                <ProductCategoryDiscount :itemDiscount="itemDiscount" class="mt-4" type="product" />
              </div>
              <v-layout row justify-end class="mt-2 me-1">
                <v-btn :width="$vuetify.breakpoint.smAndUp ? 120 : 90" color="secondary"
                  class="white--text text-capitalize" @click="updateProduct()" :loading="btnloading" :disabled="!valid">
                  Save</v-btn>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{ text }}</v-snackbar>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import ProductCategoryDiscount from "@/discount/components/ProductCategoryDiscount";
import moment from "moment";
export default {

  components: { ProductCategoryDiscount },
  props: {
    productDetails: {
      required: true,
    },
  },
  apollo: {
    getAllProductCategories() {
      return {
        query: require("../api/getAllProductCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          console.log(data);
          this.categories = data.getAllProductCategories;
          this.loading = loading;
        },
      };
    },
  },

  watch: {
    productDetails: {
      handler() {
        if (!this.productDetails.meanInfo) {
          this.productDetails.meanInfo = {
            fat: 0,
            protein: 0,
            calories: 0,
            carbs: 0,
          };
        }

        if (this.productDetails) {
          if (this.productDetails.categories) {
            let productCategories = this.productDetails.categories.map(x => x._id);
            this.productCategories = productCategories;
          }
        }
      },
      deep: true,
    },
  },

  created() {
    if (!this.productDetails.meanInfo) {
      this.productDetails.meanInfo = {
        fat: 0,
        protein: 0,
        calories: 0,
        carbs: 0,
      };
    }

    if (this.productDetails) {
      if (this.productDetails.categories) {
        let productCategories = this.productDetails.categories.map(x => x._id);
        this.productCategories = productCategories;
      }
      this.getDiscountByItem();
    }
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      valid: true,
      errors: [],
      categories: [],
      productCategories: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      myCroppa: null,
      snackColor: "",
      image: null,
      itemDiscount: {
        enableDiscount: false,
        discount: 0,
        inPercentage: false,
        maxDiscountAmount: 0,
        startDate: "",
        endDate: ""
      }
    };
  },
  methods: {
    getDiscountByItem() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getDiscountByItem.graphql"),
          variables: {
            product: this.productDetails._id,
          },
        })
        .refetch({
          variables: {
            product: this.productDetails._id,
          },
        })
        .then(data => {
          this.loading = false;
          if (data.data.getDiscountByItem) {
            let discount = data.data.getDiscountByItem;
            this.itemDiscount = {
              enableDiscount: true,
              discount: discount.discount,
              inPercentage: discount.inPercentage,
              maxDiscountAmount: discount.maxDiscountAmount,
              startDate: moment(
                new Date(parseInt(discount.startDate))
              ).format("YYYY-MM-DD"),
              endDate: moment(
                new Date(parseInt(discount.endDate))
              ).format("YYYY-MM-DD"),
            }
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    formatDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.updatedQuantity.indexOf(".") != -1)) {
        // 46 is dot
        $event.preventDefault();
      }
      if (this.alertQuantity != null && this.alertQuantity.indexOf(".") > -1 && this.alertQuantity.split(".")[1].length > 2) {
        $event.preventDefault();
      }
    },
    updateProduct() {
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob(blob => {
          this.image = blob;
          this.updateProductAPI();
        });
      } else {
        this.updateProductAPI();
      }
    },
    updateProductAPI() {
      this.btnloading = true;
      let meanInfo = null;
      debugger;
      if (this.productDetails.isMeal) {
        meanInfo = {
          fat: this.productDetails.meanInfo.fat,
          protein: this.productDetails.meanInfo.protein,
          calories: this.productDetails.meanInfo.calories,
          carbs: this.productDetails.meanInfo.carbs,
        };
      }

      let discount = null;
      debugger
      if (this.itemDiscount.enableDiscount) {
        if (!this.itemDiscount.inPercentage) {
          if (parseFloat(this.itemDiscount.discount) >= parseFloat(this.productDetails.price)) {
            this.snackbar = true;
            this.snackColor = "error";
            this.text = "Discount amount should be less than the product amount.";
            this.btnloading = false;
            return false;
          }
        }
        discount = {
          startDate: this.itemDiscount.startDate,
          endDate: this.itemDiscount.endDate,
          discount: parseFloat(this.itemDiscount.discount),
          inPercentage: this.itemDiscount.inPercentage,
          maxDiscountAmount: parseFloat(this.itemDiscount.maxDiscountAmount),
        }
      }
      this.$apollo
        .mutate({
          mutation: require("../api/updateProduct.graphql"),
          variables: {
            id: this.productDetails._id,
            nameEn: this.productDetails.nameEn,
            nameAr: this.productDetails.nameAr,
            descriptionEn: this.productDetails.descriptionEn,
            descriptionAr: this.productDetails.descriptionAr,
            price: parseFloat(this.productDetails.price),
            stockType: this.productDetails.stockType,
            status: this.productDetails.status,
            image: this.image,
            order: parseInt(this.productDetails.order),
            minimumOrderQty: parseInt(this.productDetails.minimumOrderQty),
            maximumOrderQty: parseInt(this.productDetails.maximumOrderQty),
            isMeal: this.productDetails.isMeal,
            meanInfo: meanInfo,
            categories: this.productCategories,
            discount: discount
          },
        })
        .then(() => {
          this.btnloading = false;
          this.show_dialog_box = false;
          this.snackbar = true;
          this.text = "Product Updated";
          this.$root.$emit("updateProduct");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
    },
  },
};
</script>
