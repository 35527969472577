

<template>
  <v-container class="px-0 mx-0">
    <v-btn
      @click="exportExcel"
      width="150"
      :loading="loading"
      class="mt-n3 white--text text-capitalize btn_fnt"
      color="primary"
    >
      <v-icon small class="me-1" color="white">mdi-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="exportSalesOrder"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="salesOrderExportList"
        class="elevation-1 mt-6"
      >
        <template v-slot:item.date="{ item }">{{
          new Date(parseInt(item.date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:item.startDate="{ item }">{{
          new Date(parseInt(item.startDate)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:item.endDate="{ item }">{{
          new Date(parseInt(item.endDate)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:item.total="{ item }">{{
          numberWithCommas(item.total)
        }}</template>
        <template v-slot:item.package_price="{ item }">{{
          numberWithCommas(item.package_price)
        }}</template>
        <template v-slot:item.discount="{ item }">{{
          numberWithCommas(item.discount)
        }}</template>
        <template v-slot:item.net_price="{ item }">{{
          numberWithCommas(item.net_price)
        }}</template>
        <template v-slot:item.customer="{ item }">
          
          {{ item.customer.firstnameEn }} {{ item.customer.lastnameEn }}

          
        </template>
        <template v-slot:item.referredUser="{ item }">
          <span v-if="item.referredByUser">
            {{ item.referredByUser.firstnameEn }}
            {{ item.referredByUser.lastnameEn }}
          </span>
          <span v-else>--</span>
        </template>
        <template v-slot:item.referredUserRole="{ item }">
          <span v-if="item.referredByUser">
            {{ item.referredByUser.role }}
          </span>
          <span v-else>--</span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>

import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    status: {
      required: true
    },
    startDate: {
      required: true
    },
    endDate: {
      required: true
    }
  },
  data() {
    return {
      salesOrderExportList: [],
      dayData: "",
      typeData: "",
      loading: false,
      variables: {
        limit: -1,
        skip: 0,
        status: "",
        startDate: "",
        endDate: "",
        discountOnly: false
      }
      
    };
  },


  created() {
    console.log("startDate : ",this.startDate);
    console.log("endate ",this.endDate);
    console.log("status ",this.status);
  },
  watch: {
    startDate: {
      handler() {
        this.variables.startDate = this.startDate
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.variables.endDate = this.endDate
      },
      deep: true,
    },
    status: {
      handler() {
        this.variables.status = this.status
      },
      deep: true,
    },
  },

  methods: {
    getAllSubscribedPackages() {
      debugger
      
      this.variables.status = this.status;
      this.variables.startDate = this.startDate;
      this.variables.endDate = this.endDate;
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllSubscribedPackagesWithDate.graphql"),
          variables: this.variables
        })
        .refetch({ variables: this.variables })
        .then(data => {
          this.salesOrderList = [];
          this.totalItemsCount =
            data.data.getAllSubscribedPackagesWithDate.total_count;
            console.log("data.data.getAllSubscribedPackagesWithDate",data.data.getAllSubscribedPackagesWithDate)
          data.data.getAllSubscribedPackagesWithDate.sales_orders.forEach(
            element => {
              if (element.package_price === null) {
                element.package_price = 0;
              }

              var packageStatus = element.status;
              if (element.is_deleted === true) {
                packageStatus = "Customer Deleted";
              } else if (element.status === "PENDING") {
                packageStatus = "Payment Pending";
              }
              const salesOrderData = {
                id: element._id,
                order_number: element.order_number,
                customerId: element.customer,
                customer: element.user,
                date: element.created_At,
                startDate: element.start_date,
                endDate: element.end_date,
                plan: element.package.titleEn,
                paymentStatus: packageStatus,
                total: element.net_price,
                net_price: element.net_price,
                package_price: element.package_price,
                discount: element.discount,
                is_deleted: element.is_deleted,
                referredByUser: element.referredByUser,
                availableNumOfDays:
                  element.package.availableChoices.length > 0
                    ? element.package.availableChoices[0].availableNumOfDays
                    : ""
              };
              this.salesOrderExportList.push(salesOrderData);
            }
          );

          setTimeout(() => {
            this.loading = false;
            Utils.htmlToExcel("exportSalesOrder", "Sales Order List", false);
          }, 1000);
        })
        .catch(error => {
          console.log(error);
        });
    },

    exportExcel() {
      this.getAllSubscribedPackages();
    },
    numberWithCommas(x) {
      if (!x) return 0;
      return x
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
