<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2
      v-if="packagesList.length > 0"
      class="primary--text text-h5 font-weight-bold ms-4"
    >
      Monthly Sales
    </h2>
    <!-- {{packagesList[0]}} -->
    <v-layout class="mt-5 mb-n5" wrap>
      <v-flex xs12 sm2>
        <v-select
          :items="[2021, 2022, 2023]"
          label="Year"
          solo
          v-model="year"
          @change="onYearChange"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm10> </v-flex>
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="packagesList"
      class="elevation-1 mt-10"
      dense
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          packagesList.indexOf(item) +
          1 +
          options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <v-row>
          <router-link
            :to="{
              name: 'CustomerDetails',
              params: { customerId: item._id },
              query: { customerId: item._id },
            }"
            class="mx-auto"
          >
            <span>
              {{
                ` ${item.user.firstnameEn[0].toUpperCase()}${item.user.firstnameEn
                  .substring(1)
                  .toLowerCase()} 
            ${item.user.lastnameEn[0].toUpperCase()}${item.user.lastnameEn
                  .substring(1)
                  .toLowerCase()} `
              }}</span
            >
          </router-link>
        </v-row>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <v-row> {{ item.user.phone }} </v-row>
      </template>
      <template v-slot:[`item.january`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 0)" :key="0" />
      </template>
      <template v-slot:[`item.february`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 1)" :key="1" />
      </template>
      <template v-slot:[`item.march`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 2)" :key="2" />
      </template>
      <template v-slot:[`item.april`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 3)" :key="3" />
      </template>
      <template v-slot:[`item.may`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 4)" :key="4" />
      </template>
      <template v-slot:[`item.june`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 5)" :key="5" />
      </template>
      <template v-slot:[`item.july`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 6)" :key="6" />
      </template>
      <template v-slot:[`item.august`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 7)" :key="7" />
      </template>
      <template v-slot:[`item.september`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 8)" :key="8" />
      </template>
      <template v-slot:[`item.october`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 9)" :key="9" />
      </template>
      <template v-slot:[`item.november`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 10)" :key="10" />
      </template>
      <template v-slot:[`item.december`]="{ item }">
        <PlanInfo :item="getMonthlyData(item, 11)" :key="11" />
      </template>
      <template v-slot:[`item.totalPackage`]="{ item }">
        {{ getTotalPurchase(item) }}
      </template>
      <template v-slot:[`item.totalAmount`]="{ item }">
        {{ numberWithCommas(getTotalAmount(item)) }} KD
      </template>
      <template slot="body.append">
        <tr class="black--text">
          <th><strong>Total </strong></th>
          <td></td>

          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <strong>{{ formatCurrency(totalAmount) }} </strong>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import moment from "moment";
import PlanInfo from "../components/PlanInfo.vue";
export default {
  components: { PlanInfo },
  data: () => ({
    packagesList: [],
    year: 2023,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        width: 40,
      },
      { text: "Customer Name", value: "user", width: 210, sortable: false },
      { text: "Phone No", value: "phone", width: 120, sortable: false },
      { text: "January", value: "january", width: 250, sortable: false },
      { text: "February", value: "february", width: 250, sortable: false },
      { text: "March", value: "march", width: 250, sortable: false },
      { text: "April", value: "april", width: 250, sortable: false },
      { text: "May", value: "may", width: 250, sortable: false },
      { text: "June", value: "june", width: 250, sortable: false },
      { text: "July", value: "july", width: 250, sortable: false },
      { text: "August", value: "august", width: 250, sortable: false },
      { text: "September", value: "september", width: 250, sortable: false },
      { text: "October", value: "october", width: 250, sortable: false },
      { text: "November", value: "november", width: 250, sortable: false },
      { text: "December", value: "december", width: 250, sortable: false },

      {
        text: "Total Package",
        value: "totalPackage",
        width: 250,
        sortable: false,
      },
      {
        text: "Total Amount",
        value: "totalAmount",
        width: 250,
        sortable: false,
      },
    ],
    input: {
      limit: 25,
      skip: 0,
    },
    options: {
      itemsPerPage: 25,
      page: 1,
    },
    loading: false,
    totalItemsCount: 0,
    packageId: "",
    items: [
      { text: "Active", value: false },
      { text: "Expired", value: true },
    ],
    isActive: null,
    shouldCount: false,
    packageDetail: {},
    totalAmount: "",
  }),
  watch: {
    options() {
      this.input.skip = (this.options.page - 1) * this.options.itemsPerPage;
      this.input.limit = this.input.skip + this.options.itemsPerPage;
      this.getCustomerMonthlySalesReport();
    },
  },
  created() {
    this.getCustomerMonthlySalesReport();
    this.packageId = this.$route.query.id;
  },
  methods: {
    onYearChange() {
      this.getCustomerMonthlySalesReport();
    },
    getMonthlyData(item, month) {
      let data = item.monthWiseSubscription.find((x) => x.month == month);
      return data;
    },
    getTotalPurchase(item) {
      let total = 0;
      item.monthWiseSubscription.forEach((month) => {
        total = total + month.data.purchasedPlans.length;
      });
      return total;
    },
    getTotalAmount(item) {
      let total = 0;
      item.monthWiseSubscription.forEach((month) => {
        month.data.purchasedPlans.forEach((plan) => {
          total = total + plan.amount;
        });
      });
      return total;
    },
    getCustomerMonthlySalesReport() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("../api/getCustomerMonthlySalesReport.graphql"),
          variables: {
            input: this.input,
            year: this.year,
          },
        })
        .then((data) => {
          if (data.data) {
            this.loading = false;
            this.shouldCount = false;
            this.packagesList =
              data.data.getCustomerMonthlySalesReport.customers;
            let count = data.data.getCustomerMonthlySalesReport.totalCount;
            let totalAmount =
              data.data.getCustomerMonthlySalesReport.totalAmount;
            this.totalItemsCount = count;
            this.totalAmount = totalAmount;
          }
        });
    },

    formatDate(date) {
      return moment(new Date(parseInt(date))).format("DD/MM/YYYY");
    },
  },
};
</script>
<style>
.inputPhone input[type=""] {
  -moz-appearance: textfield;
}
.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
</style>