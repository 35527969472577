<template>
  <div>
    <v-btn
      @click="showDialog"
      color="primary"
      class="white--text text-capitalize"
      :small="$vuetify.breakpoint.xs"
      :width="$vuetify.breakpoint.xs ? 100 : ''"
    >
      Quick View
    </v-btn>
    <v-dialog v-model="dialog" width="1200">
      <v-card class="pa-2 pa-sm-3" color="bg">
        <v-overlay :value="btnloading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-row class="my-3">
          <v-card-title class="black--text text-h6 py-0  ms-7"
            >Instant View</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>

        <v-card-text>
          <v-container>
            <v-layout class="mt-2 ms-n5 ps-5 ps-sm-0" wrap>
              <v-flex xs8 sm3 md3 lg2 class="mt-5 mt-sm-0 ms-sm-5">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate"
                      readonly
                      label="Date"
                      solo
                      append-icon="fa-calendar-alt black--text"
                      v-bind="attrs"
                      v-on="on"
                      dense
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    color="secondary"
                    v-model="date"
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs5 sm2 md2 lg1 class="mt-md-0 ms-sm-5">
                <v-btn
                  @click="searchDelivery"
                  color="secondary"
                  class="black--text text-capitalize"
                  :small="$vuetify.breakpoint.xs"
                  :width="$vuetify.breakpoint.xs ? 100 : ''"
                  >Search</v-btn
                >
              </v-flex>
              <v-flex xs5 sm2 md2 lg2 class="mt-md-0 ms-sm-5">
                <v-btn
                  v-if="hasRole(this.AdminUserPermissions.QUICK_VIEW_GENERATE)"
                  @click="generateReport"
                  color="secondary"
                  class="black--text text-capitalize"
                  :small="$vuetify.breakpoint.xs"
                  :width="$vuetify.breakpoint.xs ? 100 : ''"
                >
                  Generate</v-btn
                >
              </v-flex>

              <v-flex xs12 sm2 md2 lg2 class="mt-md-0 ms-sm-5">
                <ErrorLog class="mt-4 mt-sm-n2 ms-n2" :errorLog="errorLog" />
              </v-flex>
            </v-layout>
            <v-layout wrap v-show="false">
              <v-flex xs12 sm12 md12 lg4 class="mt-14 mt-sm-5 mt-lg-0">
                <DownloadReports
                  :deliveryDate="date"
                  :headers="headers"
                  deliveryStatus="All"
                />
              </v-flex>
            </v-layout>
            <!-- {{ options }} -->
            <v-data-table
              :mobile-breakpoint="0"
              v-model="selected"
              id="myTable"
              :headers="headers"
              :items="itemsWithSno"
              :loading="loading"
              item-key="slNo"
              class="elevation-1 mt-6"
              :search="search"
              :options.sync="options"
              :server-items-length="totalCount"
              :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
            >
              <template v-slot:item.order_id="{ item }">
                #{{ padLeft(item.order_id, 4) }}
              </template>
              <template v-slot:item.customerName="{ item }">
                <span v-if="item.customer">
                  {{
                    item.customer.user.firstnameEn[0].toUpperCase() +
                      item.customer.user.firstnameEn.substring(1)
                  }}
                  {{
                    item.customer.user.lastnameEn[0].toUpperCase() +
                      item.customer.user.lastnameEn.substring(1)
                  }}
                </span>
              </template>
              <template v-slot:item.customerId="{ item }">
                #{{ padLeft(item.customer.customerId, 4) }}
              </template>
              <template v-slot:item.tags="{ item }">
                <span v-if="item.customer.tags">
                  <v-row v-for="tag in item.customer.tags" :key="tag._id">
                    {{ tag.name }}
                  </v-row>
                </span>
              </template>
              <template v-slot:item.area="{ item }">
                <CustomerAddressColumn :addresses="item.address" type="area" />
              </template>

              <template v-slot:item.block="{ item }">
                <CustomerAddressColumn :addresses="item.address" type="block" />
              </template>

              <template v-slot:item.building="{ item }">
                <CustomerAddressColumn
                  :addresses="item.address"
                  type="building"
                />
              </template>
              <template v-slot:item.deliveryDate="{ item }">
                {{
                  getDate(new Date(parseFloat(item.date)))
                    | moment("MMM DD YYYY")
                }}
              </template>
              <template v-slot:item.startDate="{ item }">
                {{
                  getDate(new Date(item.subscribedPackage.start_date))
                    | moment("MMM DD YYYY")
                }}
              </template>
              <template v-slot:item.endDate="{ item }">
                {{
                  getDate(new Date(item.subscribedPackage.end_date))
                    | moment("MMM DD YYYY")
                }}
              </template>
              <template v-slot:item.shift="{ item }">
                {{ item.shift }}
              </template>
              <template v-slot:item.agent="{ item }">
                <span v-if="item.driver">
                  {{ item.driver.user.firstnameEn }}
                  {{ item.driver.user.lastnameEn }}
                </span>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
      <v-dialog v-model="showErrorDialog" max-width="500">
        <v-card class="pa-3">
          <v-row>
            <v-card-title class="text-h6 black--text mt-n4"
              >Meals Log</v-card-title
            >
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="showErrorDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <ul class="mt-5" v-if="errorLog.length > 0">
            <li v-for="item in errorLog" :key="item">{{ item }}</li>
          </ul>
          <div v-else>All meals are generated successfully!</div>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" timeout="3000" right top color="error">{{
        text
      }}</v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import DownloadReports from "./DownloadReports.vue";
import ErrorLog from "../components/ErrorLog.vue";
import CustomerAddressColumn from "../components/AddressColumn.vue";
import moment from "moment";
import { tz } from "moment-timezone";
import { DATE_FORMAT, TIME_ZONE } from "../../utils/utils";
import { apolloClient } from "../../vue-apollo-report.js";
export default {
  components: {
    CustomerAddressColumn,
    DownloadReports,
    ErrorLog
  },

  data() {
    return {
      on: "",
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      snackbar: false,
      text: "",
      totalCount: 25,
      skip: 0,
      limit: 25,
      options: {
        itemsPerPage: 25,
        page: 1
      },

      loading: true,
      pendingList: [],
      showErrorDialog: false,
      errorLog: [],
      deliveryStatus: "All",
      search: "",
      btnloading: false,
      selected: [],
      menu: "",
      tz: tz,
      reportGeneratedDate: "",
      headers: [
        { text: "Q No.", value: "order_id", width: 50, sortable: false },
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false
        },
        {
          text: "Customer Id",
          value: "customerId",
          width: 80,
          sortable: false
        },
        { text: "Tags", value: "tags", sortable: false, width: 80 },
        {
          text: "Mobile",
          value: "customer.user.phone",
          sortable: false,
          width: 100
        },
        { text: "Zone", value: "zone.zoneName", width: 150, sortable: false },
        { text: "Area", value: "area", width: 150, sortable: false },
        {
          text: "Block, Street, Jedha",
          value: "block",
          width: 220,
          sortable: false
        },
        { text: "Building", value: "building", width: 200, sortable: false },
        {
          text: "Delivery Date",
          value: "deliveryDate",
          width: 120,
          sortable: false
        },
        { text: "Start Date", value: "startDate", width: 120, sortable: false },
        { text: "End Date", value: "endDate", width: 120, sortable: false },
        { text: "Delivery Time", value: "shift", sortable: false, width: 100 },
        { text: "Delivery Agent", value: "agent", width: 120, sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        (this.limit = this.options.itemsPerPage),
          (this.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.$apollo.queries.getInstantViewDeliveryDetailsByDate)
          this.$apollo.queries.getInstantViewDeliveryDetailsByDate.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 50000,
            skip:
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage
          });
      },
      deep: true
    }
  },
  apollo: {
    getInstantViewDeliveryDetailsByDate() {
      let dateTime = moment().format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      return {
        query: require("../api/getInstantViewDeliveryDetailsByDate.graphql"),
        variables: {
          date: modifiedDate,
          skip: this.skip,
          limit: this.limit
        },
        result({ data, loading }) {
          this.loading = loading;
          this.pendingList = [];
          this.reportGeneratedDate = "";
          this.errorLog = [];
          this.totalCount = 0;
          if (data.getInstantViewDeliveryDetailsByDate) {
            this.pendingList =
              data.getInstantViewDeliveryDetailsByDate.orderDelivery;
            this.reportGeneratedDate =
              data.getInstantViewDeliveryDetailsByDate.generatedDate;
            this.errorLog = data.getInstantViewDeliveryDetailsByDate.errorLog;
            this.totalCount =
              data.getInstantViewDeliveryDetailsByDate.totalCount;
          }
        }
      };
    }
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },

    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    generateReport() {
      if (this.btnloading == false) {
        this.btnloading = true;
        apolloClient
          .mutate({
            mutation: require("../api/generateDeliveryReport.graphql"),
            variables: {
              date: this.date,
              isInstantView: true
            }
          })
          .then(data => {
            this.btnloading = false;
            if (data.data.generateDeliveryReport.errors) {
              this.errorLog = data.data.generateDeliveryReport.errors;
              this.showErrorDialog = true;
            }
            this.searchDelivery();
          })
          .catch(error => {
            this.searchDelivery();
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },

    searchDelivery() {
      const dateTime = moment(`${this.date}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      console.log(modifiedDate);

      this.$apollo.queries.getInstantViewDeliveryDetailsByDate.refetch({
        date: modifiedDate
      });
    }
  },
  computed: {
    itemsWithSno() {
      if (this.pendingList == null) return [];
      return this.pendingList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  }
};
</script>
