<template>
    <v-container class="px-0 mx-0">
      <v-btn
        outlined
        :disabled="customersList.length == 0"
        @click="exportExcel"
        :loading="loading"
        width="150"
        class=" text-capitalize"
        color="primary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
      <div v-show="false">
        <v-data-table
          id="exportDriverDeliveryReport"
          hide-default-footer
          :items-per-page="-1"
          :headers="headers"
          :items="customers"
          class="elevation-1 mt-6"
        >
          <template v-slot:item.index="{ item }">
            {{ item.index }}
          </template>
  
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="item.user"
              :to="{
                name: 'CustomerDetails',
                params: { customerId: item._id },
                query: { customerId: item._id }
              }"
              class="mx-auto"
            >
              {{
                item.user.firstnameEn[0].toUpperCase() +
                  item.user.firstnameEn.substring(1)
              }}
              {{
                item.user.lastnameEn[0].toUpperCase() +
                  item.user.lastnameEn.substring(1)
              }}
            </router-link>
            <!-- </v-row> -->
          </template>
        </v-data-table>
      </div>
    </v-container>
  </template>
  
  <script>
  import { Utils } from "../../utils/utils";
  export default {
    props: {
      driverId: {
        type: String
      },
      date: {
        type:String
      },
      customersList:{
        type:String
      }
    },
    data() {
      return {
        loading: false,
        customers:[],
        headers: [
  
          {
            text: "#",
            value: "index",
            width: 100
          },
          // { text: "Avatar", value: "photo" },
          { text: "Customer Name", value: "name", width: 150 },
          {
            text: "Contact Number",
            value: "user.phone",
            width: 150
          }
        ]
      };
    },
    methods: {
      getDriverDeliveryReport() {
        this.loading = true;
        this.$apollo
          .watchQuery({
            query: require("../api/getDriverDeliveryReport.graphql"),
            variables: {
              driverId: this.driverId,
              date:this.date,
            }
          })
          .refetch({
            variables: {
              driverId: this.driverId,
              date:this.date,
            }
          })
          .then(data => {
            this.loading = false
            if (!data.data.getDriverDeliveryReport) return;
            const { customers  } = data.data.getDriverDeliveryReport;
            this.customers = customers.map((customer, index) => {
              return { ...customer, index: index + 1 };
            });
  
            setTimeout(() => {
              this.loading = false;
              Utils.htmlToExcel(
                "exportDriverDeliveryReport",
                "exportDriverDeliveryReport",
                false
              );
            }, 1000);
          })
          .catch(error => {
            console.log(error);
          });
      },
      exportExcel() {
        this.getDriverDeliveryReport();
      }
    }
  };
  </script>
  