<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="black--text text-h5 font-weight-bold ms-3">Products</h2>
    <!-- Hello {{ products }} -->
    <v-row class="mt-3 mt-sm-0">
      <v-col cols="12" sm="6" lg="3">
        <v-text-field placeholder="Name.." v-model="search" solo dense>
          <template v-slot:append>
            <v-icon color="black" class="me-n2">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <v-select :items="['IN_STOCK', 'OUT_OF_STOCK']" label="" placeholder="stock" v-model="stockType" clearable chips
          dense solo></v-select>
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <v-select :items="['ACTIVE', 'IN_ACTIVE']" label="status" placeholder="stock" v-model="statusFilter" clearable
          chips dense solo></v-select>
      </v-col>
      <v-col cols="12" sm="6" lg="1">
        <v-btn class="ms-sm-5 black--text text-capitalize" color="secondary" width="75" @click="getAllProducts">Search
        </v-btn>
      </v-col>
      <v-layout justify-end class="me-3">
        <CreateProduct v-if="hasRole(AdminUserPermissions.SHOP_PRODUCT_ADD)"></CreateProduct>
        <v-btn class="white--text text-capitalize ml-2 font-weight-bold" color="secondary" @click="openCategory">
          Category
        </v-btn>

        <span class="me-2 ml-2">
          <AuditTrialLog class="" auditType="ECOMMERCE_LOG" />
        </span>
      </v-layout>
    </v-row>
    <!-- {{ products }} -->
    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="itemsWithSno" class="elevation-1 mt-10"
      :options.sync="options" :loading="loading" :server-items-length="totalItems"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
      <template v-slot:item.status="{ item }">
        <v-icon v-show="item.status" color="primary">mdi-checkbox-marked</v-icon>
        <v-icon v-show="!item.status" color="grey">mdi-checkbox-blank-outline</v-icon>
      </template>
      <template v-slot:item.image="{ item }">
        <v-avatar v-if="item.image != null" :size="42" :src="`${imageUrl}${item.image}`" />
        <v-avatar v-else :username="item.nameEn" :size="42" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <!-- {{ item }} -->
          <UpdateProduct v-if="hasRole(AdminUserPermissions.SHOP_PRODUCT_EDIT)" :productDetails="item" />
          <DeleteProduct v-if="hasRole(AdminUserPermissions.SHOP_PRODUCT_DELETE)" :productDetails="item" />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CreateProduct from "../components/CreateProduct.vue";
import UpdateProduct from "../components/UpdateProduct.vue";
import DeleteProduct from "../components/DeleteProduct.vue";
// import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import AuditTrialLog from "../../audit-trial-log/components/AuditTrialLog";

export default {
  components: {
    CreateProduct,
    UpdateProduct,
    DeleteProduct,
    AuditTrialLog,
  },
  mounted() {
    this.$root.$on("updateProduct", () => {
      this.loading = true;
      this.getAllProducts();
    });
  },
  watch: {
    options: {
      handler() {
        this.getAllProducts();
      },
      deep: true,
    },
  },
  methods: {
    getAllProducts() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllProducts.graphql"),
          variables: {
            limit: 25,
            skip: 0,
            statusFilter: "",
            searchKey: "",
            stockType: "",
          },
        })
        .refetch({
          searchKey: this.search,
          statusFilter: this.statusFilter,
          stockType: this.stockType,
          limit: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
          skip: this.options.page * this.options.itemsPerPage - this.options.itemsPerPage,
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllProducts) {
            this.products = data.data.getAllProducts.products;
            this.totalItems = data.data.getAllProducts.totalCount;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    openCategory() {
      this.$router.push({
        name: "ProductCategory",
      });
    },
    openDiscount() {
      this.$router.push({
        name: "ShopDiscount",
      });
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      products: [],
      imageUrl: process.env.VUE_APP_BASE_URL,
      // name: "World",
      statusFilter: "",
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      stockType: "",
      headers: [
        { text: "#", sortable: false, value: "slNo", width: 120 },
        { text: "Product Name", value: "nameEn", width: 230 },
        { text: "Product Description", value: "descriptionEn", width: 230 },
        { text: "Image", value: "image", width: 230 },
        { text: "Product Price", value: "price", width: 90 },
        { text: "Product Status", value: "status", width: 90 },
        { text: "Stock Flag", value: "stockType", width: 90 },
        { text: "Action", value: "actions", sortable: false, width: 90 },
      ],
    };
  },

  created() {
    if (!this.hasRole(this.AdminUserPermissions.SHOP_PRODUCT_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getAllProducts();
  },
  apollo: {},
  computed: {
    itemsWithSno() {
      //return this.products
      return this.products.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
};
</script>
