<template>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div class="d-flex justify-space-between">
        <span>
          <h2 class="primary--text text-h5 font-weight-bold mb-4">
            Package Report
          </h2>
        </span>
        <span>
          <!-- <ExportPackageReport :packages="packages" :headers="headers" /> -->
        </span>
      </div>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="packages"
        :items-per-page="10"
        class="elevation-1 mt-5 "
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }"
      >
        <template v-slot:item.index="{ item }">
          {{ item.index }}
        </template>
        <template v-slot:item.totalCustomers="{ item }">
          {{ item.totalCustomers }}
        </template>
        <template v-slot:item.totalIncome="{ item }">
          {{ item.totalIncome.toFixed(2) }}KD
        </template>
        <template v-slot:item.titleEn="{ item }">
          <router-link
            :to="{
              name: 'PackageReview',
              params: { packageId: item._id, packageName: item.titleEn }
            }"
            class="mx-auto"
          >
            {{ item.titleEn }}
          </router-link>
          <!-- </v-row> -->
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script>
//   import ExportPackageReport from "../components/ExportPackageReport.vue";
  import moment from "moment";
  import { DATE_FORMAT } from "../../utils/utils";
  export default {
    components: {
    //   ExportPackageReport
    },
    apollo: {
      getAllPackagesReport() {
        return {
          query: require("@/menu-packages/api/getAllPackagesReport.graphql"),
          result({ data }) {
            this.packages = data.getAllPackagesReport.packages.map(
              (plan, index) => ({
                ...plan,
                index: index + 1
              })
            );
            this.loading = false;
          }
        };
      }
    },
    data() {
      return {
        loading: true,
        date: new Date().toISOString().substr(0, 10),
        menu: "",
        packages: [],
        headers: [
          {
            text: "Sl No",
            value: "index",
            width: 10
          },
          { text: "Name", value: "titleEn", width: 120 },
          { text: "Total Customers", value: "totalCustomers", width: 120 },
          { text: "Total Income", value: "totalIncome", width: 120 }
        ]
      };
    },
    created() {
      if (!this.hasRole(this.AdminUserPermissions.PLAN_REPORT_VIEW)) {
        this.$router.push({
          name: "Error404"
        });
      }
    },
    computed: {
      formatDate() {
        return this.date ? moment(this.date).format(DATE_FORMAT) : "";
      }
    }
  };
  </script>
  