var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_c('h2',{staticClass:"primary--text text-h5 font-weight-bold mb-4"},[_vm._v(" Package Report ")])]),_c('span')]),_c('v-data-table',{staticClass:"elevation-1 mt-5 ",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.packages,"items-per-page":10,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40] }},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.totalCustomers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalCustomers)+" ")]}},{key:"item.totalIncome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalIncome.toFixed(2))+"KD ")]}},{key:"item.titleEn",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'PackageReview',
          params: { packageId: item._id, packageName: item.titleEn }
        }}},[_vm._v(" "+_vm._s(item.titleEn)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }