<template>
  <div>
    <v-layout class="bg">
      <CustomerAddSalesOrder v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_ADD)" :customer_id="customer_id"
        :customer="customer" :subscribedPackages="subscribedPackages" />
    </v-layout>

    <v-data-table :mobile-breakpoint="0" class="row-pointer" @click:row="handleClick" :headers="headers"
      :items="salesOrderInfo" :items-per-page="10" :loading="loading">
      <template v-slot:[`item.dateTime`]="{ item }">
        {{
            getDate(new Date(parseInt(item.created_At)))
            | moment("MMM DD YYYY hh:mm")
        }}
      </template>
      <template v-slot:[`item.order_number`]="{ item }">
        <router-link :to="{
          name: 'CustomerDetails',
          params: { customerId: customer_id, packageId: item._id },
          query: { customerId: customer_id, packageId: item._id },
        }" class="mx-auto">
          {{ item.order_number }}
        </router-link>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        {{
            getDate(new Date(parseInt(item.start_date))) | moment("MMM DD YYYY")
        }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ getDate(new Date(parseInt(item.end_date))) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:[`item.expire_reasons`]="{ item }">
        <span v-if="item.expire_reasons">
          {{ item.expire_reasons.join(", ") }}
        </span>
      </template>
      <template v-slot:[`item.planStatus`]="{ item }">
        <div :class="
          item.isUndoExpiry && currentPlanStatus(item) != 'Expired'
            ? 'center'
            : ''
        ">
          <span :class="
            currentPlanStatus(item) == 'Active'
              ? 'green--text'
              : currentPlanStatus(item) == 'Payment Pending'
                ? 'orange--text'
                : currentPlanStatus(item) == 'Upcoming'
                  ? 'blue--text'
                  : currentPlanStatus(item) == 'Expired'
                    ? 'red--text'
                    : ''
          ">
            {{ currentPlanStatus(item) }}</span>
        </div>
      </template>
      <template v-slot:[`item.packagePrice`]="{ item }">
        <span v-if="item.package_price">
          {{ numberWithCommas(item.package_price) }}
        </span></template>

      <template v-slot:[`item.discount`]="{ item }">{{
          numberWithCommas(
            item.discount + item.promoCodeDiscount + item.walletApplied
          )
      }}</template>
      <template v-slot:[`item.taxAmount`]="{ item }">{{
          numberWithCommas(item.taxAmount)
      }}</template>
      <template v-slot:[`item.total`]="{ item }">
        <PriceSplitup :subscribedPackage="item" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- {{ indexOf(props.item._id) }} -->
        <v-row>
          <v-col cols="3">
            <div v-if="isEditEnabled(item)" class="me-3">
              <CustomerSalesorderUpdate v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_UPDATE)"
                :salesOrderInfo="item" :customer_id="customer_id" :allPackages="salesOrderInfo" />
            </div>
          </v-col>
          <v-col cols="3">
            <div v-if="isPaymentPanding(item)" class="me-3">
              <RegenerateSalesOrderPayment v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_UPDATE)"
                :salesOrder="item" />
            </div>
          </v-col>
          <v-col cols="3">
            <div v-if="isDeleteEnabled(item)">
              <ExpireSalesOrder v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_DELETE)" :salesOrder="item" />
            </div>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CustomerAddSalesOrder from "../salesOrder/CustomerAddSalesOrder.vue";
import RegenerateSalesOrderPayment from "../salesOrder/RegenerateSalesOrderPayment.vue";
import CustomerSalesorderUpdate from "../salesOrder/CustomerSalesorderUpdate.vue";
import ExpireSalesOrder from "../salesOrder/ExpireSalesOrder.vue";
import PriceSplitup from "../salesOrder/PriceSplitup.vue";

import moment from "moment";
import { tz } from "moment-timezone";
import { TIME_ZONE, Utils } from "../../../utils/utils";
export default {
  components: {
    CustomerSalesorderUpdate,
    CustomerAddSalesOrder,
    PriceSplitup,
    ExpireSalesOrder,
    RegenerateSalesOrderPayment,
  },
  props: {
    customer_id: {
      required: true,
    },
    customer: {
      required: true,
    },
    loading: {
      required: true,
    },
    salesOrder: {
      required: true,
    },
  },

  watch: {
    salesOrder: {
      handler() {
        this.subscribedPackages = this.salesOrder;
        this.runningPackages = Utils.getActiveAndPendingCustomerPackages(
          this.salesOrder
        );
        this.salesOrderInfo = JSON.parse(JSON.stringify(this.salesOrder));
        this.salesOrderInfo = this.salesOrderInfo.sort(function (a, b) {
          return b.order_number - a.order_number;
        });
      },
      deep: true,
    },
  },

  data() {
    return {
      tz: tz,
      runningPackages: [],
      headers: [
        {
          text: "Order Number",
          value: "order_number",
          width: 70,
          sortable: false,
        },
        { text: "Date, Time", value: "dateTime", width: 170, sortable: false },
        {
          text: "Start Date",
          value: "start_date",
          width: 120,
          sortable: false,
        },
        { text: "End Date", value: "end_date", width: 120, sortable: false },
        { text: "Plan", value: "package.titleEn", width: 140, sortable: false },
        {
          text: "Day",
          value: "package.availableChoices[0].availableNumOfDays",
          width: 100,
          sortable: false,
        },
        {
          text: "Plan Status",
          value: "planStatus",
          width: 100,
          sortable: false,
        },
        { text: "Reason for expiry", value: "expire_reasons", width: 150 },

        {
          text: "Price",
          value: "packagePrice",
          width: 90,
          sortable: false,
        },
        { text: "Discount", value: "discount", width: 90, sortable: false },

        { text: "Net Price", value: "total", width: 90, sortable: false },
        { text: "Paid By", value: "paid_by", width: 90, sortable: false },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          width: 100,
        },
      ],
      salesOrderInfo: [],
      subscribedPackages: [],
    };
  },
  created() {
    this.subscribedPackages = this.salesOrder;
    this.salesOrderInfo = JSON.parse(JSON.stringify(this.salesOrder));
    this.salesOrderInfo = this.salesOrderInfo.sort(function (a, b) {
      return b.order_number - a.order_number;
    });

    this.runningPackages = Utils.getActiveAndPendingCustomerPackages(
      this.salesOrder
    );
  },
  methods: {
    isEditEnabled(order) {
      let planStatus = this.currentPlanStatus(order);

      if (planStatus == "Active" || planStatus == "Upcoming") {
        return true;
      } else if (planStatus == "Expired") {
        let activeOrUpcoming = this.subscribedPackages.filter(
          (x) =>
            moment(new Date(parseInt(order.end_date))) <=
            moment(new Date(parseInt(x.start_date))) &&
            x.payment_status == "SUCCESS" &&
            x.order_number != order.order_number &&
            x.package.availableChoices[0].availableNumOfDays > 0
        );
        if (activeOrUpcoming.length == 0) {
          if (
            this.hasRole(this.AdminUserPermissions.CUSTOMER_EXPIRE_ORDER_EDIT)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    isDeleteEnabled(order) {
      let planStatus = this.currentPlanStatus(order);
      if (planStatus == "Active" || planStatus == "Upcoming") {
        return true;
      }
      return false;
    },
    isPaymentPanding(order) {
      let lastPackage =
        this.subscribedPackages[this.subscribedPackages.length - 1];
      let planStatus = this.currentPlanStatus(order);
      if (planStatus == "Payment Pending" && order.paymentUrl) {
        if (lastPackage._id == order._id) {
          return true;
        }
      }
      return false;
    },
    handleClick(item) {
      console.log(item);
      this.$router.push({
        name: "CustomerDetails",
        query: { customerId: this.customer_id, packageId: item._id },
      });
    },

    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },

    itemRowBackground: function (item) {
      if (
        (this.currentPlanStatus(item) == "Payment Pending") |
        (this.currentPlanStatus(item) == "PENDING")
      ) {
        return "black--text";
      } else if (this.currentPlanStatus(item) == "Active") {
        return "green--text";
      } else return "blue--text";
    },
    currentPlanStatus(order) {
      // console.log(order.payment_status);

      var packageStatus = "Expired";
      if (order.payment_status === "PENDING") {
        packageStatus = "Payment Pending";
      } else {
        if (this.runningPackages.activePackage) {
          if (order._id == this.runningPackages.activePackage._id) {
            packageStatus = "Active";
          }
        }
        if (this.runningPackages.upcomingPackages.length > 0) {
          let upcomingPackage = this.runningPackages.upcomingPackages.find(
            (x) => x._id == order._id
          );
          if (upcomingPackage) {
            packageStatus = "Upcoming";
          }
        }
      }
      // console.log(packageStatus);
      return packageStatus;
    },
  },
};
</script>
<style scoped>
.row-pointer>>>tbody tr :hover {
  cursor: pointer;
}
</style>
<style scoped>
.center {
  margin: auto;
  width: 100%;
  text-align: center;
  border: 3px solid rgb(218, 86, 99);
  padding: 10px;
}
</style>