<template>
  <v-container fluid>
    <v-progress-linear v-if="loading" indeterminate color="green"></v-progress-linear>

    <v-row>
      <v-col class="text-right mb-3">
        <ExportOrders :fromCustomer="true" :customer="customer" :type="type" :zone="filter.zone" :headers="headers"
          :deliveryStatus="filter.deliveryStatus" :paymentStatus="filter.paymentStatus" :fromDate="filter.orderDateFrom"
          :toDate="filter.orderDateTo" />
      </v-col>
    </v-row>


    <v-expansion-panels flat>
      <v-expansion-panel flat v-for="(item, i) in allOrders" :key="i" class=" mb-5 py-4  lighten-2 elevation-0"
        style="background-color:#CEF1F7">
        <v-expansion-panel-header>

          <v-row no-gutters align="center" class="d-flex gap-x-4" v-if="!open">
            <v-card flat tile class="d-flex align-center  mr-4 " color="transparent" min-width="200px">
              <!-- <v-checkbox>
                <template #label> -->

              <div class="ml-4">
                <p class="text--secondary text-caption mb-1">
                  {{ moment(new Date(parseInt(item.createdAt))).tz(TIME_ZONE).format("DD MMM YYYY hh:mm:A ") }}
                </p>
                <p class="mb-0 text--primary">{{ toTitleCase(item.customer.user.firstnameEn) }}
                  {{ toTitleCase(item.customer.user.lastnameEn) }}</p>
              </div>

              <!-- </template>
              </v-checkbox> -->
            </v-card>


            <div class="d-flex align-center mr-4 ">
              <Icon>
                <v-icon> mdi-motorbike </v-icon>
              </Icon>
              <v-card flat tile color="transparent" class="ml-4" min-width="200px">
                <p class="text--secondary text-caption  mb-1">Delivery</p>

                <p class="mb-0 font-weight-medium text--primary"
                  v-if="item.delivery && item.delivery.driver && item.delivery.driver.user">
                  {{ item.delivery.driver.user.firstnameEn }}
                  {{ item.delivery.driver.user.lastnameEn }}
                </p>
                <AssignDriver :item="item" v-else-if="tab == 0 && item.deliveryStatus == 'PENDING'"
                  style="padding:0px !important;" />

                <CheckPaymentStatus v-else-if="tab == 2 && item.paymentStatus == 'PENDING'" :item="item" />
              </v-card>
            </div>
            <div class="d-flex align-center mr-8 " v-if="item.netPrice == 0">
              <Icon class="text-caption"> F </Icon>
              <div class="ml-4">
                <p class="text--secondary text-caption mb-1">FREE</p>
                <p class="mb-0 font-weight-medium text--primary"> </p>
              </div>
            </div>
            <div class="d-flex align-center mr-8 " v-else>
              <Icon class="text-caption"> K </Icon>
              <div class="ml-4">
                <p class="text--secondary text-caption mb-1">KNET</p>
                <p class="mb-0 font-weight-medium text--primary"> {{ numberWithCommas(item.netPrice) }}</p>
              </div>
            </div>
            <!-- <v-card flat class="white px-4 py-2 mr-4" width="fit-content" rounded="lg" v-if="tab === 0">
              <p class="mb-1"> Monday </p>
              <p class="mb-0"> 4:00pm </p>
            </v-card> -->
            <v-card min-width="150px" flat tile color="transparent">
              <v-card flat class="px-4 py-2 mr-4" color="" style="background-color:#DBDC00;" rounded="lg"
                v-if="tab === 0 && item.delivery && item.delivery.driver && item.delivery.driver.user">
                <p class="mb-0">
                  Assigned at

                  {{ moment(new Date(parseInt(item.delivery.deliveryAssignedTime))).tz(TIME_ZONE)
                    .format("hh:mm A, DD MMMM")
                  }}
                </p>
              </v-card>
              <v-card min-width="150px" flat tile color="transparent"
                v-else-if="tab == 1 && item.deliveryStatus == 'DELIVERED'">
                <p class="mb-1 gray--text text-body-2">Delivered</p>
                <span class="mb-2 font-weight-medium " v-if="item.delivery && item.delivery.deliveredTime">
                  {{ moment(new Date(parseInt(item.delivery.deliveredTime))).tz(TIME_ZONE).format("hh:mm a")
                  }}
                </span>,
                <span class="mb-0 font-weight-medium" v-if="item.delivery && item.delivery.deliveredTime">
                  {{ moment(new Date(parseInt(item.delivery.deliveredTime))).tz(TIME_ZONE).format("DD MMM") }}
                </span>
                <!-- 04:00 PM - 05:00
              PM, -->
              </v-card>
            </v-card>


            <DeliverOrder :item="item" v-if="tab === 0 && item.delivery.driver.user" />

            <v-btn min-width="100px" v-else-if="tab === 1" small
              :color="item.deliveryStatus == 'DELIVERED' ? 'primary' : 'error'" class="rounded-pill ml-12 text-capitalize"
              tile elevation="0">
              {{ item.deliveryStatus }}
            </v-btn>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <OrderExpansion :orderInfo="item" :tab="tab" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <v-pagination v-model="currentPage" :total-visible="7" :length="totalPages" @input="updateItems"></v-pagination>
  </v-container>
</template>
<script>
import { TIME_ZONE } from "../../utils/utils";
import Icon from "../components/Icon.vue";
import OrderExpansion from "../components/OrderExpansion.vue";
import AssignDriver from "../components/AssignDriver.vue";
import DeliverOrder from "../components/DeliverOrder.vue";
import ExportOrders from "../components/ExportOrders.vue";
import moment from "moment";
import CheckPaymentStatus from "../components/CheckPaymentStatus.vue";
// import { format, parseISO } from "date-fns";
export default {
  components: { Icon, OrderExpansion, AssignDriver, DeliverOrder, ExportOrders, CheckPaymentStatus, },

  mounted() {
    this.$root.$on("getAllOrders", () => {

      this.getAllOrders();
    });
  },
  watch: {
    filter: {
      handler() {
        this.getAllOrders();
      },
      deep: true,
    },
    searchKey: {
      handler() {
        this.getAllOrders();
      },
      deep: true,
    },
    currentPage: {
      handler() {
        debugger
        this.variables.skip = this.productsPerPage * (this.currentPage - 1);
        this.variables.limit = this.productsPerPage;
        this.getAllOrders();
      },
      deep: true,
    },
    options: {
      handler() {
        //   this.variables.limit = this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : this.totalOrders;
        //   this.variables.skip = this.options.page * this.options.itemsPerPage - this.options.itemsPerPage;

        this.variables.skip = this.productsPerPage * (this.currentPage - 1);
        this.variables.limit = this.productsPerPage;
        this.getAllOrders();
      },
      deep: true,
    },
  },

  data() {
    return {
      headers: [

        {
          text: "Order Id",
          align: "left",
          value: "orderId",
          sortable: false,
          width: 70,
        },
        {
          text: "Delivery Status",
          align: "center",
          value: "deliveryStatus",
          sortable: false,
          width: 60,
        },
        {
          text: "Payment Status",
          align: "center",
          value: "paymentStatus",
          sortable: false,
          width: 60,
        },
        {
          text: "Customer",
          align: "center",
          width: 180,
          value: "customer",
          sortable: false,
        },
        {
          text: "Phone",
          align: "center",
          width: 100,
          value: "phone",
          sortable: false,
        },
        {
          text: "Total",
          align: "center",
          width: 120,
          value: "total",
          sortable: false,
        },
        {
          text: "Discount",
          align: "center",
          width: 100,
          value: "discount",
          sortable: false,
        },
        {
          text: "Net Price",
          align: "center",
          width: 120,
          value: "netPrice",
          sortable: false,
        },
        {
          text: "Zone",
          align: "center",
          value: "zone",
          sortable: false,
          width: 120,
        },
        {
          text: "Order Date",
          align: "center",
          width: 120,
          value: "orderDate",
          sortable: false,
        },
        {
          text: "Created By",
          align: "center",
          width: 150,
          value: "createdBy",
          sortable: false,
        },

      ],
      TIME_ZONE,
      loading: false,
      moment: moment,
      productsPerPage: 2500,
      totalPages: 0,
      drivers: [],
      currentPage: 1,
      variables: {
        limit: 10,
        skip: 0,
        regionFilter: "",
        zoneFilter: "",
        status: null,
        searchKey: "",
        deliveryDate: null,
        orderDate: null,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      currentOrder: {},
      allOrders: [],
      totalOrders: 10,
      currentStatus: null,


      showMarkAsDespatch: false,
      showMarkAsDeliverd: false,
      showEditOrder: false,
      showProductReturn: false,
      orderEditCustomerId: "",
      productReturnCustomerId: "",
      isEdit: false,
      orderToEdit: null,
      selectedIds: [],
      isAnyOrderSelected: false,
      status: "",

    };
  },

  computed: {
    deliveryDateFormatted() {
      return this.deliveryDate ? moment(this.deliveryDate).format("DD/MM/YYYY") : "";
    },
    orderDateFormatted() {
      return this.orderDate ? moment(this.orderDate).format("DD/MM/YYYY") : "";
    },

    toDateFormatted() {
      return this.toDate ? moment(this.toDate).format("DD/MM/YYYY") : "";
    },
    fromDateFormatted() {
      return this.fromDate ? moment(this.fromDate).format("DD/MM/YYYY") : "";
    },
    filteredHeaders() {
      return this.headers.filter(header => {
        return header.value !== "checkbox" && header.value !== "action";
      });
    },
  },
  methods: {
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    getAllOrders() {
      this.loading = true;
      let pageInput = {
        skip: 0,
        limit: 2500,
      };

      let filter = {
        paymentStatus: this.filter.paymentStatus,
        deliveryStatus: this.filter.deliveryStatus,
        zone: this.filter.zone,
        customer: this.fromCustomer ? this.customer._id : "",
        tab: this.type,
        fromDate: this.filter.orderDateFrom,
        toDate: this.filter.orderDateTo,

      };
      this.$apollo
        .watchQuery({
          query: require("../api/listOrders.graphql"),
          variables: {
            input: pageInput,
            filter: filter,
            searchKey: this.searchKey,
          },
        })
        .refetch({

          input: {
            skip: this.productsPerPage * (this.currentPage - 1),
            limit: this.productsPerPage,
          },
          filter: filter,
          searchKey: this.searchKey,

        })
        .then(data => {
          this.isAnyOrderSelected = false; // posOrder button disabling
          this.loading = false;
          this.allOrders = [];
          this.totalOrders = 0;
          if (data.data.listOrders) {
            let allOrders = data.data.listOrders.orders;
            allOrders.forEach(element => {
              element.isChecked = false;
            });
            this.allOrders = allOrders;
            this.totalOrders = data.data.listOrders.totalCount;
            this.totalPages = this.totalOrders / this.productsPerPage;
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  created() {
    this.getAllOrders();
  },
  props: {
    tab: Number,
    fromCustomer: {
      required: false,
    },
    customer: {
      required: false,
    },
    filter: {
      required: false,
    },
    type: String,
    searchKey: String,
  },
};
</script>